<template>
  <main role="main" class="bd-content">
    <div class="main-content">
      <section class="section section-pets-details">
        <!-- <div class="panel" v-for="(item, index) in petsFilter" :key="index"> -->
        <div class="panel">
          <div class="panel-header">
            <div class="title-wrap">
              <div class="title-with-back">
                <router-link class="back-arrow" :to="{ name: 'pets' }">
                  <back-arrow></back-arrow>
                </router-link>
                <h3 class="panel-title">Pet Details</h3>
              </div>
              <div class="actions">
                <button class="btn-text btn-delete" @click="deletePet">
                  <Delete></Delete>
                  <span class="primary-color">Delete</span>
                </button>
              </div>
              <!-- modal for Delete -->
            </div>
          </div>

          <div class="panel-body">
            <div class="pet-detail-head">
              <div class="detail-wrap">
                <ul class="lists">
                  <li class="list">
                    <div class="list-wrap">
                      <figure class="thumb-wrap">
                        <!-- <img
                          class="img"
                          :src="
                            pet.image_web !== null
                              ? pet.image_web
                              : '/images/pet-deafult.png'
                          "
                          alt="pet icon"
                        /> -->
                        <CoolLightBox
                          :items="petImage"
                          :index="index2"
                          @close="index2 = null"
                        >
                        </CoolLightBox>

                        <img
                          class="img"
                          :class="image == null ? '' : 'enable-lightbox'"
                          v-for="(image, imageIndex2) in petImage"
                          :key="imageIndex2"
                          @click="index2 = imageIndex2"
                          :src="
                            image !== null ? image : '/images/pet-deafult.png'
                          "
                          alt="pet icon"
                        />
                      </figure>
                      <div class="text-wrap">
                        <h6 class="list-title">
                          {{ pet.name }}
                        </h6>
                        <div class="pet-desc">
                          <div class="pet-breed">
                            <label class="breed" v-if="pet && pet.breed"
                              ><span>{{ pet.breed.name }}</span></label
                            >
                            <label class="breed" v-if="pet && pet.breed == null"
                              ><span>Beautiful Mix</span></label
                            >
                          </div>
                          <div class="pet-caracter">
                            <label class="character">
                              <span>{{
                                pet.gender == 0 ? "Good Boy" : "Good Girl"
                              }}</span>
                            </label>
                            <label class="weight"
                              ><span>
                                {{ Number(pet.weight).toFixed(1) }}
                                {{ pet.metric }}</span
                              ></label
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li class="list">
                    <div class="list-wrap">
                      <div class="pet-birth">
                        <label class="birth"><span> Birth Date</span></label>
                        <!-- <h3 class="pet-date">6 June 2021</h3> -->
                        <h3 class="pet-date">
                          {{ pet.date_of_birth | moment("D MMMM YYYY") }}
                        </h3>
                        <span
                          class="pet-age"
                          v-if="petAge.months !== 0 || petAge.years !== 0"
                        >
                          <template v-if="petAge.years !== 0">
                            {{ petAge.years }} year<template
                              v-if="petAge.years > 1"
                              >s</template
                            >
                          </template>
                          <template v-if="petAge.months !== 0">
                            {{ petAge.months }} month<template
                              v-if="petAge.months > 1"
                              >s</template
                            >
                          </template>
                        </span>
                      </div>
                      <div class="pet-gotcha">
                        <label class="gotcha"><span>Gotcha Date</span></label>
                        <h3 class="pet-date">
                          {{ pet.gotcha_date | moment("D MMMM YYYY") }}
                        </h3>
                      </div>
                    </div>
                  </li>
                  <li class="list">
                    <div class="list-wrap">
                      <div class="pet-guardian">
                        <label class="guardian">
                          <span> Guardians </span>
                        </label>
                        <ul class="g-lists">
                          <li class="g-list">
                            <figure class="g-thumb" v-if="pet && pet.user">
                              <img
                                :src="
                                  pet.user.image_web !== null
                                    ? pet.user.image_web
                                    : '/images/user-deafult.png'
                                "
                                alt=""
                                class="guardian-img"
                              />
                            </figure>
                            <label class="g-name" v-if="pet && pet.user">{{
                              pet.user.name
                            }}</label>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                  <li class="list">
                    <div class="list-wrap">
                      <div class="pet-place">
                        <label class="place">
                          <span> Neighbourhood </span>
                        </label>
                        <ul class="fav-lists">
                          <li class="fav-list">
                            <h5 class="fav-name">
                              {{ pet.fav_spot }}
                            </h5>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                  <li class="list">
                    <div class="list-wrap">
                      <div class="pet-place">
                        <label class="place">
                          <span> Clinic </span>
                        </label>
                        <ul class="fav-lists">
                          <li class="fav-list">
                            <h5 class="fav-name">
                              {{ pet.clinic }}
                            </h5>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                 
                  <li class="list">
                    <div class="list-wrap">
                      <div class="pet-more">
                        <label class="place">
                          <span>More Information</span>
                        </label>
                        <ul class="more-lists">
                          <li class="more-list" v-if="pet.is_neutered == 1">
                            <label class="label-more"> Neutered</label>
                          </li>
                          <li class="more-list" v-if="pet.is_social">
                            <label class="label-more">{{
                              pet.is_social
                            }}</label>
                          </li>
                          <li
                            class="more-list"
                            v-if="pet.likes_big_dog == 'Yes!'"
                          >
                            <label class="label-more">Like big dogs</label>
                          </li>
                          <li
                            class="more-list"
                            v-if="pet.likes_small_dog == 'Yes!'"
                          >
                            <label class="label-more">Like small dogs</label>
                          </li>

                          <li
                            class="more-list"
                            v-if="pet.has_treats && pet.pet_type == 0"
                          >
                            <label class="label-more">All the treats</label>
                          </li>
                          <li
                            class="more-list"
                            v-if="pet.has_treats == null && pet.pet_type == 0"
                          >
                            <label class="label-more">Ask me first</label>
                          </li>
                          <li class="more-list" v-if="pet.likes_dog == 'Yes!'">
                            <label class="label-more">Like dogs</label>
                          </li>
                          <li class="more-list" v-if="pet.likes_cat == 'Yes!'">
                            <label class="label-more">Like cats</label>
                          </li>

                          <li
                            class="more-list"
                            v-if="
                              pet.likes_adventure == 'Yes!' && pet.pet_type == 1
                            "
                          >
                            <label class="label-more">Likes adventure</label>
                          </li>
                          <li
                            class="more-list"
                            v-if="
                              pet.likes_adventure == null && pet.pet_type == 1
                            "
                          >
                            <label class="label-more">Stays at home</label>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="pet-wrapper">
              <div class="pet-record">
                <v-card>
                  <v-tabs
                    v-model="tab"
                    background-color="transparent"
                    :class="sidebarStatus ? 'sticky' : ''"
                  >
                    <v-tab v-for="item in items" :key="item">
                      {{ item }}
                    </v-tab>
                  </v-tabs>

                  <v-tabs-items v-model="tab">
                    <v-tab-item>
                      <div class="tab-record">
                        <div class="title-with-action">
                          <h3 class="tab-title">Record History</h3>
                          <div class="actions">
                            <router-link
                              :to="{
                                name: 'create-vaccine-record',
                                params: { id: this.$route.params.id },
                              }"
                              class="btn btn-primary btn-create"
                              dusk="create"
                            >
                              <span> Create new </span>
                              <add-with-circle></add-with-circle>
                            </router-link>
                          </div>
                        </div>
                        <v-expansion-panels class="according-section">
                          <v-expansion-panel
                            v-for="(vaccineRecord, index) in vaccineRecords"
                            :key="vaccineRecord.index"
                          >
                            <v-expansion-panel-header>
                              <div class="accordion-head">
                                <div class="left-area">
                                  <h4 class="accordion-title">
                                    {{ vaccineRecord.vaccine.name }}
                                    <span
                                      class="status"
                                      v-if="dosesPublished(vaccineRecord)"
                                    ></span>
                                  </h4>
                                  <span class="date">
                                    <span
                                      v-for="(
                                        vaccineDetail, index
                                      ) in vaccineRecord.vaccine_records_detail"
                                      :key="vaccineDetail.index"
                                    >
                                      <template
                                        v-if="
                                          index ==
                                          vaccineRecord.vaccine_records_detail
                                            .length -
                                            1
                                        "
                                      >
                                        <span
                                          v-if="
                                            vaccineDetail.is_booster_date == 1
                                          "
                                          >Booster Date:</span
                                        >
                                        <span
                                          class="color-primary"
                                          v-if="
                                            vaccineDetail.is_booster_date == 1
                                          "
                                          >{{
                                            vaccineDetail.injection_date
                                              | moment("D MMM YYYY")
                                          }}</span
                                        >
                                      </template>
                                    </span>
                                  </span>
                                </div>
                                <div class="right-area">
                                  <span
                                    class="vaccine"
                                    :class="isVaccinatedClass(vaccineDetail)"
                                    v-for="(
                                      vaccineDetail, index
                                    ) in vaccineRecord.vaccine_records_detail"
                                    :key="vaccineDetail.index"
                                  ></span>
                                </div>
                              </div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <div class="widget-dose">
                                <ul class="lists">
                                  <li
                                    class="list"
                                    v-for="(
                                      vaccineDetail, index
                                    ) in vaccineRecord.vaccine_records_detail"
                                    :key="vaccineDetail.index"
                                  >
                                    <div class="list-wrap">
                                      <span
                                        class="dose-status"
                                        :class="
                                          isVaccinatedClass(vaccineDetail)
                                        "
                                      >
                                      </span>
                                      <div class="list-head">
                                        <h6 class="dose-title">
                                          {{ vaccineRecord.vaccine.name }}&nbsp;
                                          <label class="dose-label">
                                            Dose {{ index + 1 }}
                                          </label>
                                          <span
                                            class="status"
                                            v-if="
                                              vaccineDetail.is_published == 0
                                            "
                                            :class="
                                              vaccineDetail.is_published == 0
                                                ? 'not-verify'
                                                : ''
                                            "
                                          >
                                            {{
                                              vaccineDetail.is_published
                                            }}</span
                                          >
                                        </h6>
                                      </div>
                                      <div class="list-body">
                                        <label
                                          >{{
                                            vaccineDetail.is_booster_date == 0
                                              ? "Injection Date"
                                              : "Booster Date"
                                          }}

                                          <span
                                            class="vaccine-date"
                                            :class="
                                              vaccineDetail.is_booster_date == 1
                                                ? 'booster-date'
                                                : ''
                                            "
                                            >{{
                                              vaccineDetail.injection_date
                                                | moment("D MMM YYYY")
                                            }}</span
                                          ></label
                                        >
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                      </div>
                      <div class="action" v-if="vaccineRecords.length != 0">
                        <button
                          class="btn btn-primary btn-mark"
                          @click="publishChanges"
                          :class="needs_publish ? '' : 'disabled'"
                          :disabled="!needs_publish"
                        >
                          Publish Changes
                        </button>
                        <div v-if="published_date" class="latest-date">
                          <span>
                            Changes Published:
                            <span class="primary-color">{{
                              published_date
                            }}</span>
                          </span>
                        </div>
                      </div>
                    </v-tab-item>
                    <v-tab-item>
                      <div class="tab-record">
                        <h3 class="tab-title">Checked in place</h3>
                        <check-in-places :items="places"></check-in-places>
                      </div>
                    </v-tab-item>
                    <v-tab-item>
                      <div class="tab-record">
                        <div class="blood-donation-with-tabs">
                          <h3 class="tab-title">Blood Donation</h3>
                          <template>
                            <v-tabs class="blood-tabs">
                              <v-tab @click="activetab = '1'"> Request </v-tab>
                              <v-tab @click="activetab = '2'"> Donate </v-tab>
                            </v-tabs>
                          </template>
                        </div>
                        <template v-if="activetab == '1'">
                          <blood-donations
                            :items="bloodRequests"
                            :petDetail="pet"
                          ></blood-donations>
                        </template>
                        <template v-if="activetab == '2'">
                          <blood-donations
                            :items="bloodDonations"
                          ></blood-donations>
                        </template>
                      </div>
                    </v-tab-item>
                    <!-- <v-tab-item>
                      <v-card color="basil" flat>
                        <div class="tab-record">
                          <h3 class="tab-title">Record History</h3>
                          <div
                            class="widget-vaccineImage"
                            v-if="vaccineImage.image"
                          >
                            <ul class="lists">
                              <li class="list">
                                <div class="list-wrap">
                                  <figure class="list-thumb">
                                    <CoolLightBox
                                      :items="vaccineImageSrc"
                                      :index="index"
                                      @close="index = null"
                                    >
                                    </CoolLightBox>

                                    <div
                                      class="list-img"
                                      v-for="(
                                        image, imageIndex
                                      ) in vaccineImageSrc"
                                      :key="imageIndex"
                                      @click="index = imageIndex"
                                      :style="{
                                        backgroundImage: 'url(' + image + ')',
                                        backgroundSize: 'cover',
                                      }"
                                    ></div>
                                  </figure>
                                  <div class="list-text">
                                    <span
                                      class="status success"
                                      v-if="vaccineImage.is_completed == 1"
                                      >Updated</span
                                    >
                                    <span class="status fail" v-else
                                      >Not updated</span
                                    >
                                    <label class="date"
                                      >Uploaded Date:
                                      <span class="primary-color">{{
                                        vaccineImage.created_at
                                          | moment("D MMM YYYY")
                                      }}</span>
                                    </label>
                                  </div>
                                  <div
                                    class="action"
                                    v-if="vaccineImage.is_completed == 0"
                                  >
                                    <button
                                      class="btn btn-primary btn-mark"
                                      @click="updateVaccineImage"
                                    >
                                      Mark as updated
                                    </button>
                                    <div v-if="errors.updateVaccineImage">
                                      <small class="text-danger">
                                        {{ errors.updateVaccineImage }}
                                      </small>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div class="widget-vaccineImage" v-else>
                            <p>Records not found</p>
                          </div>
                        </div>
                      </v-card>
                    </v-tab-item> -->
                  </v-tabs-items>
                </v-card>
              </div>
              <div class="sidebar-area" v-if="tab == 0">
                <div class="widget widget-vaccineImage" v-if="vaccineImageSrc">
                  <div class="widget-wrap">
                    <div class="widget-header">
                      <div class="widget-title">Record History</div>
                    </div>
                    <div class="widget-body">
                      <ul class="lists">
                        <li class="list" v-if="vaccineImageSrc.length == 0">
                          No records found
                        </li>
                        <li class="list" v-else>
                          <div class="list-wrap">
                            <div
                              :class="
                                vaccineImageSrc.length > 1
                                  ? 'gallery-wrap multiple'
                                  : 'gallery-wrap'
                              "
                            >
                              <CoolLightBox
                                :items="vaccineImageSrc"
                                :index="index"
                                :effect="'fade'"
                                @close="index = null"
                              >
                              </CoolLightBox>
                              <span
                                class="loading-status"
                                v-if="replaceImageLoading"
                              >
                                <span class="loading-dots"></span>
                              </span>
                              <figure
                                class="list-thumb"
                                v-for="(image, imageIndex) in vaccineImageSrc"
                                :key="imageIndex"
                              >
                                <div
                                  class="list-img"
                                  @click="index = imageIndex"
                                  :style="{
                                    backgroundImage: 'url(' + image + ')',
                                    backgroundSize: 'cover',
                                  }"
                                ></div>
                                <button
                                  class="btn-remove"
                                  @click.prevent="removeUploadedVaccine(image)"
                                >
                                  <svg
                                    data-v-58d45dee=""
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="13"
                                    height="13"
                                    viewBox="0 0 13 13"
                                    fill="none"
                                  >
                                    <path
                                      data-v-58d45dee=""
                                      d="M7.64539 6.50286L12.7534 1.39425C13.5176 0.629994 12.3269 -0.4745 11.6059 0.246595L6.49786 5.35266L1.38728 0.246595C0.625639 -0.517664 -0.481272 0.673158 0.239744 1.39425L5.34779 6.50286L0.237205 11.6115C-0.496505 12.3453 0.638333 13.5056 1.38474 12.7591L6.49532 7.65052L11.6059 12.7591C12.3396 13.4929 13.4897 12.3453 12.7534 11.6115L7.64539 6.50286Z"
                                      fill="white"
                                    ></path>
                                  </svg>
                                </button>
                              </figure>
                            </div>
                            <div class="list-text">
                              <label class="date"
                                >Uploaded Date:
                                <span class="primary-color">{{
                                  vaccineImageSrcUpdatedDate
                                }}</span>
                              </label>
                            </div>
                            <div class="action">
                              <!-- <button
                                class="btn btn-primary btn-mark"
                                @click="$refs.fileInput.click()"
                                :disabled="replaceImageLoading"
                              >
                                Replace Image
                                <input
                                  accept="image/png, image/gif, image/jpeg"
                                  type="file"
                                  ref="fileInput"
                                  name="image"
                                  @change="replaceImage"
                                  style="
                                    position: absolute;
                                    opacity: 0;
                                    z-index: -1;
                                  "
                                />
                              </button> -->
                              <button
                                class="btn btn-primary btn-mark"
                                @click="$refs.fileInput.click()"
                                :disabled="uploadImageLoading"
                              >
                                Upload Image
                                <input
                                  accept="image/png, image/gif, image/jpeg"
                                  type="file"
                                  ref="fileInput"
                                  name="image"
                                  @change="uploadVaccineImage"
                                  style="
                                    position: absolute;
                                    opacity: 0;
                                    z-index: -1;
                                  "
                                />
                              </button>
                              <div>
                                <small
                                  class="text-danger"
                                  v-if="errors.uploadVaccineImage"
                                >
                                  Upload valid image file
                                </small>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <v-app class="mt-12 ml-12">
            <template>
              <v-dialog v-model="dialogDelete" max-width="460px">
                <v-card class="modal-actionDelete">
                  <div class="modal-head">
                    <v-card-title class="card-title">Delete</v-card-title>
                  </div>
                  <v-card-subtitle>
                    Are you sure want to delete?
                  </v-card-subtitle>
                  <v-card-actions>
                    <v-btn class="btn btn-secondary" text @click="closeDelete"
                      >Cancel</v-btn
                    >
                    <v-btn
                      class="btn btn-primary btn-sucess"
                      text
                      @click="deletePetConfirm"
                      >Yes, delete</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogImageDelete" max-width="460px">
                <v-card class="modal-actionDelete">
                  <div class="modal-head">
                    <v-card-title class="card-title">Delete</v-card-title>
                  </div>
                  <v-card-subtitle>
                    Are you sure want to delete vacine image?
                  </v-card-subtitle>
                  <v-card-actions>
                    <v-btn
                      class="btn btn-secondary"
                      text
                      @click="dialogImageDelete = false"
                      >Cancel</v-btn
                    >
                    <v-btn
                      class="btn btn-primary btn-sucess"
                      text
                      @click.prevent="deleteVaccineImage"
                      >Yes, delete</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
          </v-app>
        </div>
      </section>
    </div>
  </main>
</template>

<script>
import DoseSuccess from "../icons/DoseSuccess.vue";
import DoseFail from "../icons/DoseFail.vue";
import BackArrow from "../icons/BackArrow.vue";
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
import moment from "moment";
import Delete from "../icons/Delete.vue";
import AddWithCircle from "../icons/AddWithCircle.vue";
import CheckInPlaces from "./checkInPlaces.vue";
import BloodDonations from "./bloodDonations.vue";

export default {
  name: "petsdetails",
  components: {
    DoseSuccess,
    DoseFail,
    BackArrow,
    CoolLightBox,
    Delete,
    AddWithCircle,
    CheckInPlaces,
    BloodDonations,
  },
  data() {
    return {
      activetab: "1",
      places: [
        {
          name: "Art Park West Kowloon Cultural District",
          date: "30 mins ago",
          image: "/images/kitty.jpeg",
        },
        {
          name: "Art Park West Kowloon Cultural District",
          date: "40 mins ago",
          image: "/images/kitty.jpeg",
        },
      ],
      bloodDonations: [],
      bloodRequests: [],
      sidebarStatus: false,
      id: this.$route.params.id,
      //  vaccineImage: [
      //   "/storage/uploads/onboarding/thumbnails/thumb-onboarding-1646289873.jpg",
      // ],
      vaccineImage: {},
      vaccineImageSrc: [],
      vaccineImageSrcUpdatedDate: null,
      replaceImageForm: null,
      replaceImageLoading: false,
      uploadImageForm: null,
      uploadImageLoading: false,
      vaccineImageToBeDeleted: "",
      index: null,
      index2: null,
      loading: false,
      vaccineRecords: {},
      needs_publish: true,
      published_date: null,
      dialogDelete: false,
      dialogImageDelete: false,
      form: {
        image: null,
      },
      pet: {},
      petImage: [],
      petAge: {
        years: 0,
        months: 0,
      },

      tab: 0,
      items: [
        "Vaccine records",
        // "Uploaded vaccine photos",
        "Checked in places",
        "Blood donations",
      ],
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      errors: {
        updateVaccineImage: null,
        replaceVaccineImage: null,
        updateVaccineImage: null,
      },
    };
  },
  created() {
    const token = localStorage.getItem("access_token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
  },
  mounted() {
    (this.loading = true),
      axios
        .get("/admin/pet/" + this.id)
        .then(({ data }) => {
          this.pet = data;
          this.petImage = [this.pet.image_web];
          var dob = moment(this.pet.date_of_birth);
          var years = moment().diff(dob, "years", false);
          var months = moment().diff(moment(dob), "months", false);
          this.petAge.months = months - years * 12;
          this.petAge.years = years;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    this.getVaccineRecords(this.id);
    this.getVaccineImage(this.id);
    this.getBloodRequest();
    window.addEventListener("scroll", this.updateScroll);
  },
  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  methods: {
    updateScroll() {
      if (window.scrollY >= $(".pet-wrapper").offset().top + 10) {
        this.sidebarStatus = true;
        $("body").css("overflow", "initial");
      } else {
        this.sidebarStatus = false;
        $("body").css("overflow", "hidden");
      }
    },
    getVaccineRecords() {
      axios
        .get(`/admin/vaccine/records/${this.id}`)
        .then(({ data }) => {
          this.vaccineRecords = data;
          //Check published status
          let published_data = [];
          this.vaccineRecords.map(function (vaccineRecord) {
            vaccineRecord.vaccine_records_detail.map(function (vaccineDetail) {
              published_data.push(vaccineDetail.is_published);
            });
          });
          if (published_data.includes(0)) {
            this.needs_publish = true;
          } else {
            this.needs_publish = false;
          }
          //Check published date
          let published_dates = [];
          this.vaccineRecords.map(function (vaccineRecord) {
            vaccineRecord.vaccine_records_detail.map(function (vaccineDetail) {
              if (
                vaccineDetail.published_at == null ||
                vaccineDetail.published_at == ""
              ) {
              } else {
                published_dates.push(new Date(vaccineDetail.published_at));
              }
            });
          });
          if (published_dates.length > 0) {
            this.published_date = moment(
              new Date(Math.max.apply(null, published_dates))
            ).format("h:mma D MMM YYYY");
          } else {
            this.published_date = null;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getVaccineImage(id) {
      axios
        .get(`/admin/vaccine/image/${id}`)
        .then(({ data }) => {
          this.vaccineImageSrc = data.map((image) => {
            return image.image_web;
          });
          let dates = data.map((date) => {
            return date.created_at;
          });
          let moments = dates.map((d) => moment(d));
          let maxDate = moment.max(moments);
          this.vaccineImageSrcUpdatedDate =
            moment(maxDate).format("D MMM YYYY");
        })
        .catch((errors) => console.log(errors));
    },
    updateVaccineImage() {
      axios
        .patch(`/admin/vaccine/update-image/${this.id}`)
        .then(this.getVaccineImage(this.id))
        .catch((error) => {
          this.errors.updateVaccineImage = error.response.data.error;
        });
    },
    publishChanges() {
      axios
        .patch(`/admin/vaccine/records/publish/${this.id}`)
        .then(({ data }) => {
          this.getVaccineRecords();
          this.needs_publish = false;
          Toast.success();
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    isVaccinatedClass(data) {
      let injection_date = data.injection_date;
      let is_booster_date = data.is_booster_date;
      let today = moment().format("D MMM YYYY");
      let week = moment().add(7, "day").format("D MMM YYYY");
      if (is_booster_date == 0) {
        return "vaccine--active";
      } else {
        if (moment(injection_date).isBefore(today)) {
          return "vaccine--missed";
        } else if (moment(injection_date).isSameOrBefore(week)) {
          return "vaccine--comming";
        } else {
          return "";
        }
      }
    },
    deletePet() {
      this.dialogDelete = true;
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    deletePetConfirm() {
      axios
        .delete("/admin/pet/" + this.id)
        .then(this.$router.push({ name: "pets" }), Toast.success())
        .catch((errors) => console.log(errors));
      this.closeDelete();
    },
    replaceImage(e) {
      this.errors.replaceVaccineImage = null;
      this.replaceImageLoading = true;
      const file = this.$refs.fileInput.files[0];
      this.vaccineImage.image = file.name;
      this.vaccineImageSrc[0] = file.name;
      if (!file) {
        e.preventDefault();
        alert("No file chosen");
        return;
      }
      //   if (file.size > 1024 * 1024) {
      //     e.preventDefault();
      //     alert("File too big (> 1MB)");
      //     return;
      //   }
      // Encode the file using the FileReader API
      const reader = new FileReader();
      reader.onloadend = () => {
        // console.log(reade  r.result);
        this.vaccineImage.image = reader.result;
        this.vaccineImageSrc[0] = reader.result;
        this.form.image = reader.result;
        axios
          .post(`/admin/vaccine/replace-image/${this.id}`, this.form)
          .then(({ data }) => {
            this.replaceImageLoading = false;
            Toast.success();
          })
          .catch((error) => {
            this.replaceImageLoading = false;
            this.errors.replaceVaccineImage = error.response.data.errors;
          });
      };
      reader.readAsDataURL(file);
    },
    uploadVaccineImage(e) {
      this.errors.uploadVaccineImage = null;
      this.uploadImageLoading = true;
      const file = this.$refs.fileInput.files[0];
      this.vaccineImage.image = file.name;
      this.vaccineImageSrc[0] = file.name;
      if (!file) {
        e.preventDefault();
        alert("No file chosen");
        return;
      }
      //   if (file.size > 1024 * 1024) {
      //     e.preventDefault();
      //     alert("File too big (> 1MB)");
      //     return;
      //   }

      // Encode the file using the FileReader API
      const reader = new FileReader();
      reader.onloadend = () => {
        // console.log(reade  r.result);
        this.vaccineImage.image = reader.result;
        this.vaccineImageSrc[0] = reader.result;
        this.form.image = reader.result;
        axios
          .post(`/admin/vaccine/upload-image/${this.id}`, this.form)
          .then(({ data }) => {
            this.getVaccineImage(this.id);
            this.uploadImageLoading = false;
            Toast.success();
          })
          .catch((error) => {
            this.uploadImageLoading = false;
            this.errors.uploadVaccineImage = error.response.data.errors;
          });
      };
      reader.readAsDataURL(file);
    },
    dosesPublished($record) {
      //Check published doses status
      let doses_published = [];
      $record.vaccine_records_detail.map(function (vaccineDetail) {
        doses_published.push(vaccineDetail.is_published);
      });
      if (doses_published.includes(0)) {
        return true;
      } else {
        return false;
      }
    },
    getBloodRequest() {
      axios
        .get(`/admin/blood-request/${this.id}`)
        .then(({ data }) => {
          this.bloodRequests = data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    removeUploadedVaccine(image_url) {
      this.vaccineImageToBeDeleted = image_url.split("/").pop();
      this.dialogImageDelete = true;
    },
    deleteVaccineImage() {
      this.loading = true;
      axios
        .delete(`/admin/vaccine/delete-image/${this.vaccineImageToBeDeleted}`)
        .then(({ data }) => {
          this.loading = false;
          this.dialogImageDelete = false;
          this.getVaccineImage(this.id);
          Toast.success();
        })
        .catch((error) => console.log(error));
    },
  },
};
</script>

<style  scoped>
</style>

