<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <rect width="22" height="22" fill="none" />
        <path
          d="M18.07.9H11.69A3.29,3.29,0,0,0,8.58,3.1H3.93A3.31,3.31,0,0,0,.62,6.4V17.79A3.31,3.31,0,0,0,3.93,21.1h6.38a3.28,3.28,0,0,0,3.11-2.2h4.65a3.31,3.31,0,0,0,3.31-3.3V4.21A3.31,3.31,0,0,0,18.07.9ZM10.31,19.2H3.93a1.41,1.41,0,0,1-1.41-1.41V6.4A1.41,1.41,0,0,1,3.93,5H8.38V15.6a3.31,3.31,0,0,0,2.82,3.27A1.34,1.34,0,0,1,10.31,19.2Zm9.17-3.6A1.41,1.41,0,0,1,18.07,17H11.69a1.41,1.41,0,0,1-1.41-1.41V4.21a1.42,1.42,0,0,1,1.41-1.4h6.38a1.41,1.41,0,0,1,1.41,1.4Z"
          fill="#181818"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "OnboardIcon",
};
</script>

<style>
</style>
