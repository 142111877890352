<template>
  <main role="main" class="bd-content">
    <div class="main-content">
      <div class="section section-merchant">
        <div class="panel">
          <div class="panel-body">
            <v-app class="mt-12 ml-12">
              <v-data-table
                :headers="headers"
                :items="merchants"
                :loading="loading"
                loading-text="Loading... Please wait"
                class="elevation-1"
                hide-default-footer
                :items-per-page="-1"
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <div class="toolbar-left">
                      <v-card-title>
                        <v-toolbar-title class="panel-with">
                          <h3 class="panel-title">Merchants</h3>
                        </v-toolbar-title>
                        <div class="search-toolbar-area">
                          <v-text-field
                            name="search"
                            v-model="search"
                            single-line
                            hide-details
                            placeholder="Search a name"
                          ></v-text-field>
                        </div>
                      </v-card-title>
                    </div>
                    <div class="toolbar-right">
                      <label for="">Merchant registraion link:</label>
                      <span class="url-area"
                        >{{ merchantUrl }}
                        <template>
                          <span
                            class="copy-icon"
                            @click="copyLink(merchantUrl)"
                          >
                            <LinkCopyIcon v-if="!copyClick"></LinkCopyIcon>
                            <TickIcon v-else></TickIcon>
                            <transition name="fade">
                              <span
                                class="copied-text"
                                :class="copyClick ? 'active' : ''"
                                v-if="copyClick"
                                >Copied</span
                              >
                            </transition>
                          </span>
                        </template>
                      </span>
                    </div>
                    <!-- modal for Deny Pending user -->
                    <v-dialog v-model="dialogDeny" max-width="460px">
                      <v-card class="modal-actionDelete">
                        <div class="modal-head">
                          <v-card-title class="card-title"
                            >Deny Merchant</v-card-title
                          >
                        </div>
                        <v-card-subtitle>
                          Are you sure want to Deny merchant registration?
                        </v-card-subtitle>
                        <v-card-actions>
                          <v-btn
                            class="btn btn-secondary"
                            text
                            @click="closeDeny"
                            >Cancel</v-btn
                          >
                          <v-btn
                            class="btn btn-primary btn-sucess"
                            text
                            @click="denyMerchantConfirm"
                            >Yes, deny</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <!-- modal for Deny Pending user -->
                    <v-dialog v-model="dialogDelete" max-width="460px">
                      <v-card class="modal-actionDelete">
                        <div class="modal-head">
                          <v-card-title class="card-title"
                            >Delete Merchant</v-card-title
                          >
                        </div>
                        <v-card-subtitle>
                          Are you sure want to Delete merchant?
                        </v-card-subtitle>
                        <v-card-actions>
                          <v-btn
                            class="btn btn-secondary"
                            text
                            @click="closeDelete"
                            >Cancel</v-btn
                          >
                          <v-btn
                            class="btn btn-primary btn-sucess"
                            text
                            @click="deleteMerchantConfirm"
                            >Yes, Delete</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-toolbar>
                  <div class="bottom-toolbar">
                    <div class="tab-wrap">
                      <template>
                        <v-tabs>
                          <v-tabs-slider color="#e77386"></v-tabs-slider>
                          <v-tab @click="getStatus('active')"> Active </v-tab>
                          <v-tab @click="getStatus('inactive')">
                            Inactive
                          </v-tab>
                          <v-tab @click="getStatus('pending')"> Pending </v-tab>
                        </v-tabs>
                      </template>
                    </div>
                  </div>
                </template>
                <!-- end of v-slot:top -->
                <template v-slot:item.sn="{ index }">
                  {{ index + 1 }}
                </template>
                <template v-slot:item.status="{ item }">
                  <v-chip class="status" :color="getColor(item.status)" dark>
                    {{ item.status }}
                  </v-chip>
                </template>

                <template v-slot:item.unique_link="{ item }">
                  {{ item.merchant_link }}
                </template>

                <template v-slot:item.actions="{ item }">
                  <div class="action">
                    <!-- <button class="btn btn-edit" @click="editItem(item)">
                      <div class="text-animation"></div>
                    </button> -->
                    <template v-if="form.status == 'active'">
                      <button class="btn btn-text btn-view-text">View</button>
                      <button
                        class="btn btn-text btn-view-text"
                        @click="deactivateMerchant(item)"
                      >
                        Deactivate
                      </button>
                      <button
                        class="btn btn-text btn-delete-text"
                        @click="deleteMerchant(item)"
                      >
                        Delete
                      </button>
                    </template>
                    <template v-if="form.status == 'inactive'">
                      <button
                        class="btn btn-text btn-view-text"
                        @click="activateMerchant(item)"
                      >
                        Activate
                      </button>
                    </template>
                    <template v-if="form.status == 'pending'">
                      <button
                        class="btn btn-text btn-view-text"
                        @click="acceptPendingMerchant(item)"
                      >
                        Accept
                      </button>
                      <button
                        class="btn btn-text btn-delete-text"
                        @click="denyMerchant(item)"
                      >
                        Deny
                      </button>
                    </template>
                  </div>
                </template>
              </v-data-table>
              <Pagination
                :totalItems="pagination.totalItems + ' Merchants'"
                :totalPages="pagination.totalPages"
                :perPage="pagination.perPage"
                :currentPage="pagination.currentPage"
                @pagechanged="getMerchants"
              ></Pagination>
            </v-app>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import LinkCopyIcon from "../icons/LinkCopyIcon.vue";
import TickIcon from "../icons/TickIcon.vue";
import Pagination from "../subComponents/pagination.vue";
export default {
  name: "Merchant",
  components: {
    LinkCopyIcon,
    TickIcon,
    Pagination,
  },
  data() {
    return {
      pagination: {
        totalItems: 0,
        totalPages: 1,
        perPage: 0,
        currentPage: 1,
      },
      paginationOf: "merchants",
      merchantUrl: window.location.origin + "/merchant/register",
      copyClick: false,
      loading: false,
      search: null,
      awaitingSearch: false,
      dialogDeny: false,
      dialogDelete: false,
      form: {
        status: "active",
      },
      headers: [
        {
          text: "S.N.",
          align: "start",
          sortable: false,
          value: "sn",
          width: "30px",
        },

        {
          text: "Merchant name",
          value: "name",
          sortable: true,
          align: "start",
          width: "175px",
        },

        {
          text: "Contact person",
          value: "name",
          sortable: true,
          align: "start",
          width: "180px",
        },
        {
          text: "Phone",
          value: "phone",
          sortable: false,
          width: "165px",
        },
        {
          text: "Email",
          value: "email",
          sortable: false,
          //   width: "135px",
        },
        {
          text: "Unique link",
          value: "unique_link",
          sortable: false,
          //   width: "180px",
          align: "left",
        },
        {
          text: "Status",
          value: "status",
          sortable: false,
          align: "left",
        },
      ],
      merchants: [],
      editedIndex: -1,
    };
  },
  watch: {
    search: function (val) {
      if (this.search == null || this.search == "") {
        this.awaitingSearch = false;
        this.getMerchants(this.pagination.currentPage);
        return false;
      } else {
        if (!this.awaitingSearch) {
          setTimeout(() => {
            if (this.search === null || this.search === "") {
              return;
            }
            this.loading = true;
            //  this.pagination.currentPage = 1;
            axios
              .get(
                `/admin/merchant/search?page=${this.pagination.currentPage}`,
                {
                  params: { search: this.search, status: this.form.status },
                }
              )
              .then(({ data }) => {
                this.paginationOf = "search";
                this.pagination.currentPage = data.current_page;
                this.pagination.totalPages = data.last_page;
                this.merchants = data.data;
                this.pagination.totalItems = data.total;
                this.loading = false;
                // console.log(data);
              })
              .catch((errors) => console.log(errors));
            this.awaitingSearch = false;
          }, 2000); // 2 sec delay
        }
        this.awaitingSearch = true;
      }
    },
    dialogDeny(val) {
      val || this.closeDeny();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  created() {
    const token = localStorage.getItem("access_token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    this.getMerchants(this.pagination.currentPage);
  },
  mounted() {
    if (this.form.status == "active") {
      let activeAction = {
        text: "Actions",
        value: "actions",
        sortable: false,
        align: "start",
        width: "235px",
      };
      this.headers.push(activeAction);
    }
  },
  methods: {
    copyLink(link) {
      this.copyClick = true;
      navigator.clipboard.writeText(link);
      setTimeout(() => {
        this.copyClick = false;
      }, 3000);
    },
    getColor(statusGet) {
      //   console.log(statusGet);
      if (statusGet == "active") return "v-chip--active";
      else if (statusGet == "inactive") return "v-chip--inactive";
      else if (statusGet == "pending") return "v-chip--active";
      else return "hidden";
    },
    getStatus(status) {
      this.form.status = status;
      this.getMerchants(this.pagination.currentPage);
      let pendingAction = {
        text: "Actions",
        value: "actions",
        sortable: false,
        align: "start",
        width: "145px",
      };
      let activeAction = {
        text: "Actions",
        value: "actions",
        sortable: false,
        align: "start",
        width: "235px",
      };
      if (this.form.status == "pending") {
        if (this.headers.length <= 7) {
          this.headers = this.headers.filter(
            (item) => item.value !== "unique_link"
          );
          this.headers.push(pendingAction);
        }
      } else if (this.form.status == "active") {
        if (this.headers.length <= 7) {
          this.headers.push(activeAction);
        }
      } else {
        // this.headers = this.headers.filter((item) => item.value !== "actions");
      }
    },
    getMerchants(page) {
      this.loading = true;
      this.pagination.currentPage = page;
      if (this.paginationOf == "search") {
        axios
          .get(`/admin/merchant/search?page=${this.pagination.currentPage}`, {
            params: { search: this.search, status: this.form.status },
          })
          .then(({ data }) => {
            this.paginationOf = "search";
            this.pagination.currentPage = data.current_page;
            this.pagination.totalPages = data.last_page;
            this.merchants = data.data;
            this.pagination.totalItems = data.total;
            this.loading = false;
            // console.log(data);
          })
          .catch((errors) => console.log(errors));
      } else {
        axios
          .get(
            `/admin/merchant/${this.form.status}?page=${this.pagination.currentPage}`
          )
          .then(({ data }) => {
            // console.log(data);
            this.paginationOf = "merchants";
            this.pagination.totalItems = data.total;
            this.pagination.totalPages = data.last_page;
            this.pagination.perPage = data.per_page;
            this.pagination.currentPage = data.current_page;
            this.merchants = data.data;
            this.loading = false;
          })
          .catch((error) => console.log(error));
      }
    },
    acceptPendingMerchant(item) {
      axios
        .put(`/admin/merchant/accept/${item.id}`)
        .then(
          this.merchants.splice(this.merchants.indexOf(item), 1),
          Toast.success()
        )
        .catch((error) => console.log(error));
    },
    denyPendingMerchant(item) {
      axios
        .delete(`/admin/merchant/deny/${item.id}`)
        .then(this.merchants.splice(this.merchants.indexOf(item), 1))
        .catch((error) => console.log(error));
    },
    denyMerchant(item) {
      this.editedIndex = this.merchants.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDeny = true;
    },
    closeDeny() {
      this.dialogDeny = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    denyMerchantConfirm() {
      axios
        .delete(`/admin/merchant/deny/${this.editedItem.id}`)
        .then(this.merchants.splice(this.editedIndex, 1), Toast.success())
        .catch((errors) => Toast.error());
      this.closeDeny();
    },
    deleteMerchant(item) {
      this.editedIndex = this.merchants.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    deleteMerchantConfirm() {
      axios
        .delete(`/admin/merchant/delete/${this.editedItem.id}`)
        .then(this.merchants.splice(this.editedIndex, 1), Toast.success())
        .catch((errors) => Toast.error());
      this.closeDelete();
    },
    deactivateMerchant(item) {
      axios
        .patch(`/admin/merchant/deactivate/${item.id}`)
        .then(({ data }) => {
          this.merchants.splice(this.merchants.indexOf(item), 1),
            Toast.success();
        })
        .catch((errors) => Toast.error());
    },
    activateMerchant(item) {
      axios
        .patch(`/admin/merchant/activate/${item.id}`)
        .then(({ data }) => {
          this.merchants.splice(this.merchants.indexOf(item), 1),
            Toast.success();
        })
        .catch((errors) => Toast.error());
    },
  },
};
</script>

<style>
</style>
