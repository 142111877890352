<template>
  <main role="main" class="bd-content">
    <div class="main-content">
      <section class="section section-pets-create">
        <div class="panel">
          <div class="panel-header">
            <div class="title-wrap">
              <div class="title-with-back">
                <router-link class="back-arrow" :to="{ name: 'pets' }">
                  <back-arrow></back-arrow>
                </router-link>
                <h3 class="panel-title">Add a vaccine record</h3>
              </div>
            </div>
          </div>
          <div class="panel-body">
            <form>
              <div class="row">
                <div class="col-md-8">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="vaccine">Vaccine</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder=""
                        />
                        <small class="text-danger"></small>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="clinic">Clinic</label>
                        <v-autocomplete
                          v-model="model"
                          :items="clinicLists"
                          :loading="isLoading"
                          :search-input.sync="search"
                          color="white"
                          hide-no-data
                          hide-selected
                          item-text="Description"
                          item-value="API"
                          placeholder="Start typing to Search"
                          return-object
                        ></v-autocomplete>
                      </div>
                    </div>
                    <!-- <div class="col-md-4">
                      <div class="form-check">
                        <input
                          type="checkbox"
                          class="form-check-input"
                          id="exampleCheck1"
                        />
                        <label class="form-check-label" for="exampleCheck1"
                          >Check me out</label
                        >
                      </div>
                    </div> -->
                  </div>
                </div>
              </div>
              <button type="submit" class="btn btn-primary">Submit</button>
            </form>
          </div>
          <div class="panel-footer">
            <div class="actions"></div>
          </div>
        </div>
      </section>
    </div>
  </main>
</template>

<script>
import BackArrow from "../icons/BackArrow.vue";
export default {
  name: "PetsAdd",
  components: {
    BackArrow,
  },
  data() {
    return {
      clinicLists: [
        "Hong Kong Island Veterinary Clinic",
        "Pet Space Sai Kung Veterinary Hospital",
        "Island Veterinary Services",
      ],
      descriptionLimit: 60,
      entries: [],
      isLoading: false,
      model: null,
      search: null,
    };
  },
  computed: {
    fields() {
      if (!this.model) return [];

      return Object.keys(this.model).map((key) => {
        return {
          key,
          value: this.model[key] || "n/a",
        };
      });
    },
    items() {
      return this.entries.map((entry) => {
        const Description =
          entry.Description.length > this.descriptionLimit
            ? entry.Description.slice(0, this.descriptionLimit) + "..."
            : entry.Description;

        return Object.assign({}, entry, { Description });
      });
    },
  },

  watch: {
    search(val) {
      // Items have already been loaded
      if (this.items.length > 0) return;

      // Items have already been requested
      if (this.isLoading) return;

      this.isLoading = true;

      // Lazily load input items
      fetch("https://api.publicapis.org/entries")
        .then((res) => res.json())
        .then((res) => {
          const { count, entries } = res;
          this.count = count;
          this.entries = entries;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>

<style>
</style>
