<template>

    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
    >
      <path
        d="M7 1L12 6L7 11"
        stroke="#6A6A6A"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M1 1L6 6L1 11"
        stroke="#6A6A6A"
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>

</template>

<script>
export default {
  name: "DoubleArrowRight",
};
</script>

<style>
</style>
