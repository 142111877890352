<template>
<div>
      Check in place
</div>
</template>

<script>
export default {
name:"CheckInPlace"
}
</script>

<style>

</style>
