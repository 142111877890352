<template>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="14"
      viewBox="0 0 8 14"
      fill="none"
    >
      <path
        d="M7 13L1 7L7 0.999999"
        stroke="#181818"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
</template>

<script>
export default {
  name: "BackArrow",
};
</script>

<style>
</style>
