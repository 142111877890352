<template>
  <main role="main" class="bd-content">
    <div class="main-content">
      <div class="section section-vaccines">
        <div class="panel">
          <div class="panel-header">
            <div class="title-wrap">
              <div class="title-with-back">
                <h3 class="panel-title">Push Notification</h3>
              </div>
            </div>
          </div>
          <div class="panel-body">
            <v-app>
              <form @submit.prevent="sendNotification">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Notification Title</label>
                      <input
                        class="form-control"
                        placeholder="Notification Title"
                        type="text"
                        name="notification_title"
                        v-model="form.notification_title"
                      />
                    </div>
                    <div class="form-group">
                      <label>Notification Message</label>
                      <v-textarea
                        auto-grow
                        rows="4"
                        name="notification_message"
                        placeholder="Notification Message"
                        v-model="form.notification_message"
                        outlined
                      ></v-textarea>
                    </div>
                    <button type="submit" class="btn btn-primary">
                      Send Notification
                    </button>
                  </div>
                </div>
              </form>
            </v-app>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
export default {
  name: "PushNotification",
  data() {
    return {
      form: {
        notification_title: "",
        notification_message: "",
      },
    };
  },
  methods: {
    sendNotification() {},
  },
};
</script>