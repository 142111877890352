<template>
  <main role="main" class="bd-content">
    <div class="main-content">
      <div class="section section-vaccine">
        <form @submit.prevent="saveVaccine">
          <div class="panel">
            <div class="panel-header" :class="panelHeader ? 'sticky' : ''">
              <div class="title-wrap">
                <div class="title-with-back">
                  <router-link
                    class="back-arrow"
                    :to="/pet/ + this.$route.params.id"
                  >
                    <back-arrow></back-arrow>
                  </router-link>
                  <h3 class="panel-title">Add a vaccine record</h3>
                </div>
                <div class="action">
                  <button
                    type="submit"
                    class="btn btn-primary btn-save"
                    :disabled="isLoading"
                    dusk="btn_save"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
            <div class="panel-body">
              <v-app>
                <div
                  class="form-area"
                  v-for="(form, index) in vaccineMultiples"
                  :key="index"
                  :class="index == 0 ? '' : 'new'"
                >
                  <div class="form-wrap" :class="index == 0 ? '' : 'new'">
                    <div class="row">
                      <div class="col-12">
                        <div class="title-wtih-button">
                          <h4 class="dose-title">Dose {{ index + 1 }}</h4>
                          <div class="action" v-if="index != 0">
                            <button
                              class="btn-text btn-delete"
                              type="button"
                              @click="deleteVaccineForm(index)"
                            >
                              Remove
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label
                            >Vaccine <span class="req-field">*</span></label
                          >
                          <v-autocomplete
                            v-model="form.vaccine_type_id"
                            :items="vaccineOptions"
                            item-text="name"
                            item-value="id"
                            placeholder="Select Vaccine"
                            outlined
                            dusk="vaccine"
                            @change="changeVaccineType(index)"
                            @click="
                              removeValidation(index + '.vaccine_type_id')
                            "
                            :readonly="index != 0"
                          ></v-autocomplete>
                          <div class="error-container">
                            <div v-for="(error, key) in errors" :key="key">
                              <span
                                v-for="(errorItem, innerKey) in error"
                                :key="innerKey"
                                style="margin-top: 2rem"
                              >
                                <small
                                  class="text-danger"
                                  v-if="key == index + '.vaccine_type_id'"
                                >
                                  <template
                                    v-if="
                                      form.vaccine_type_id == null ||
                                      form.vaccine_type_id == ''
                                    "
                                  >
                                    {{ errorItem }}
                                  </template>
                                </small>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>Clinic<span class="req-field">*</span></label>
                          <input
                            class="form-control"
                            placeholder="Clinic"
                            type="text"
                            name="clinic"
                            :id="'clinic_' + index"
                            :index="index"
                            :value="form.clinic"
                            @change="changeClinic($event, index)"
                          />
                          <div class="error-container">
                            <div v-for="(error, key) in errors" :key="key">
                              <span
                                v-for="(errorItem, innerKey) in error"
                                :key="innerKey"
                                style="margin-top: 2rem"
                              >
                                <small
                                  class="text-danger"
                                  v-if="key == index + '.clinic'"
                                >
                                  <template
                                    v-if="
                                      form.clinic == null || form.clinic == ''
                                    "
                                  >
                                    {{ errorItem }}
                                  </template>
                                </small>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <div class="datepicker-area">
                            <label
                              >Injection Date
                              <span
                                class="req-field"
                                v-if="
                                  form.booster_date == null ||
                                  form.booster_date == ''
                                "
                                >*</span
                              ></label
                            >
                            <v-menu
                              v-model="form.injectionDatePicker"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              min-width="auto"
                              offset-y
                              class="datepicker"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="form.injection_date"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  dusk="injection_date"
                                  clearable
                                  @click:clear="injectionDateChanged(form)"
                                  placeholder="Select injection date"
                                  @click="
                                    removeValidation(index + '.injection_date')
                                  "
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                color="#ff5959 "
                                :max="new Date().toISOString().slice(0, 10)"
                                v-model="form.injection_date"
                                @input="injectionDateChanged(form)"
                              ></v-date-picker>
                            </v-menu>
                            <div class="error-container">
                              <div v-for="(error, key) in errors" :key="key">
                                <span
                                  v-for="(errorItem, innerKey) in error"
                                  :key="innerKey"
                                  style="margin-top: 2rem"
                                >
                                  <small
                                    class="text-danger"
                                    v-if="key == index + '.injection_date'"
                                  >
                                    <template
                                      v-if="
                                        form.injection_date == null ||
                                        form.injection_date == ''
                                      "
                                    >
                                      {{ errorItem }}
                                    </template>
                                  </small>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <div class="datepicker-area">
                            <label
                              >Booster Date
                              <span
                                class="req-field req-booster"
                                :class="
                                  form.injection_date != null ? '' : 'show'
                                "
                                >*</span
                              ></label
                            >
                            <v-menu
                              v-model="form.boosterDatePicker"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="form.booster_date"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  dusk="booster_date"
                                  clearable
                                  placeholder="Select Booster Date"
                                  @click="
                                    removeValidation(index + '.booster_date')
                                  "
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-bind:min="form.minBoosterDate"
                                color="#ff5959 "
                                v-model="form.booster_date"
                                @input="form.boosterDatePicker = false"
                              ></v-date-picker>
                            </v-menu>
                            <div class="error-container">
                              <div v-for="(error, key) in errors" :key="key">
                                <span
                                  v-for="(errorItem, innerKey) in error"
                                  :key="innerKey"
                                  style="margin-top: 2rem"
                                >
                                  <small
                                    class="text-danger"
                                    v-if="key == index + '.booster_date'"
                                  >
                                    <template
                                      v-if="
                                        form.booster_date == null ||
                                        form.booster_date == ''
                                      "
                                    >
                                      {{ errorItem }}
                                    </template>
                                  </small>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>Batch Number (Optional)</label>
                          <input
                            class="form-control"
                            placeholder="Enter batch number"
                            type="text"
                            name="batch_number"
                            v-model="form.batch_number"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label
                            >Country <span class="req-field">*</span></label
                          >
                          <v-autocomplete
                            v-model="form.country"
                            :items="countryOptions"
                            item-text="country"
                            item-value="country"
                            dusk="country"
                            outlined
                            placeholder="Select Country"
                            @click="removeValidation(index + '.country')"
                          ></v-autocomplete>
                          <div class="error-container">
                            <div v-for="(error, key) in errors" :key="key">
                              <span
                                v-for="(errorItem, innerKey) in error"
                                :key="innerKey"
                                style="margin-top: 2rem"
                              >
                                <small
                                  class="text-danger"
                                  v-if="key == index + '.country'"
                                >
                                  <template
                                    v-if="
                                      form.country == null || form.country == ''
                                    "
                                  >
                                    {{ errorItem }}
                                  </template>
                                </small>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group last">
                          <label>Medical Product (Optional)</label>
                          <input
                            class="form-control"
                            placeholder="Enter medical product"
                            type="text"
                            name="medical_product"
                            v-model="form.medical_product"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group last">
                          <label>Manufacturer (Optional)</label>
                          <input
                            class="form-control"
                            placeholder="Enter manufacturer"
                            type="text"
                            name="manufacture"
                            v-model="form.manufacture"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="action"
                    v-if="index == vaccineMultiples.length - 1"
                  >
                    <button
                      type="button"
                      class="btn btn-primary btn-add"
                      @click="multiplyForm(index)"
                      :disabled="
                        form.vaccine_type_id == '' ||
                        form.vaccine_type_id == null ||
                        form.clinic == null ||
                        form.clinic == '' ||
                        form.country == '' ||
                        form.country == null ||
                        form.booster_date == '' ||
                        form.booster_date == null
                      "
                    >
                      <span> Add a dose</span>
                      <add-with-circle></add-with-circle>
                    </button>
                  </div>
                </div>
              </v-app>
            </div>
          </div>
        </form>
      </div>
    </div>
  </main>
</template>
<script>
import BackArrow from "../icons/BackArrow.vue";
import AddWithCircle from "../icons/AddWithCircle.vue";

export default {
  name: "createVaccineRecord",
  components: {
    BackArrow,
    AddWithCircle,
  },
  data() {
    return {
      id: this.$route.params.id,
      pet_type: 0,
      vaccineOptions: [],
      countryOptions: [],
      isLoading: null,
      date: new Date().toISOString().substr(0, 10),
      
      vaccineMultiples: [
        {
          injectionDatePicker: false,
          boosterDatePicker: false,
          vaccine_type_id: "",
          injection_date: null,
          country: "",
          manufacture: "",
          clinic: "",
          booster_date: "",
          batch_number: "",
          medical_product: "",
          minBoosterDate: "",
        },
      ],
      errors: {},
      prevDate: "",
      panelHeader: false,
      headerHeight: "",
    };
  },
  created() {
    const token = localStorage.getItem("access_token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    this.getPetType();
    this.getVaccines();
    this.getCountries();
  },
  computed: {
    getEndDate() {
      var endDate = new Date(
        this.date.getFullYear(),
        this.date.getMonth() + 1,
        10
      );
      return endDate.toISOString().slice(0, 10);
    },
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
    this.headerHeight =
      $(".panel-header").offset().top - $("nav.main-navbar.navbar").height();
    // Initialize google autocomplete place
    this.InitializeGoogleAutocomplete(0);
  },
  methods: {
    InitializeGoogleAutocomplete(index) {
      var options = {
        types: ["establishment"],
        componentRestrictions: { country: "hk" },
      };
      var address = document.getElementById("clinic_" + index);
      var autocomplete = new google.maps.places.Autocomplete(address, options);
      google.maps.event.addListener(autocomplete, "place_changed", function () {
        var place = autocomplete.getPlace();
        if (!place.geometry) {
          // console.log("You entered: " + index + place.name);
          return place;
        }
        var formatted_address_with_name =
          place.name + ", " + place.formatted_address;
        return formatted_address_with_name;
        // console.log("You selected: " + index + formatted_address_with_name);
      });
    },
    getPetType() {
      axios
        .get(`/admin/pet/${this.id}`)
        .then(({ data }) => {
          this.pet_type = data.pet_type;
          this.vaccineMultiples[0].clinic = data.clinic;;
          //this.vaccineMultiples[0].country = data.country;
      
          //console.log(data.country);
        })
        .catch((errors) => console.log(errors));
    },
    getVaccines() {
      axios
        .post(`/admin/vaccine/types/${this.pet_type}`)
        .then(({ data }) => {
          this.vaccineOptions = data;
          // console.log(this.vaccineOptions);
        })
        .catch((errors) => console.log(errors));
    },
    getCountries() {
      axios
        .get(`/admin/countries`)
        .then(({ data }) => {
          this.countryOptions = data;
          // console.log(this.countryOptions);
        })
        .catch((errors) => console.log(errors));
    },
    saveVaccine() {
      this.isLoading = true;
      axios
        .post(`/admin/vaccine/records/${this.id}`, this.vaccineMultiples)
        .then(({ data }) => {
          Toast.success();
          this.$router.push({ name: "pets-details", params: { id: this.id } });
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.isLoading = false;
          // console.log(error.response);
          if (error.response.status === 409) {
            Toast.error(error.response.data.message);
          }
          // console.log(this.errors);
        });
    },
    multiplyForm(index) {
      // console.log("index" + index);

      var startDate = new Date(this.vaccineMultiples[index].booster_date);
      var day = 60 * 60 * 24 * 1000;
      var increasedByOne = new Date(startDate.getTime() + day)
        .toISOString()
        .substr(0, 10);
      // }
      this.vaccineMultiples.push({
        vaccine_type_id: this.vaccineMultiples[0].vaccine_type_id,
        injection_date: this.vaccineMultiples[index].booster_date,
        country: this.vaccineMultiples[index].country,
        manufacture: this.vaccineMultiples[index].manufacture,
        clinic: this.vaccineMultiples[index].clinic,
        booster_date: "",
        batch_number: this.vaccineMultiples[index].batch_number,
        medical_product: this.vaccineMultiples[index].medical_product,
        minBoosterDate: increasedByOne,
      });
      var dose = index + 1;
      // console.log("This" + dose);
      setTimeout(() => {
        this.InitializeGoogleAutocomplete(dose);
      }, 100);
    },
    deleteVaccineForm(index) {
      this.vaccineMultiples.splice(index, 1);
    },
    injectionDateChanged(form) {
      form.injectionDatePicker = false;
      // form.booster_date = "";
      var startDate = new Date(form.injection_date);
      var day = 60 * 60 * 24 * 1000;
      form.minBoosterDate = new Date(startDate.getTime() + day)
        .toISOString()
        .substr(0, 10);
      // }
    },
    changeVaccineType(index) {
      //   this.vaccineMultiples.map(
      //     (e) =>
      //       (e.vaccine_type_id = this.vaccineMultiples[index].vaccine_type_id)
      //   );
    },
    changeClinic(e, index) {
      let valTEst = null;
      setTimeout(() => {
        valTEst = $("#clinic_" + index).val();
        this.vaccineMultiples[index].clinic = valTEst;
      }, 500);
    },
    removeValidation(key) {
      // this.errors = null;
      this.errors.key = null;
    },
    updateScroll() {
      // console.log(window.scrollY );
      if (window.scrollY >= this.headerHeight) {
        this.panelHeader = true;
        $("body").css("overflow", "initial");
      } else {
        this.panelHeader = false;
        $("body").css("overflow", "hidden");
      }
    },
  },
};
</script>


