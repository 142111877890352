<template>
  <main role="main" class="bd-content">
    <div class="main-content">
      <div class="section section-nutrition">
        <div class="panel">
          <div class="panel-body">
            <v-app class="mt-12 ml-12">
              <v-data-table
                class="elevation-1"
                :headers="headers"
                :items="nutrition"
                :loading="loading"
                loading-text="Loading... Please wait"
                hide-default-footer
                :items-per-page="-1"
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <div class="toolbar-left">
                      <v-card-title>
                        <v-toolbar-title class="title-with-search">
                          <div class="title-wrap">
                            <h3 class="panel-title">Nutrition</h3>
                          </div>
                          <div class="search-toolbar-area">
                            <v-text-field
                              name="search"
                              v-model="search"
                              single-line
                              hide-details
                              placeholder="Search an item "
                            ></v-text-field>
                          </div>
                        </v-toolbar-title>
                      </v-card-title>
                    </div>
                    <div class="toolbar-right">
                      <div class="category-select">
                        <CategoryFilter
                          :newCategoryOption="newCategoryOption"
                          @changedCategoryOption="getChangedCategoryOption"
                        ></CategoryFilter>
                      </div>
                      <div class="action">
                        <router-link
                          class="btn btn-primary btn-add"
                          to="/nutrition/create"
                        >
                          Add item
                        </router-link>
                      </div>
                    </div>

                    <!-- modal for Delete -->
                    <v-dialog v-model="dialogDelete" max-width="460px">
                      <v-card class="modal-actionDelete">
                        <div class="modal-head">
                          <v-card-title class="card-title">Delete</v-card-title>
                        </div>
                        <v-card-subtitle>
                          Are you sure want to delete?
                        </v-card-subtitle>
                        <v-card-actions>
                          <v-btn
                            class="btn btn-secondary"
                            text
                            @click="closeDelete"
                            >Cancel</v-btn
                          >
                          <v-btn
                            class="btn btn-primary btn-sucess"
                            text
                            @click="deleteItemConfirm"
                            >Yes, delete</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-toolbar>
                  <div class="bottom-toolbar">
                    <div class="tab-wrap">
                      <template>
                        <v-tabs>
                          <v-tabs-slider color="#e77386"></v-tabs-slider>
                          <v-tab @click="getNutrition(1, 'dog')"> Dog </v-tab>
                          <v-tab @click="getNutrition(1, 'cat')"> Cat </v-tab>
                        </v-tabs>
                      </template>
                    </div>
                    <div class="right-area">
                      <div class="nutrition-import-area">
                        <form @submit.prevent="importNutrition">
                          <div class="form-group">
                            <div class="form-row">
                              <div class="form-title-wrap">
                                <h3 class="form-title">Import Nutrition</h3>
                              </div>
                              <div class="field-wrap">
                                <div class="file-area" v-if="import_file_state">
                                  <label for="">File name: </label>
                                  <span
                                    class="file-name"
                                    v-if="import_file_name != null"
                                    >{{ import_file_name }}</span
                                  >
                                  <div
                                    v-if="error.message"
                                    class="invalid-feedback"
                                  >
                                    Invalid
                                  </div>
                                </div>
                                <div
                                  class="import-action"
                                  v-if="!import_file_state"
                                >
                                  <a
                                    class="btn btn-primary"
                                    @click="$refs.import_file.click()"
                                  >
                                    Import
                                  </a>

                                  <input
                                    type="file"
                                    class="form-control"
                                    id="input-file-import"
                                    name="file_import"
                                    ref="import_file"
                                    accept=".xlsx, .xls"
                                    @change="onFileChange"
                                  />
                                </div>

                                <div class="actions" v-else>
                                  <button
                                    type="submit"
                                    class="btn btn-primary"
                                    :disabled="loading"
                                  >
                                    Submit
                                    <span
                                      class="loading-dots"
                                      v-if="loading"
                                    ></span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                      <div class="action-download">
                        <a :href="csvFileLink" class="btn btn-primary" download>
                          Download sample file
                        </a>
                      </div>
                      <div class="switch">
                        <input
                          id="language-toggle"
                          v-model="languageSwitch"
                          class="check-toggle check-toggle-round-flat"
                          type="checkbox"
                          @change="switchToggle($event)"
                        />
                        <label for="language-toggle"></label>
                        <span class="on">EN</span>
                        <span class="off">ZH</span>
                      </div>
                      <div class="action">
                        <router-link
                          class="btn btn-primary btn-add"
                          to="/nutrition/create"
                        >
                          Add item
                        </router-link>
                      </div>
                    </div>
                  </div>
                </template>

                <template v-slot:item.sn="{ index }">
                  {{ index + 1 }}
                </template>
                <template v-slot:item.item_translations="{ item }">
                  <div v-if="item.all_translations[0]">
                    {{ item.all_translations[0].item }}
                  </div>
                </template>
                <!-- <template v-slot:item.pet_type="{ item }">
                  {{ item.pet_type == 0 ? "Dog" : "Cat" }}
                </template> -->
                <template v-slot:item.is_processed="{ item }">
                  {{
                    item.is_processed == null
                      ? "-"
                      : item.is_processed == 0
                      ? "No"
                      : "Yes"
                  }}
                </template>
                <template v-slot:item.is_dcm="{ item }">
                  {{
                    item.is_processed == null
                      ? "-"
                      : item.is_processed == 0
                      ? "No"
                      : "Yes"
                  }}
                </template>
                <template v-slot:item.is_dcm="{ item }">
                  {{
                    item.is_dcm == null ? "-" : item.is_dcm == 0 ? "No" : "Yes"
                  }}
                </template>
                <template v-slot:item.is_highfat="{ item }">
                  {{
                    item.is_highfat == null
                      ? "-"
                      : item.is_highfat == 0
                      ? "No"
                      : "Yes"
                  }}
                </template>
                <template v-slot:item.content_translations="{ item, index }">
                  <div class="desc-wrap" v-if="item.all_translations[0]">
                    <div
                      class="short-content"
                      :class="'shortContent-' + index"
                      @click="
                        item.all_translations[0].content.length > 130
                          ? showFullContent(index)
                          : ''
                      "
                    >
                      {{ item.all_translations[0].content.substring(0, 130) }}
                    </div>
                    <div
                      class="long-content"
                      v-if="item.all_translations[0].content.length > 130"
                    >
                      {{ item.all_translations[0].content.substring(0) }}
                    </div>
                  </div>
                  <div class="overlay" @click="hideFullContent(index)"></div>
                </template>

                <template v-slot:item.actions="{ item }">
                  <div class="action">
                    <router-link
                      :to="{ name: 'NutritionEdit', params: { id: item.id } }"
                      class="btn btn-view-text"
                      >Edit</router-link
                    >
                    <button
                      class="btn btn-delete-text"
                      @click="deleteItem(item)"
                    >
                      Delete
                      <div class="text-animation"></div>
                    </button>
                  </div>
                </template>
              </v-data-table>

              <Pagination
                :totalItems="pagination.totalItems + ' Nutrition'"
                :totalPages="pagination.totalPages"
                :perPage="pagination.perPage"
                :currentPage="pagination.currentPage"
                @pagechanged="getNutrition"
              ></Pagination>
            </v-app>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import Pagination from "../subComponents/pagination.vue";
import CategoryFilter from "./categoryFilter.vue";

export default {
  name: "nutrition",
  props: ["csv_path"],
  components: {
    Pagination,
    CategoryFilter,
  },
  data() {
    return {
      csvFileLink: this.csv_path,
      error: {},
      import_file: "",
      import_file_name: "",
      import_file_state: false,
      languageSwitch: false,
      pagination: {
        totalItems: 0,
        totalPages: 1,
        perPage: 0,
        currentPage: 1,
      },
      paginationOf: "nutrition",
      form: {
        pet_type: 0,
        lang_code: "en",
      },
      nutrition: [],
      newCategoryOption: null,
      loading: false,
      dialog: false,
      showNutrition: false,
      dialogDelete: false,
      headers: [
        {
          text: "S.N.",
          align: "left",
          sortable: false,
          value: "sn",
          width: "30px",
        },
        {
          text: "Item",
          align: "left",
          sortable: true,
          value: "item_translations",
          width: "120px",
        },
        {
          text: "CATEGORY",
          align: "left",
          sortable: true,
          value: "nutrition_category.name",
          width: "130px",
        },
        {
          text: "Edible",
          align: "left",
          sortable: false,
          value: "edible",
        },
        {
          text: "Processed",
          align: "left",
          sortable: false,
          value: "is_processed",
        },
        {
          text: "dcm",
          align: "left",
          sortable: false,
          value: "is_dcm",
        },
        {
          text: "highfat",
          align: "left",
          sortable: false,
          value: "is_highfat",
        },
        {
          text: "Total searches",
          align: "left",
          sortable: false,
          value: "total_search",
          width: "160px",
        },

        {
          text: "content",
          align: "left",
          sortable: false,
          value: "content_translations",
          width: "320px",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "start",
          width: "150px",
        },
      ],
      editedIndex: -1,
      editedItem: {
        id: "",
      },
      defaultItem: {
        id: "",
        img_name: "",
        name: "",
      },
      search: null,
      awaitingSearch: false,
    };
  },
  created() {
    const token = localStorage.getItem("access_token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    this.getNutrition(this.pagination.currentPage, this.form);
  },
  watch: {
    search: function (val) {
      if (this.search == null || this.search == "") {
        this.awaitingSearch = false;
        this.getNutrition(this.pagination.currentPage, this.form);
        return false;
      } else {
        if (!this.awaitingSearch) {
          setTimeout(() => {
            if (this.search === null || this.search === "") {
              return;
            }
            this.loading = true;
            axios
              .post(
                `/admin/nutrition/search/${this.search}?page=${this.pagination.currentPage}`,
                this.form
              )
              .then(({ data }) => {
                this.paginationOf = "search";
                this.pagination.totalItems = data.total;
                this.pagination.totalPages = data.last_page;
                this.pagination.perPage = data.per_page;
                this.pagination.currentPage = data.current_page;
                this.nutrition = data.data;
                this.loading = false;
              })
              .catch((errors) => console.log(errors));
            this.awaitingSearch = false;
          }, 2000); // 2 sec delay
        }
        this.awaitingSearch = true;
      }
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  methods: {
    searchBy(value) {
      this.search = value;
      // console.log(this.search);
    },
    getNutrition(page, pet_type) {
      this.search = null;
      this.loading = true;
      this.pagination.currentPage = page;
      if (pet_type == "dog") {
        this.form.pet_type = 0;
        this.headers = [
          {
            text: "S.N.",
            align: "left",
            sortable: false,
            value: "sn",
            width: "30px",
          },
          {
            text: "Item",
            align: "left",
            sortable: true,
            value: "item_translations",
            width: "120px",
          },
          {
            text: "CATEGORY",
            align: "left",
            sortable: true,
            value: "nutrition_category.name",
            width: "130px",
          },
          {
            text: "Edible",
            align: "left",
            sortable: false,
            value: "edible",
          },
          {
            text: "Processed",
            align: "left",
            sortable: false,
            value: "is_processed",
          },
          {
            text: "dcm",
            align: "left",
            sortable: false,
            value: "is_dcm",
          },
          {
            text: "highfat",
            align: "left",
            sortable: false,
            value: "is_highfat",
          },
          {
            text: "Total searches",
            align: "left",
            sortable: false,
            value: "total_search",
            width: "160px",
          },

          {
            text: "content",
            align: "left",
            sortable: false,
            value: "content_translations",
            width: "320px",
          },
          {
            text: "Actions",
            value: "actions",
            sortable: false,
            align: "start",
            width: "150px",
          },
        ];
      }
      if (pet_type == "cat") {
        this.form.pet_type = 1;
        this.headers = [
          {
            text: "S.N.",
            align: "left",
            sortable: false,
            value: "sn",
            width: "30px",
          },
          {
            text: "Item",
            align: "left",
            sortable: true,
            value: "item_translations",
            width: "120px",
          },
          {
            text: "CATEGORY",
            align: "left",
            sortable: true,
            value: "nutrition_category.name",
            width: "130px",
          },
          {
            text: "Edible",
            align: "left",
            sortable: false,
            value: "edible",
          },
          {
            text: "Processed",
            align: "left",
            sortable: false,
            value: "is_processed",
          },
          {
            text: "highfat",
            align: "left",
            sortable: false,
            value: "is_highfat",
          },
          {
            text: "Total searches",
            align: "left",
            sortable: false,
            value: "total_search",
            width: "160px",
          },

          {
            text: "content",
            align: "left",
            sortable: false,
            value: "content_translations",
            width: "320px",
          },
          {
            text: "Actions",
            value: "actions",
            sortable: false,
            align: "start",
            width: "150px",
          },
        ];
      }
      axios
        .post(`/admin/nutrition?page=${this.pagination.currentPage}`, this.form)
        .then(({ data }) => {
          // console.log(data);
          this.paginationOf = "nutrition";
          this.pagination.totalItems = data.total;
          this.pagination.totalPages = data.last_page;
          this.pagination.perPage = data.per_page;
          this.pagination.currentPage = data.current_page;
          this.nutrition = data.data;
          // console.log(this.nutrition);
          this.loading = false;
        })
        .catch((errors) => console.log(errors));
    },
    showFullContent(index) {
      $(".short-content:not(.shortContent-" + index + ")")
        .parent()
        .removeClass("show");
      $(".shortContent-" + index)
        .parent()
        .toggleClass("show");
      if ($(".desc-wrap").hasClass("show")) {
        $(".v-data-table__wrapper").addClass("removeOverflow");
        $("body").addClass("RemoveOverflow");
      } else {
        $(".v-data-table__wrapper").removeClass("removeOverflow");
        $("body").removeClass("RemoveOverflow");
      }
    },
    hideFullContent(index) {
      // console.log(index);
      $("body").removeClass("RemoveOverflow");
      $(".v-data-table__wrapper").removeClass("removeOverflow");
      $(".shortContent-" + index)
        .parent()
        .removeClass("show");
    },
    switchToggle(e) {
      if (e.target.checked == true) {
        // console.log(e);
        // console.log("ZH");
        this.search = null;
        this.languageSwitch = "ZH";
        this.form.lang_code = "zh";
        this.loading = true;
        axios
          .post(
            `/admin/nutrition?page=${this.pagination.currentPage}`,
            this.form
          )
          .then(({ data }) => {
            // console.log(data);
            this.paginationOf = "nutrition";
            this.pagination.totalItems = data.total;
            this.pagination.totalPages = data.last_page;
            this.pagination.perPage = data.per_page;
            this.pagination.currentPage = data.current_page;
            this.nutrition = data.data;
            // console.log(this.nutrition);
            this.loading = false;
          })
          .catch((errors) => console.log(errors));
      }
      if (e.target.checked == false) {
        // console.log(e);
        // console.log("EN");
        this.search = null;
        this.languageSwitch = false;
        this.form.lang_code = "en";
        this.loading = true;
        axios
          .post(
            `/admin/nutrition?page=${this.pagination.currentPage}`,
            this.form
          )
          .then(({ data }) => {
            // console.log(data);
            this.paginationOf = "nutrition";
            this.pagination.totalItems = data.total;
            this.pagination.totalPages = data.last_page;
            this.pagination.perPage = data.per_page;
            this.pagination.currentPage = data.current_page;
            this.nutrition = data.data;
            // console.log(this.nutrition);
            this.loading = false;
          })
          .catch((errors) => console.log(errors));
      }
    },

    deleteItem(item) {
      this.editedIndex = this.nutrition.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    deleteItemConfirm() {
      axios
        .delete("/admin/nutrition/" + this.editedItem.id)
        .then(this.nutrition.splice(this.editedIndex, 1), Toast.success())
        .catch((errors) => Toast.error());
      this.closeDelete();
    },
    //Category Filter
    getChangedCategoryOption(option) {
      this.search = null;
      this.newCategoryOption = option;
      this.loading = true;
      axios
        .post(
          `/admin/nutrition/filterby/category/${this.newCategoryOption}?page=${this.pagination.currentPage}`,
          this.form
        )
        .then(({ data }) => {
          this.paginationOf = "filterby";
          this.pagination.totalItems = data.total;
          this.pagination.totalPages = data.last_page;
          this.pagination.perPage = data.per_page;
          this.pagination.currentPage = data.current_page;
          this.nutrition = data.data;
          // console.log(data);
          this.loading = false;
        })
        .catch((errors) => console.log(errors));
    },
    importNutrition() {
      this.loading = true;
      this.uploading = true;
      let formData = new FormData();
      formData.append("import_file", this.import_file);
      axios
        .post("/admin/import-nutrition", formData, {
          headers: { "content-type": "multipart/form-data" },
        })
        .then((response) => {
          this.loading = false;
          this.import_file = "";
          this.import_file_name = "";
          this.import_file_state = false;
          if (response.status === 200) {
            Toast.success();
            this.getNutrition();
          }
        })
        .catch((error) => {
          // code here when an upload is not valid
          this.loading = false;
          this.uploading = false;
          this.error = error.response.data;
          // console.log("check error: ", this.error);
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 1000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
          Toast.fire({
            icon: "error",
            title: "Invalid file format!",
          });
          this.import_file_state = false;
          // Toast.error();
        });
    },
    onFileChange(e) {
      this.import_file = e.target.files[0];
      this.import_file_state = true;
      this.import_file_name = this.import_file.name;
    },
  },
};
</script>
