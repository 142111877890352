<template>
  <main role="main" class="bd-content">
    <div class="main-content">
      <section class="section section-onboard">
        <div class="panel">
          <!-- <div class="panel-header">
          <div class="title-wrap">
            <h3 class="panel-title">Onboarding screens</h3>
          </div>

        </div>-->
          <div class="panel-body">
            <v-app id="inspire">
              <v-data-table
                :headers="headers"
                :items="onboardings"
                :items-per-page="15"
                sort-by="title"
                class="elevation-1"
                :loading="loadTable"
                loading-text="Loading... Please wait"
              >
                <template v-slot:item.sn="{ index }">
                  {{ index + 1 }}
                </template>
                <template v-slot:item.thumbnail="{ item }">
                  <div class="thumb-wrap">
                    <img
                      :src="item.thumbnail"
                     class="board-img"
                    />
                  </div>
                </template>
                <template v-slot:top>
                  <v-toolbar flat>
                    <div class="toolbar-left">
                      <v-card-title>
                        <div class="title-wrap">
                          <h3 class="panel-title">Onboarding screens</h3>
                        </div>
                      </v-card-title>
                      <div class="select-toolbar-area">
                        <div class="toolbar-wrap">
                          <!-- <label>Select by: </label> -->
                          <!-- Filter for language -->
                          <v-select
                            :items="languages"
                            item-text="name"
                            item-value="code"
                            :clearable="false"
                            outlined
                            v-model="languagesFilterValue"
                          ></v-select>
                        </div>
                      </div>
                    </div>
                    <!-- template form  new modal-->
                    <v-dialog v-model="dialog" max-width="740px">
                      <!-- <template v-slot:activator="{ on, attrs }">
                        <div class="toobar-right">
                          <button
                            color="primary"
                            dark
                            class="btn btn-primary btn-new"
                            v-bind="attrs"
                            v-on="on"
                          >
                            New Item
                          </button>
                        </div>
                      </template> -->
                      <v-card class="modal-actionEdit">
                        <v-card-title>
                          <h3 class="card-title">{{ formTitle }}</h3>
                        </v-card-title>

                        <v-card-text>
                          <v-row class="edit-onbaord">
                            <v-col class="image-area" md="5">
                              <figure>
                                <label>Image</label>
                                <div
                                  class="drop display-inline"
                                  id="img-upload"
                                  @dragover.prevent
                                  @drop="onDrop"
                                >
                                  <label
                                    class="trigger-element"
                                    :class="!editedItem.image ? '' : 'hidden'"
                                  >
                                    SELECT OR DROP AN IMAGE
                                    <input
                                      type="file"
                                      name="image"
                                      @change="onChange"
                                    />
                                  </label>
                                  <div
                                    class="hidden display-inline align-center"
                                    v-bind:class="{ image: true }"
                                  >
                                    <div class="img-wrap">
                                      <img
                                        :src="editedItem.image"
                                        alt="Broken Image"
                                        class="img"
                                      />
                                    </div>
                                    <button
                                      class="btn-remove"
                                      @click="uploadFile"
                                    >
                                      Update image
                                    </button>
                                  </div>
                                </div>
                              </figure>
                            </v-col>
                            <v-col class="field-area" cols="12" sm="6" md="7">
                              <v-select
                                :items="languages"
                                item-text="name"
                                item-value="code"
                                :clearable="false"
                                v-model="editedItem.lang_code"
                                label="Language"
                              ></v-select>
                              <v-text-field
                                v-model="editedItem.title"
                                label="Title"
                              ></v-text-field>
                              <v-textarea
                                v-model="editedItem.content"
                                label="Description"
                              ></v-textarea>

                              <v-text-field
                                label="Position"
                                type="number"
                                step="1"
                                min="0"
                                ref="input"
                                v-model.number="editedItem.order"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-card-text>

                        <v-card-actions>
                          <v-row>
                            <v-col style="margin-left: 318px">
                              <v-btn
                                class="btn btn-primary btn-save"
                                color=""
                                text
                                @click="save"
                              >
                                Update
                              </v-btn>
                              <v-btn
                                class="btn btn-secondary btn-cancel"
                                color=""
                                text
                                @click="close"
                              >
                                Cancel
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <!-- modal for Delete -->
                    <v-dialog v-model="dialogDelete" class="modal-actionDelete">
                      <v-card>
                        <v-card-title class="card-title">Delete</v-card-title>
                        <v-card-subtitle>
                          Are you sure want to delete?
                        </v-card-subtitle>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="blue darken-1" text @click="closeDelete"
                            >Cancel</v-btn
                          >
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="deleteItemConfirm"
                            >OK</v-btn
                          >
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <!--End div modal for Delete -->
                  </v-toolbar>
                </template>
                <!-- actions of Edit/Delete -->
                <template v-slot:item.actions="{ item }">
                  <div class="action">
                    <button class="btn btn-edit" @click="editItem(item)">
                      <div class="text-animation"></div>
                    </button>

                    <!-- <button class="btn btn-trash" @click="deleteItem(item)">
                      <div class="text-animation"></div>
                    </button> -->
                  </div>
                </template>
                <!-- End actions of Edit/Delete -->
                <template v-slot:no-data>
                  No Records Found
                  <!-- <v-btn color="primary" @click="initialize"> Reset </v-btn> -->
                </template>
              </v-data-table>
            </v-app>
          </div>
        </div>
      </section>
    </div>
  </main>
</template>
<script>
export default {
  name: "Onboarding",
  components: {},
  data() {
    return {
      loadTable: true,
      dialog: false,
      dialogDelete: false,
      dialog: false,
      dialogDelete: false,
      headers: [
        {
          text: "S.N.",
          align: "center",
          sortable: false,
          value: "sn",
        },
        {
          text: "Image",
          value: "thumbnail",
          sortable: false,
          width: '90px',
        },
        {
          text: "Language",
          value: "lang_code",
          sortable: false,
          align: "center",
          filter: this.languagesFilter,
        },
        { text: "Title", value: "title", sortable: true },
        { text: "Description", value: "content", width: "25%" },
        {
          text: "Position",
          value: "order",
          sortable: true,
          align: "center",
        },
        { text: "Actions", value: "actions", sortable: false, align: "center" },
      ],
      onboardings: [],
      languages: [],
      languagesFilterValue: "en",
      onboardingFilterValue: "",
      editedIndex: -1,
      editedItem: {
        title: "",
        content: "",
        image: "",
        thumbnail: "",
        order: "",
        parent_id: "",
        status: "",
        lang_code: "",
      },
      defaultItem: {
        title: "",
        content: "",
        image: "",
        thumbnail: "",
        order: "",
        parent_id: "",
        status: "",
        lang_code: "",
      },
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add Onboarding" : "Edit Onboarding";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  created() {
    const token = localStorage.getItem("access_token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    this.initialize();
    this.getLanguages();
  },
  methods: {
    initialize() {
      axios
        .get("/admin/onboarding")
        .then(({ data }) => {
          return (this.onboardings = data), (this.loadTable = false);
          // console.log(data);
        })
        .catch((errors) => console.log(errors));
    },
    getLanguages() {
      axios
        .get("/admin/language")
        .then(({ data }) => {
          return (this.languages = data);
        })
        .catch((errors) => console.log(errors));
    },
    languagesFilter(value) {
      if (!this.languagesFilterValue) {
        return true;
      }
      return value === this.languagesFilterValue;
    },

    editItem(item) {
      this.editedIndex = this.onboardings.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.onboardings.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      axios
        .delete("/admin/onboarding/" + this.editedItem.id)
        .then(this.onboardings.splice(this.editedIndex, 1), Toast.success())
        .catch((errors) => Toast.error());
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        // console.log("Delete cancelled");
      });
    },

    save() {
      if (this.editedIndex > -1) {
        //  Update Data
        const updateData = {
          title: this.editedItem.title,
          content: this.editedItem.content,
          image: this.editedItem.image,
          thumbnail: (this.editedItem.thumbnail = this.editedItem.image),
          order: this.editedItem.order,
          lang_code: this.editedItem.lang_code,
        };
        axios
          .put("/admin/onboarding/" + this.editedItem.id, updateData)
          .then(
            Object.assign(this.onboardings[this.editedIndex], this.editedItem),
            Toast.success()
          )
          .catch((errors) => Toast.error());
      } else {
        // Store data
        const storeData = {
          title: this.editedItem.title,
          content: this.editedItem.content,
          image: this.editedItem.image,
          thumbnail: this.editedItem.thumbnail,
          order: this.editedItem.order,
          lang_code: this.editedItem.lang_code,
        };
        axios
          .post("/admin/onboarding/", storeData)
          .then(this.onboardings.push(this.editedItem), Toast.success())
          .catch((errors) => Toast.error());
      }
      this.close();
    },
    onDrop: function (e) {
      e.stopPropagation();
      e.preventDefault();
      var files = e.dataTransfer.files;
      this.createFile(files[0]);
    },
    onChange(e) {
      var files = e.target.files;
      this.createFile(files[0]);
    },
    createFile(file) {
      if (!file.type.match("image.*")) {
        alert("Select an image");
        return;
      }
      var img = new Image();
      var reader = new FileReader();
      var vm = this;

      reader.onload = function (e) {
        vm.editedItem.image = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    uploadFile() {
      //   this.editedItem.image = "";
      setTimeout(() => {
        $('#img-upload input[name="image"]').trigger("click");
      }, 10);
    },
  },
};
</script>

