<template>
  <div class="auth">
    <div class="login-wrapper">
      <form @submit.prevent="login()">
        <!-- <input type="hidden" name="_token" :value="csrf" /> -->

        <!-- Email -->
        <div class="panel">
          <div class="panel-header">
            <div class="logo-wrap">
              <img
                class="logo"
                src="images/logo-white.png"
                width="150"
                height="30"
                alt="logo "
              />
            </div>
          </div>
          <div class="panel-body">
            <!-- <div class="error-message"></div> -->
            <div class="form-group">
              <input
                class="form-control"
                placeholder="Email Address"
                type="email"
                name="email"
                v-model="form.email"
                required
                :class="['', isEmailValid()]"
                @click="removeError"
              />
              <div class="pre-icon icon-email"></div>
              <!-- error message -->
              <small class="text-danger" v-if="emailValidation">
                Please enter a valid email address
              </small>
            </div>

            <!-- password -->
            <div class="form-group">
              <input
                class="form-control"
                placeholder="Password"
                type="password"
                name="password"
                v-model="form.password"
                required
                @click="removeError"
              />
              <div class="pre-icon icon-password"></div>
              <!-- error message -->
              <small class="text-danger" v-if="errors">
                <!-- Invaild email or password. Please try again. -->
                {{ errors.message }}
              </small>
            </div>
          </div>

          <div class="panel-footer">
            <!-- Submit Button -->
            <button
              type="submit"
              class="btn btn-primary btn-login"
              :disabled="isDisable"
            >
              Log In
              <span class="loading-dots" v-if="loading"></span>
            </button>
            <div class="forgot-password">
              <router-link :to="{ name: 'forgot-password' }">
                Forgot password?
              </router-link>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "login",
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      isDisable: true,
      errors: {},
      reg: '/^(([^<>()[]\\.,;:s@"]+(.[^<>()[]\\.,;:s@"]+)*)|(".+"))@(([[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}])|(([a-zA-Z-0-9]+.)+[a-zA-Z]{2,24}))$/',
      emailValidation: false,
      loading: false,
    };
  },
  watch: {
    form: {
      handler: function (v) {
        if (this.form.email == "" || this.form.password == "") {
          return (this.isDisable = true);
        } else {
          return (this.isDisable = false);
        }
      },
      deep: true,
    },
  },
  methods: {
    login() {
      this.loading = true;
      this.$store
        .dispatch("retrieveToken", {
          email: this.form.email,
          password: this.form.password,
        })
        .then((response) => {
          this.loading = false;
          this.$router.push({ name: "dashboard" });
        })
        .catch((error) => {
          // console.log(error);
          this.errors = error;
          // console.log(this.errors);
          this.loading = false;
        });
    },
    isEmailValid() {
      if (this.form.email != "") {
        this.emailValidation =
          !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(
            this.form.email
          );
      }
    },
    removeError() {
      this.errors = {};
    },
  },
};
</script>

<style scoped>
</style>
