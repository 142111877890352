<template></template>
<script>
export default {
  beforeCreate() {
    this.$store
      .dispatch("destroyToken")
      .then((response) => {
        this.$router.push({ name: "login" });
      })
      .catch((errors) => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "error",
          title: "Unauthorized Logged out",
        });
        this.$router.push({ name: "login" });
        // console.log(errors);
      });
  },
};
</script>

