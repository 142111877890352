<template>
<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
  <g id="vaccine" transform="translate(126.038 126.035)">
    <g id="Group_4" data-name="Group 4" transform="translate(-126.038 -126.035)">
      <path id="Path_10" data-name="Path 10" d="M21.853,21.164,18.9,18.238a1.881,1.881,0,0,0,.166-1.605l-.36-1a2.054,2.054,0,0,0-.492-2.105L10.372,5.687l.085-.085a.977.977,0,0,0,0-1.38l-.771-.771a.977.977,0,0,0-1.38,0L7.222,4.535,6.208,3.521,7.292,2.437a.977.977,0,0,0,0-1.38L6.521.286a.977.977,0,0,0-1.38,0L.283,5.143a.977.977,0,0,0,0,1.38l.771.771a.976.976,0,0,0,1.38,0L3.518,6.211,4.532,7.225,3.449,8.309a.977.977,0,0,0,0,1.38l.771.771a.977.977,0,0,0,1.38,0l.085-.085,2.37,2.37h0l1.818,1.818h0l3.649,3.649a2.047,2.047,0,0,0,2.105.492l1,.361a1.864,1.864,0,0,0,.63.109,1.892,1.892,0,0,0,.938-.251l2.967,2.936a.489.489,0,1,0,.688-.7ZM1.746,6.6.975,5.835,5.831.979l.77.767L5.175,3.172l0,0-2,2-.008.01ZM5.516,4.212,6.531,5.227,5.224,6.533,4.21,5.519ZM4.14,9,9,4.144l.77.767-.429.429h0L5.352,9.327l-.013.011-.011.013-.417.417Zm10.073,8.521-1.485-1.485,1.652-1.653a.489.489,0,0,0-.691-.691l-1.652,1.653L10.91,14.217l2.5-2.5a.489.489,0,0,0-.691-.691l-2.5,2.5L9.091,12.4l1.652-1.653a.489.489,0,0,0-.691-.691L8.4,11.708,6.376,9.684,9.681,6.379l7.838,7.838a1.076,1.076,0,0,1,0,1.521l-1.784,1.784a1.076,1.076,0,0,1-1.521,0Zm2.748.624-.344-.124,1.4-1.4.123.343a.9.9,0,0,1-.21.938l-.034.034A.9.9,0,0,1,16.961,18.145Z" transform="translate(0.002 -0.001)" fill="#565656"/>
    </g>
  </g>
</svg>
</template>

<script>
export default {
  name: "VaccineUploadIcon",
};
</script>

<style>
</style>
