<template>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <path
                      d="M20,12.82A1,1,0,0,0,21.36,12c0-.34.05-.69.05-1s0-.69-.05-1A1,1,0,0,0,20,9.18h0a1,1,0,0,0-.6,1,7.32,7.32,0,0,1,0,.81,7.69,7.69,0,0,1,0,.83,1,1,0,0,0,.6,1Z"
                      fill="#181818"
                    />
                    <path
                      d="M2,12.81a1,1,0,0,0,.6-1,7.69,7.69,0,0,1,0-.83,7.32,7.32,0,0,1,0-.81,1,1,0,0,0-.6-1H2A1,1,0,0,0,.64,10c0,.34-.05.69-.05,1s0,.71.05,1A1,1,0,0,0,2,12.82Z"
                      fill="#181818"
                    />
                    <path
                      d="M7.57,2.35a.94.94,0,0,1,.12.18.91.91,0,0,0,1,.37,8.28,8.28,0,0,1,4.56,0,1,1,0,0,0,1.07-.44l0,0A1,1,0,0,0,13.79,1,10.27,10.27,0,0,0,11,.58,10.7,10.7,0,0,0,8,1,.89.89,0,0,0,7.57,2.35Z"
                      fill="#181818"
                    />
                    <path
                      d="M11,19.42a8.58,8.58,0,0,1-2.27-.32,1,1,0,0,0-1.07.42v0A1,1,0,0,0,8.23,21a10.29,10.29,0,0,0,5.54,0,1,1,0,0,0,.58-1.5v0a1,1,0,0,0-1.07-.42A8.58,8.58,0,0,1,11,19.42Z"
                      fill="#181818"
                    />
                    <path
                      d="M12.76,12.47a2.3,2.3,0,1,0-3.23.29A2.3,2.3,0,0,0,12.76,12.47Z"
                      fill="#181818"
                    />
                    <path
                      d="M20.13,14.76a3.12,3.12,0,0,0-1.32-.64,3.05,3.05,0,0,0-.6-.06,3,3,0,0,0-2.77,4.08,2.85,2.85,0,0,0,.85,1.19,3.21,3.21,0,0,0,.48.34,2.94,2.94,0,0,0,1.44.37,3,3,0,0,0,2.32-4.87A2.59,2.59,0,0,0,20.13,14.76Zm-1,3a1.17,1.17,0,0,1-.6.38.65.65,0,0,1-.31.05,1.18,1.18,0,0,1-1.16-1.38,1.1,1.1,0,0,1,.25-.56,1,1,0,0,1,.51-.35.92.92,0,0,1,.4-.08,1.21,1.21,0,0,1,.76.28,1.18,1.18,0,0,1,.41,1A1.15,1.15,0,0,1,19.12,17.8Z"
                      fill="#181818"
                    />
                    <path
                      d="M1.87,7.23a3,3,0,0,0,1.32.64,2.45,2.45,0,0,0,.6.06A3,3,0,0,0,6.08,6.86a2.93,2.93,0,0,0,.48-3,2.79,2.79,0,0,0-.85-1.19,2.62,2.62,0,0,0-.48-.34A2.94,2.94,0,0,0,3.79,2,3,3,0,0,0,1.47,6.81,2.27,2.27,0,0,0,1.87,7.23Zm1-3.05a1.12,1.12,0,0,1,.59-.37.86.86,0,0,1,.32-.05,1.12,1.12,0,0,1,.76.29,1.13,1.13,0,0,1,.42.8.87.87,0,0,1,0,.28,1,1,0,0,1-.26.57,1.12,1.12,0,0,1-.51.35.92.92,0,0,1-.4.08A1.2,1.2,0,0,1,3,5.86a1.17,1.17,0,0,1-.41-1A1.23,1.23,0,0,1,2.88,4.18Z"
                      fill="#181818"
                    />
                    <path
                      d="M3.79,14.06a3.05,3.05,0,0,0-.6.06,3.12,3.12,0,0,0-1.32.64,2.59,2.59,0,0,0-.4.41A3,3,0,0,0,3.79,20a2.94,2.94,0,0,0,1.44-.37,3.21,3.21,0,0,0,.48-.34,3,3,0,0,0-1.92-5.27ZM4.55,18a1.16,1.16,0,0,1-.76.28.65.65,0,0,1-.31-.05,1.17,1.17,0,0,1-.6-.38,1.15,1.15,0,0,1-.26-.63,1.18,1.18,0,0,1,.41-1,1.21,1.21,0,0,1,.76-.28.92.92,0,0,1,.4.08,1,1,0,0,1,.51.35,1.1,1.1,0,0,1,.25.56A1.16,1.16,0,0,1,4.55,18Z"
                      fill="#181818"
                    />
                    <path
                      d="M18.21,7.93a2.45,2.45,0,0,0,.6-.06,3,3,0,0,0,1.32-.64,2.27,2.27,0,0,0,.4-.42A3,3,0,0,0,18.21,2a2.94,2.94,0,0,0-1.44.37,2.62,2.62,0,0,0-.48.34,2.79,2.79,0,0,0-.85,1.19,3,3,0,0,0,.49,3A3,3,0,0,0,18.21,7.93ZM17,4.85a1.21,1.21,0,0,1,1.18-1.09.86.86,0,0,1,.32.05,1.12,1.12,0,0,1,.59.37,1.23,1.23,0,0,1,.26.64,1.17,1.17,0,0,1-.41,1,1.2,1.2,0,0,1-.76.27.92.92,0,0,1-.4-.08,1.12,1.12,0,0,1-.51-.35A1,1,0,0,1,17,5.13.87.87,0,0,1,17,4.85Z"
                      fill="#181818"
                    />
                    <rect width="22" height="22" fill="none" />
                  </g>
                </g>
              </svg>
</template>

<script>
export default {
  name: "CmsIcon",
};
</script>

<style>
</style>
