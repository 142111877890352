<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="20"
    viewBox="0 0 22 20"
  >
    <path
      id="warehouse"
      d="M21.944,7.675a.846.846,0,0,1-.368-.083L11.9,2.708,2.216,7.559a.866.866,0,0,1-1.147-.406.848.848,0,0,1,.41-1.134L11.9.92l10.417,5.1a.848.848,0,0,1,.653,1.01A.858.858,0,0,1,21.944,7.675ZM7.709,15.953H2.685V20.92H7.709Zm6.7,0H9.383V20.92h5.024Zm6.7,0H16.082V20.92h5.024ZM17.757,9.331H12.733V14.3h5.024Zm-6.7,0H6.034V14.3h5.024Z"
      transform="translate(-0.985 -0.92)"
      fill="#565656"
    />
  </svg>
</template>

<script>
export default {
  name: "MerchantIcon",
};
</script>

<style>
</style>
