<template>
  <div class="filter-select">
    <label>Category: </label>
    <v-select
      :items="categoryOptions"
      item-text="name"
      item-value="id"
      outlined
      attach
      clearable
      dense
      label="Filter by category"
      v-model="selectedCategoryOption"
      @change="$emit('changedCategoryOption', selectedCategoryOption)"
    >
    </v-select>
  </div>
</template>
<script>
// import moment from "moment";
export default {
  name: "CategoryFilter",
  props: ["newCategoryOption"],
  data() {
    return {
      categoryOptions: [],
      selectedCategoryOption: {
        category: this.newCategoryOption,
      },
    };
  },
  created() {
    const token = localStorage.getItem("access_token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    this.getNutritionCategories();
  },
  methods: {
    getNutritionCategories() {
      axios
        .get(`/admin/nutrition/category`)
        .then(({ data }) => {
          this.categoryOptions = data;
          // console.log(this.categoryOptions);
        })
        .catch((error) => console.log(error));
    },
  },
};
</script>
