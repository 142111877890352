<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9 0C4.032 0 0 4.032 0 9C0 13.968 4.032 18 9 18C13.968 18 18 13.968 18 9C18 4.032 13.968 0 9 0ZM9.00275 16.1996C5.03374 16.1996 1.80273 12.9686 1.80273 8.99958C1.80273 5.03059 5.03374 1.7996 9.00275 1.7996C12.9718 1.7996 16.2028 5.03059 16.2028 8.99958C16.2028 12.9686 12.9718 16.1996 9.00275 16.1996ZM8.10195 5.39959C8.10195 4.90459 8.50695 4.49959 9.00195 4.49959C9.49695 4.49959 9.90195 4.90459 9.90195 5.39959V8.09957H12.602C13.097 8.09957 13.502 8.50457 13.502 8.99957C13.502 9.49457 13.097 9.89956 12.602 9.89956H9.90195V12.5995C9.90195 13.0945 9.49695 13.4995 9.00195 13.4995C8.50695 13.4995 8.10195 13.0945 8.10195 12.5995V9.89956H5.40195C4.90695 9.89956 4.50195 9.49457 4.50195 8.99957C4.50195 8.50457 4.90695 8.09957 5.40195 8.09957H8.10195V5.39959Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "AddWithCircle",
};
</script>

<style>
</style>
