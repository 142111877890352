<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          d="M17.5,2.17H8A4,4,0,0,0,4,5.7a4,4,0,0,0-3.53,4v6.16a4,4,0,0,0,4,4H14a4,4,0,0,0,4-3.53,4,4,0,0,0,3.53-4V6.17A4,4,0,0,0,17.5,2.17ZM16.2,15.83a2.07,2.07,0,0,1-.06.5A2.21,2.21,0,0,1,14,18H4.5a2.2,2.2,0,0,1-2.2-2.2V9.67A2.2,2.2,0,0,1,4,7.53a2.07,2.07,0,0,1,.5-.06H14a2.21,2.21,0,0,1,2.2,2.2Zm3.5-3.5A2.21,2.21,0,0,1,18,14.47V9.67a4,4,0,0,0-4-4H5.86A2.19,2.19,0,0,1,8,4h9.5a2.21,2.21,0,0,1,2.2,2.2Z"
          fill="#181818"
        />
        <path
          d="M12.59,12.67a.79.79,0,0,1-.8.79H10v1.75a.54.54,0,0,1,0,.22.78.78,0,0,1-.75.58.79.79,0,0,1-.76-.58.54.54,0,0,1,0-.22V13.46H6.7a.79.79,0,0,1-.79-.79.79.79,0,0,1,.79-.8H8.45V10.12a.79.79,0,0,1,.8-.79.79.79,0,0,1,.79.79v1.75h1.75A.8.8,0,0,1,12.59,12.67Z"
          fill="#181818"
        />
        <rect width="22" height="22" fill="none" />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "NewSectionIcon",
};
</script>

<style>
</style>
