<template>
  <nav class="table-footer" aria-label="pagination ">
    <div class="table-footer-wrap">
      <aside class="widget- widget-total">
        <span> {{ totalItems }} </span>
      </aside>
      <ul class="pagination">
        <!--First-->
        <li class="page-item" :class="isInFirstPage? 'disabled':''">
          <button
            type="button"
            class="page-link"
            @click="onClickFirstPage"
            :disabled="isInFirstPage"
          >
            <DoubleArrowLeft></DoubleArrowLeft>
          </button>
        </li>
        <!--Arrow left-->
        <li class="page-item"  :class="isInFirstPage? 'disabled':''" >
          <button
            class="page-link"
            aria-label="Previous"
            type="button"
            @click="onClickPreviousPage"
            :disabled="isInFirstPage"
          >
            <span aria-hidden="true">
              <arrow-left></arrow-left>
            </span>
          </button>
        </li>
        <!-- Visible Buttons Start -->

    <!-- <li
      v-for="page in pages"
      class="pagination-item"
    >
      <button
        type="button"
        @click="onClickPage(page.name)"
        :disabled="page.isDisabled"
        :class="{ active: isPageActive(page.name) }"
      >
        {{ page.name }}
      </button>
    </li> -->

    <!-- Visible Buttons End -->
        <span class="pagination-showing"
          ><span class="primary-color">{{ currentPage }}</span> of
          {{ totalPages }}</span
        >
        <li class="page-item" :class="isInLastPage?'disabled':''">
          <button
            class="page-link"
            aria-label="Next"
            type="button"
            @click="onClickNextPage"
            :disabled="isInLastPage"
          >
            <span aria-hidden="true">
              <arrow-right></arrow-right>
            </span>
          </button>
        </li>
        <!--Last-->
        <li class="page-item" :class="isInLastPage?'disabled':''">
          <button
            class="page-link"
            type="button"
            @click="onClickLastPage"
            :disabled="isInLastPage"
          >
            <double-arrow-right></double-arrow-right
          ></button>
        </li>
      </ul>
    </div>
  </nav>
</template>
<script>
import ArrowLeft from "../icons/ArrowLeft.vue";
import ArrowRight from "../icons/ArrowRight.vue";
import DoubleArrowLeft from "../icons/doubleArrowLeft.vue";
import DoubleArrowRight from "../icons/doubleArrowRight.vue";

export default {
  name: "",
  components: {
    DoubleArrowLeft,
    DoubleArrowRight,
    ArrowRight,
    ArrowLeft,
  },
  props: {
    totalItems: {
      type: String,
      required: true,
    },
    maxVisibleButtons: {
      type: Number,
      required: false,
      default: 3,
    },
    totalPages: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
  },
  computed: {
    startPage() {
      // When on the first page
      if (this.currentPage === 1) {
        return 1;
      }

      // When on the last page
      if (this.currentPage === this.totalPages) {
        return this.totalPages - this.maxVisibleButtons;
      }

      // When inbetween
      return this.currentPage - 1;
    },
    pages() {
      const range = [];

      for (
        let i = this.startPage;
        i <=
        Math.min(this.startPage + this.maxVisibleButtons - 1, this.totalPages);
        i++
      ) {
        range.push({
          name: i,
          isDisabled: i === this.currentPage,
        });
      }

      return range;
    },
    isInFirstPage() {
      return this.currentPage === 1;
    },
    isInLastPage() {
      return this.currentPage === this.totalPages;
    },
  },
  methods: {
    onClickFirstPage() {
      this.$emit("pagechanged", 1);
    },
    onClickPreviousPage() {
      this.$emit("pagechanged", this.currentPage - 1);
    },
    onClickPage(page) {
      this.$emit("pagechanged", page);
    },
    onClickNextPage() {
      this.$emit("pagechanged", this.currentPage + 1);
    },
    onClickLastPage() {
      this.$emit("pagechanged", this.totalPages);
    },
    isPageActive(page) {
      return this.currentPage === page;
    },
  },
};
</script>
