<template>
  <main role="main" class="bd-content">
    <div class="main-content">
      <div class="section section-nutrition">
        <div class="panel">
          <div class="panel-body">
            <v-app class="mt-12 ml-12">
              <v-data-table
                class="elevation-1"
                :headers="headers"
                :items="merchantTags"
                :loading="loading"
                loading-text="Loading... Please wait"
                hide-default-footer
                :items-per-page="-1"
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <div class="toolbar-left">
                      <v-card-title>
                        <v-toolbar-title class="title-with-search">
                          <div class="title-wrap">
                            <h3 class="panel-title">Merchant Tags</h3>
                          </div>
                        </v-toolbar-title>
                      </v-card-title>
                    </div>
                    <div class="toolbar-right">
                      <v-dialog v-model="dialog" max-width="500px">
                        <template v-slot:activator="{ on, attrs }">
                          <button
                            class="btn btn-primary btn-add"
                            v-bind="attrs"
                            v-on="on"
                          >
                            New Item
                          </button>
                        </template>
                        <v-card class="modalTagAdd">
                          <div class="modal-head">
                            <span class="text-h5">{{ formTitle }}</span>
                          </div>

                          <div class="modal-body">
                            <div class="row">
                              <div class="col-12">
                                <div class="form-wrap">
                                  <label for="vaccine">Tag Name-EN</label>
                                  <input
                                    type="text"
                                    v-model="editedItem.name_en"
                                    @input="errors.name_en = null"
                                    @click="errors.name_en = null"
                                  />
                                  <small
                                    class="text-danger"
                                    v-if="errors.name_en"
                                  >
                                    {{ errors.name_en[0] }}
                                  </small>
                                </div>
                              </div>
                              <div class="col-12">
                                <div class="form-wrap last">
                                  <label for="vaccine">Tag Name-ZH</label>
                                  <input
                                    type="text"
                                    v-model="editedItem.name_zh"
                                    @input="errors.name_zh = null"
                                    @click="errors.name_zh = null"
                                  />
                                  <small
                                    class="text-danger"
                                    v-if="errors.name_zh"
                                  >
                                    {{ errors.name_zh[0] }}
                                  </small>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="modal-footer">
                            <div class="actions">
                              <button
                                class="btn btn-secondary"
                                text
                                @click="close"
                              >
                                Cancel
                              </button>
                              <button
                                class="btn btn-primary"
                                text
                                @click="save"
                                :disabled="loading"
                              >
                                Save
                                <span
                                  class="loading-dots"
                                  v-if="loading"
                                ></span>
                              </button>
                            </div>
                          </div>
                        </v-card>
                      </v-dialog>
                    </div>
                    <!-- modal for Delete -->
                    <v-dialog v-model="dialogDelete" max-width="460px">
                      <v-card class="modal-actionDelete">
                        <div class="modal-head">
                          <v-card-title class="card-title">Delete</v-card-title>
                        </div>
                        <v-card-subtitle>
                          Are you sure want to delete?
                        </v-card-subtitle>
                        <v-card-actions>
                          <v-btn
                            class="btn btn-secondary"
                            text
                            @click="closeDelete"
                            >Cancel</v-btn
                          >
                          <v-btn
                            class="btn btn-primary btn-sucess"
                            text
                            @click="deleteItemConfirm"
                            >Yes, delete</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-toolbar>
                </template>

                <template v-slot:item.sn="{ index }">
                  {{ index + 1 }}
                </template>
                <template v-slot:item.all_translations[0]="{ item }">
                  <span v-if="item.all_translations[0]">
                    {{ item.all_translations[0].name }}
                  </span>
                  <!-- <div v-for="trans in item.all_translations" :key="trans.id">
                    <span v-if="trans.lang_code == 'en'">
                      {{ trans.name }}
                    </span>
                    <span v-if="trans.lang_code == 'zh'">
                      {{ trans.name }}
                    </span>
                  </div> -->
                </template>
                <template v-slot:item.all_translations[1]="{ item }">
                  <span v-if="item.all_translations[1]">
                    {{ item.all_translations[1].name }}
                  </span>
                  <!-- <div v-for="trans in item.all_translations" :key="trans.id">
                    <span v-if="trans.lang_code == 'en'">
                      {{ trans.name }}
                    </span>
                    <span v-if="trans.lang_code == 'zh'">
                      {{ trans.name }}
                    </span>
                  </div> -->
                </template>
                <template v-slot:item.actions="{ item }">
                  <div class="action">
                    <a @click="editItem(item)" class="btn btn-view-text"
                      >Edit</a
                    >
                    <button
                      class="btn btn-delete-text"
                      @click="deleteItem(item)"
                    >
                      Delete
                      <div class="text-animation"></div>
                    </button>
                  </div>
                </template>
              </v-data-table>

              <Pagination
                :totalItems="pagination.totalItems + ' Merchant Tag'"
                :totalPages="pagination.totalPages"
                :perPage="pagination.perPage"
                :currentPage="pagination.currentPage"
                @pagechanged="getMerchantTags"
              ></Pagination>
            </v-app>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import Pagination from "../../subComponents/pagination.vue";

export default {
  name: "merchantTag",
  components: {
    Pagination,
  },
  data() {
    return {
      pagination: {
        totalItems: 0,
        totalPages: 1,
        perPage: 0,
        currentPage: 1,
      },
      paginationOf: "merchantTag",
      dialog: false,
      dialogDelete: false,
      loading: false,
      headers: [
        {
          text: "S.N.",
          align: "left",
          sortable: false,
          value: "sn",
        },
        {
          text: "Name EN",
          align: "left",
          sortable: true,
          value: "all_translations[0]",
        },
        {
          text: "Name ZH",
          align: "left",
          sortable: false,
          value: "all_translations[1]",
        },
        { text: "Actions", value: "actions", sortable: false },
      ],
      merchantTags: [],
      editedIndex: -1,
      editedItem: {
        id: "",
        name_en: "",
        name_zh: "",
      },
      defaultItem: {
        id: "",
        name_en: "",
        name_zh: "",
      },
      errors: {},
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Merchant Tag" : "Edit Merchant Tag";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    const token = localStorage.getItem("access_token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    this.getMerchantTags(this.pagination.currentPage);
  },

  methods: {
    getMerchantTags(page) {
      this.loading = true;
      this.pagination.currentPage = page;
      axios
        .get(`/admin/merchant/tag?page=${this.pagination.currentPage}`)
        .then(({ data }) => {
          this.paginationOf = "merchantTag";
          this.pagination.totalItems = data.total;
          this.pagination.totalPages = data.last_page;
          this.pagination.perPage = data.per_page;
          this.pagination.currentPage = data.current_page;
          this.merchantTags = data.data;
          this.loading = false;
        })
        .catch((error) => console.log(error));
    },
    editItem(item) {
      let editItem = {
        id: item.id,
        name_en:
          item.all_translations[0] == null ? "" : item.all_translations[0].name,
        name_zh:
          item.all_translations[1] == null ? "" : item.all_translations[1].name,
      };
      this.editedIndex = this.merchantTags.indexOf(item);
      this.editedItem = Object.assign({}, editItem);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.merchantTags.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      axios
        .delete(`/admin/merchant/tag/${this.editedItem.id}`)
        .then(this.merchantTags.splice(this.editedIndex, 1), Toast.success())
        .catch((errors) => Toast.error());
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.errors = {};
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        axios
          .put(`/admin/merchant/tag/${this.editedItem.id}`, this.editedItem)
          .then(({ data }) => {
            this.close();
            this.getMerchantTags();
            Toast.success();
          })
          .catch((error) => {
            this.errors = error.response.data.errors;
          });
      } else {
        console.log(this.editedItem);
        axios
          .post(`/admin/merchant/tag`, this.editedItem)
          .then(({ data }) => {
            this.close();
            this.getMerchantTags();
            Toast.success();
          })
          .catch((error) => {
            this.errors = error.response.data.errors;
          });
      }
    },
  },
};
</script>
