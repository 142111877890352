<template>
  <main role="main" class="bd-content">
    <div class="main-content">
      <div class="section section-pets">
        <div class="panel">
          <div class="panel-body">
            <v-app class="mt-12 ml-12">
              <v-data-table
                class="elevation-1"
                :headers="headers"
                :items="pets"
                :loading="loading"
                loading-text="Loading... Please wait"
                hide-default-footer
                :items-per-page="-1"
                rowKey
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <div class="toolbar-left">
                      <v-card-title>
                        <v-toolbar-title class="panel-with">
                          <h3 class="panel-title">Pets</h3></v-toolbar-title
                        >
                        <div class="search-toolbar-area">
                          <v-text-field
                            v-model="search"
                            single-line
                            hide-details
                            placeholder="Search a name"
                          ></v-text-field>
                        </div>
                      </v-card-title>
                    </div>
                    <div class="toolbar-right">
                      <FilterByDate
                        :newFilterOption="newFilterOption"
                        @changedFilterOption="getChangedFilterOption"
                      ></FilterByDate>
                    </div>
                  </v-toolbar>

                  <!-- template form modal -->
                  <!-- <v-dialog v-model="dialog" max-width="700px">
                      <v-card>
                        <v-card-title>
                          <h3 class="card-title">{{ formTitle }}</h3>
                        </v-card-title>
                        <v-card-text>
                          <v-row class="edit-onbaord">
                            <v-col class="image-area" md="5">
                              <figure>
                                <label>Image</label>
                                <div
                                  class="drop display-inline"
                                  id="img-upload"
                                  @dragover.prevent
                                  @drop="onDrop"
                                >
                                  <label
                                    class="trigger-element"
                                    :class="!editedItem.image ? '' : 'hidden'"
                                  >
                                    SELECT OR DROP AN IMAGE
                                    <input
                                      type="file"
                                      name="image"
                                      @change="onChange"
                                    />
                                  </label>
                                  <div
                                    class="hidden display-inline align-center"
                                    v-bind:class="{ image: true }"
                                  >
                                    <div class="img-wrap">
                                      <img
                                        :src="editedItem.image"
                                        alt="Broken Image"
                                        class="img"
                                      />
                                    </div>
                                    <button
                                      class="btn-remove"
                                      @click="uploadFile"
                                    >
                                      Update image
                                    </button>
                                  </div>
                                </div>
                              </figure>
                            </v-col>
                            <v-col class="field-area" cols="12" sm="6" md="7">
                              <v-text-field
                                v-model="editedItem.title"
                                label="Title"
                              ></v-text-field>
                              <v-textarea
                                v-model="editedItem.content"
                                label="Description"
                              ></v-textarea>

                              <v-text-field
                                label="Position"
                                type="number"
                                step="1"
                                min="0"
                                ref="input"
                                v-model.number="editedItem.order"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer></v-spacer>

                          <v-btn
                            class="btn btn-primary btn-save"
                            color=""
                            text
                            @click="save"
                          >
                            Save
                          </v-btn>
                          <v-btn
                            class="btn btn-secondary btn-cancel"
                            color=""
                            text
                            @click="close"
                          >
                            Cancel
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog> -->

                  <!-- modal for Delete -->
                  <v-dialog v-model="dialogDelete" max-width="460px">
                    <v-card class="modal-actionDelete">
                      <div class="modal-head">
                        <v-card-title class="card-title">Delete</v-card-title>
                      </div>
                      <v-card-subtitle>
                        Are you sure want to delete?
                      </v-card-subtitle>
                      <v-card-actions>
                        <v-btn
                          class="btn btn-secondary"
                          text
                          @click="closeDelete"
                          >Cancel</v-btn
                        >
                        <v-btn
                          class="btn btn-primary btn-sucess"
                          text
                          @click="deleteItemConfirm"
                          >Yes, delete</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </template>
                <template v-slot:item.sn="{ index }">
                  {{ index + 1 }}
                </template>
                <template v-slot:item.image_web="{ item }">
                  <div class="thumb-wrap">
                    <img
                      :src="
                        item.image_web !== null
                          ? item.image_web
                          : '/images/pet-deafult.png'
                      "
                      class="border-img"
                    />
                  </div>
                </template>

                <template v-slot:item.created_at="{ item }">
                  <div class="date">
                    {{ item.created_at | formatDate }}
                  </div>
                </template>
                <template v-slot:item.breed="{ item }">
                  <div class="breed" v-if="item && item.breed">
                    {{ item.breed.name }}
                  </div>
                  <div class="breed" v-if="item && item.breed == null">
                    Beautiful Mix
                  </div>
                </template>
                <template v-slot:item.pet_type="{ item }">
                  <div class="pet_type">
                    {{ petType(item.pet_type) }}
                  </div>
                </template>
                <template v-slot:item.weight="{ item }">
                  <div class="weight">
                    {{ Number(item.weight).toFixed(1) }} {{ item.metric }}
                  </div>
                </template>
                <template v-slot:item.status="{ item }">
                  <v-chip class="status" :color="getColor(item.status)" dark>
                    {{ item.status }}
                  </v-chip>
                </template>
                <template v-slot:item.contact="{ item }">
                  <span>
                    {{ item.contact }}
                    <br />
                    <a :href="'mailto:' + item.email" class="link-email">
                      {{ item.email }}</a
                    >
                  </span>
                </template>
                <template v-slot:item.associatedPets="{ item }">
                  <a
                    href="javascript:void(0);"
                    class="link"
                    @click="petDetail(pets)"
                  >
                    {{ item.associatedPets }}
                  </a>
                </template>

                <template v-slot:item.actions="{ item }">
                  <div class="action">
                    <!-- <button class="btn btn-edit" @click="editItem(item)">
                      <div class="text-animation"></div>
                    </button> -->

                    <button
                      class="btn btn-delete-text"
                      @click="deleteItem(item)"
                    >
                      Delete
                    </button>

                    <router-link
                      class="btn btn-view-text"
                      :to="/pet/ + item.id"
                      value="{items}"
                      :dusk="/pet_view/ + item.id"
                      >View</router-link
                    >
                  </div>
                </template>
                <v-dialog v-model="showPets" max-width="360px">
                  <v-card class="show-pet">
                    <v-card-title>
                      <span class="user-title">Jeanni</span>
                    </v-card-title>

                    <v-card-text>
                      <ul class="pet-lists">
                        <li class="pet-list" v-for="(pet, i) in pets" :key="i">
                          <div class="list-wrap">
                            <figure class="thumb-wrap">
                              <img :src="pet.image" alt="pet image" />
                            </figure>
                            <div class="content-wrap">
                              <div class="text-wrap">
                                <h4 class="pet-name">
                                  {{ pet.name }}
                                </h4>
                                <label class="pet-breed">
                                  {{ pet.breed }}
                                </label>
                              </div>
                              <div class="action">
                                <router-link
                                  class="link"
                                  :to="{ name: 'pets-details' }"
                                  >View more <span class="arrow-icon"></span
                                ></router-link>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </v-card-text>
                  </v-card>
                </v-dialog>
              </v-data-table>
              <Pagination
                :totalItems="pagination.totalItems + ' Pets'"
                :totalPages="pagination.totalPages"
                :perPage="pagination.perPage"
                :currentPage="pagination.currentPage"
                @pagechanged="getPets"
              ></Pagination>
            </v-app>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import FilterByDate from "../subComponents/filterByDate.vue";
import Pagination from "../subComponents/pagination.vue";
export default {
  name: "pets",
  components: {
    FilterByDate,
    Pagination,
  },
  data() {
    return {
      pagination: {
        totalItems: 0,
        totalPages: 1,
        perPage: 0,
        currentPage: 1,
      },
      paginationOf: "pet",
      pets: [],
      newFilterOption: null,
      loading: false,
      dialog: false,
      showPets: false,
      dialogDelete: false,
      headers: [
        {
          text: "S.N.",
          align: "left",
          sortable: false,
          value: "sn",
          width: "30px",
        },

        {
          text: "Image",
          value: "image_web",
          sortable: false,
          align: "left",
        },
        {
          text: "Name",
          value: "name",
          sortable: true,
          align: "left",
          width: "110px",
        },
        {
          text: "pet type",
          value: "pet_type",
          sortable: true,
          align: "left",
          width: "130px",
        },
        {
          text: "Registration Date",
          value: "created_at",
          sortable: true,
          align: "left",
          width: "190px",
        },

        {
          text: "Breed",
          value: "breed",
          sortable: false,
          align: "left",
        },

        {
          text: "primary Guardian",
          value: "user.name",
          sortable: false,
          align: "left",
          width: "170px",
        },
        {
          text: "Pet Weight",
          value: "weight",
          sortable: false,
          align: "left",
          width: "150px",
        },
        {
          text: "Blood Donor",
          value: "blood_donor",
          sortable: false,
          align: "start",
          width: "140px",
        },

        {
          text: "Active Blood Request",
          value: "blood_request",
          sortable: false,
          align: "start",
          width: "200px",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "start",
          width: "150px",
        },
      ],
      editedIndex: -1,
      editedItem: {
        img_name: "",
        name: "",
        pet_type: "",
        breed: "",
        pet_weight: "",
        blood_donor: "",
      },
      defaultItem: {
        img_name: "",
        name: "",
        pet_type: "",
        breed: "",
        pet_weight: "",
        blood_donor: "",
      },
      search: null,
      awaitingSearch: false,
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Pet" : "Edit Pet";
    },
  },
  created() {
    const token = localStorage.getItem("access_token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    this.getPets(this.pagination.currentPage);
  },
  watch: {
    search: function (val) {
      if (this.search == null || this.search == "") {
        this.awaitingSearch = false;
        console.log(this.pagination.currentPage);
        this.getPets(this.pagination.currentPage);
        return false;
      } else {
        if (!this.awaitingSearch) {
          setTimeout(() => {
            if (this.search === null || this.search === "") {
              return;
            }
            this.loading = true;
            this.newFilterOption = null;
            //  this.pagination.currentPage = page;
            axios
              .get("/admin/pet/search?page=" + this.pagination.currentPage, {
                params: { search: this.search },
              })
              .then(({ data }) => {
                this.paginationOf = "search";
                this.pagination.totalItems = data.total;
                this.pagination.totalPages = data.last_page;
                this.pagination.perPage = data.per_page;
                this.pagination.currentPage = data.current_page;
                this.pets = data.data;
                this.loading = false;
              })
              .catch((errors) => console.log(errors));
            this.awaitingSearch = false;
          }, 2000); // 2 sec delay
        }
        this.awaitingSearch = true;
      }
    },
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  methods: {
    petDetail(item) {
      //   this.editedIndex = this.users.indexOf(item);
      this.pets = Object.assign({}, item);
      this.showPets = true;
      console.log(this.pets);
    },
    getPets(page) {
      this.loading = true;
      this.pagination.currentPage = page;
      if (this.paginationOf == "search") {
        this.newFilterOption = null;
        axios
          .get(`/admin/pet/search?page=${this.pagination.currentPage}`, {
            params: { search: this.search },
          })
          .then(({ data }) => {
            this.paginationOf = "search";
            this.pagination.totalItems = data.total;
            this.pagination.totalPages = data.last_page;
            this.pagination.perPage = data.per_page;
            this.pagination.currentPage = data.current_page;
            this.pets = data.data;
            this.loading = false;
          })
          .catch((errors) => console.log(errors));
      } else if (this.paginationOf == "filterby") {
        this.search = null;
        axios
          .get(
            `/admin/pet/filterby/${this.newFilterOption}?page=${this.pagination.currentPage}`
          )
          .then(({ data }) => {
            // console.log(data);
            this.paginationOf = "filterby";
            this.pagination.totalItems = data.total;
            this.pagination.totalPages = data.last_page;
            this.pagination.perPage = data.per_page;
            this.pagination.currentPage = data.current_page;
            this.pets = data.data;
            this.loading = false;
          })
          .catch((errors) => console.log(errors));
      } else {
        axios
          .get(`/admin/pet?page=${this.pagination.currentPage}`)
          .then(({ data }) => {
            this.paginationOf = "pet";
            this.pagination.totalItems = data.total;
            this.pagination.totalPages = data.last_page;
            this.pagination.perPage = data.per_page;
            this.pagination.currentPage = data.current_page;
            this.pets = data.data;
            this.loading = false;
            //  console.log( this.pets);
          })
          .catch((errors) => console.log(errors));
      }
    },
    getChangedFilterOption(option) {
      this.newFilterOption = option;
      this.loading = true;
      this.search = null;
      axios
        .get(
          `/admin/pet/filterby/${this.newFilterOption}?page=${this.pagination.currentPage}`
        )
        .then(({ data }) => {
          this.paginationOf = "filterby";
          this.pagination.totalItems = data.total;
          this.pagination.totalPages = data.last_page;
          this.pagination.perPage = data.per_page;
          this.pagination.currentPage = data.current_page;
          this.pets = data.data;
          // console.log(data);
          this.loading = false;
        })
        .catch((errors) => console.log(errors));
    },
    deleteItem(item) {
      this.editedIndex = this.pets.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.users[this.editedIndex], this.editedItem);
      } else {
        this.users.push(this.editedItem);
      }
      this.close();
    },
    deleteItemConfirm() {
      axios
        .delete("/admin/pet/" + this.editedItem.id)
        .then(this.pets.splice(this.editedIndex, 1), Toast.success())
        .catch((errors) => Toast.error());
      this.closeDelete();
    },
    getColor(statusGet) {
      //   console.log(statusGet);
      if (statusGet == "active") return "v-chip--active";
      else if (statusGet == "inactive") return "v-chip--inactive";
      else return "hidden";
    },
    onDrop: function (e) {
      e.stopPropagation();
      e.preventDefault();
      var files = e.dataTransfer.files;
      this.createFile(files[0]);
    },
    onChange(e) {
      var files = e.target.files;
      this.createFile(files[0]);
    },
    createFile(file) {
      if (!file.type.match("image.*")) {
        alert("Select an image");
        return;
      }
      var img = new Image();
      var reader = new FileReader();
      var vm = this;

      reader.onload = function (e) {
        vm.editedItem.image = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    uploadFile() {
      //   this.editedItem.image = "";
      setTimeout(() => {
        $('#img-upload input[name="image"]').trigger("click");
      }, 10);
    },
    petType(type) {
      if (type == 0) {
        return "Dog";
      } else if (type == 1) {
        return "Cat";
      } else {
        return;
      }
    },
  },
};
</script>

<style>
</style>

