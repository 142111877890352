<template>
  <main role="main" class="bd-content">
    <div class="main-content">
      <div class="section section-vaccines">
        <div class="panel">
          <div class="panel-body">
            <v-app class="mt-12 ml-12">
              <v-data-table
                class="elevation-1"
                :headers="headers"
                :items="vaccines"
                :loading="loading"
                loading-text="Loading... Please wait"
                hide-default-footer
                :items-per-page="-1"
                rowKey
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <div class="toolbar-left">
                      <v-card-title>
                        <v-toolbar-title class="panel-with">
                          <h3 class="panel-title">Vaccines</h3></v-toolbar-title
                        >
                        <div class="search-toolbar-area">
                          <v-text-field
                            v-model="search"
                            single-line
                            hide-details
                            placeholder="Search a name"
                          ></v-text-field>
                        </div>
                      </v-card-title>
                    </div>
                    <div class="toolbar-right">
                      <FilterByDate
                        :newFilterOption="newFilterOption"
                        @changedFilterOption="getChangedFilterOption"
                      ></FilterByDate>
                    </div>
                  </v-toolbar>
                </template>
                <template v-slot:item.sn="{ index }">
                  {{ index + 1 }}
                </template>
                <template v-slot:item.image_web="{ item }">
                  <div class="thumb-wrap">
                    <img
                      :src="
                        item.pet.image_web !== null
                          ? item.pet.image_web
                          : '/images/pet-deafult.png'
                      "
                      class="border-img"
                    />
                  </div>
                </template>
                <template v-slot:item.name="{ item }">
                  <div class="thumb-wrap">
                    {{ item.pet.name }}
                  </div>
                </template>
                <template v-slot:item.pet_type="{ item }">
                  <div class="pet_type">
                    {{ petType(item.pet.pet_type) }}
                  </div>
                </template>
                <template v-slot:item.created_at="{ item }">
                  <div class="date">
                    {{ item.pet.created_at | formatDate }}
                  </div>
                </template>
                <template v-slot:item.breed="{ item }">
                  <div class="breed" v-if="item && item.pet.breed">
                    {{ item.pet.breed.name }}
                  </div>
                  <div class="breed" v-if="item && item.breed == null">
                    Beautiful Mix
                  </div>
                </template>
                <template v-slot:item.weight="{ item }">
                  <div class="weight">
                    {{ Number(item.pet.weight).toFixed(1) }}
                    {{ item.pet.metric }}
                  </div>
                </template>

                <template v-slot:item.guardian="{ item }">
                  <div class="guardian" v-if="item && item.pet.user">
                    {{ item.pet.user.name }}
                  </div>
                </template>

                <template v-slot:item.actions="{ item }">
                  <div class="action">
                    <router-link
                      class="btn btn-view-text"
                      :to="/pet/ + item.pet_id"
                      value="{items}"
                      >View</router-link
                    >
                  </div>
                </template>
              </v-data-table>
              <Pagination
                :totalItems="pagination.totalItems + ' Vaccines'"
                :totalPages="pagination.totalPages"
                :perPage="pagination.perPage"
                :currentPage="pagination.currentPage"
                @pagechanged="getVaccines"
              ></Pagination>
            </v-app>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import FilterByDate from "../subComponents/filterByDate.vue";
import Pagination from "../subComponents/pagination.vue";
export default {
  name: "vaccines",
  components: {
    FilterByDate,
    Pagination,
  },
  data() {
    return {
      pagination: {
        totalItems: 0,
        totalPages: 1,
        perPage: 0,
        currentPage: 1,
      },
      paginationOf: "vaccine",
      vaccines: [],
      newFilterOption: null,
      loading: false,
      dialog: false,
      showVaccines: false,
      dialogDelete: false,
      headers: [
        {
          text: "S.N.",
          align: "left",
          sortable: false,
          value: "sn",
          width: "30px",
        },

        {
          text: "Image",
          value: "image_web",
          sortable: false,
          align: "left",
        },
        {
          text: "Name",
          value: "name",
          sortable: true,
          align: "left",

          width: "110px",
        },
        {
          text: "pet type",
          value: "pet_type",
          sortable: true,
          align: "left",

          width: "130px",
        },
        {
          text: "Registration Date",
          value: "created_at",
          sortable: true,
          align: "left",

          width: "190px",
        },

        {
          text: "Breed",
          value: "breed",
          sortable: false,
          align: "left",
        },

        {
          text: "primary Guardian",
          value: "guardian",
          sortable: false,
          align: "left",

          width: "170px",
        },
        {
          text: "Pet Weight",
          value: "weight",
          sortable: false,
          align: "left",
          width: "150px",
        },
        {
          text: "Blood Donor",
          value: "blood_donor",
          sortable: false,
          align: "start",

          width: "160px",
        },

        {
          text: "Active Blood Request",
          value: "blood_request",
          sortable: false,
          align: "start",

         width: "200px",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "start",
          width: "100px",
        },
      ],
      editedIndex: -1,
      editedItem: {
        img_name: "",
        name: "",
        pet_type: "",
        breed: "",
        pet_weight: "",
        guardian: "",
        blood_donor: "",
      },
      defaultItem: {
        img_name: "",
        name: "",
        pet_type: "",
        breed: "",
        pet_weight: "",
        guardian: "",
        blood_donor: "",
      },
      search: null,
      awaitingSearch: false,
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Pet" : "Edit Pet";
    },
  },
  created() {
    const token = localStorage.getItem("access_token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    this.getVaccines(this.pagination.currentPage);
  },
  watch: {
    search: function (val) {
      if (this.search == null || this.search == "") {
        this.awaitingSearch = false;
        //console.log(this.pagination.currentPage);
        this.getVaccines(this.pagination.currentPage);
        return false;
      } else {
        if (!this.awaitingSearch) {
          setTimeout(() => {
            if (this.search === null || this.search === "") {
              return;
            }
            this.loading = true;
            this.newFilterOption = null;
            //  this.pagination.currentPage = page;
            axios
              .get(
                "/admin/vaccineupload/search?page=" +
                  this.pagination.currentPage,
                {
                  params: { search: this.search },
                }
              )
              .then(({ data }) => {
                this.paginationOf = "search";
                this.pagination.totalItems = data.total;
                this.pagination.totalPages = data.last_page;
                this.pagination.perPage = data.per_page;
                this.pagination.currentPage = data.current_page;
                this.vaccines = data.data;
                this.loading = false;
              })
              .catch((errors) => console.log(errors));
            this.awaitingSearch = false;
          }, 2000); // 2 sec delay
        }
        this.awaitingSearch = true;
      }
    },
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  methods: {
    // petDetail(item) {
    //   //   this.editedIndex = this.users.indexOf(item);
    //   this.vaccines = Object.assign({}, item);
    //   this.showVaccines = true;
    //   //console.log(this.vaccines);
    // },
    getVaccines(page) {
      this.loading = true;
      this.pagination.currentPage = page;
      if (this.paginationOf == "search") {
        this.newFilterOption = null;
        axios
          .get(
            `/admin/vaccineupload/search?page=${this.pagination.currentPage}`,
            {
              params: { search: this.search },
            }
          )
          .then(({ data }) => {
            this.paginationOf = "search";
            this.pagination.totalItems = data.total;
            this.pagination.totalPages = data.last_page;
            this.pagination.perPage = data.per_page;
            this.pagination.currentPage = data.current_page;
            this.vaccines = data.data;
            this.loading = false;
          })
          .catch((errors) => console.log(errors));
      } else if (this.paginationOf == "filterby") {
        this.search = null;
        axios
          .get(
            `/admin/vaccineupload/filterby/${this.newFilterOption}?page=${this.pagination.currentPage}`
          )
          .then(({ data }) => {
            // console.log(data);
            this.paginationOf = "filterby";
            this.pagination.totalItems = data.total;
            this.pagination.totalPages = data.last_page;
            this.pagination.perPage = data.per_page;
            this.pagination.currentPage = data.current_page;
            this.vaccines = data.data;
            this.loading = false;
          })
          .catch((errors) => console.log(errors));
      } else {
        axios
          .get(`/admin/vaccineupload?page=${this.pagination.currentPage}`)
          .then(({ data }) => {
            // console.log(data);
            this.paginationOf = "vaccine";
            this.pagination.totalItems = data.total;
            this.pagination.totalPages = data.last_page;
            this.pagination.perPage = data.per_page;
            this.pagination.currentPage = data.current_page;
            this.vaccines = data.data;
            this.loading = false;
          })
          .catch((errors) => console.log(errors));
      }
    },
    getChangedFilterOption(option) {
      this.newFilterOption = option;
      this.loading = true;
      this.search = null;
      axios
        .get(
          `/admin/vaccineupload/filterby/${this.newFilterOption}?page=${this.pagination.currentPage}`
        )
        .then(({ data }) => {
          this.paginationOf = "filterby";
          this.pagination.totalItems = data.total;
          this.pagination.totalPages = data.last_page;
          this.pagination.perPage = data.per_page;
          this.pagination.currentPage = data.current_page;
          this.vaccines = data.data;
          // console.log(data);
          this.loading = false;
        })
        .catch((errors) => console.log(errors));
    },
    petType(type) {
      if (type == 0) {
        return "Dog";
      } else if (type == 1) {
        return "Cat";
      } else {
        return;
      }
    },
  },
};
</script>

<style scoped>
.v-application tbody .action .btn-view-text{

color: #E77386;
}
</style>


