<template>
  <div class="filter-select">
    <label>Filter by: </label>
    <v-select
      :items="filterOptions"
      item-text="name"
      item-value="date"
      outlined
      v-model="selectedFilterOption"
      @change="$emit('changedFilterOption', selectedFilterOption)"
    >
    </v-select>
  </div>
</template>
<script>
import moment from "moment";
export default {
  name: "FilterByDate",
  props: ["newFilterOption"],
  data() {
    return {
      filterOptions: [
        {
          name: "All Date",
          date: null,
        },
        {
          name: "Yesterday",
          date:
            moment()
              .subtract(1, "day")
              .startOf("day")
              .format("YYYY-MM-DD HH:mm:ss") +
            "|" +
            moment()
              .subtract(1, "day")
              .endOf("day")
              .format("YYYY-MM-DD HH:mm:ss"),
        },
        {
          name: "Last 7 days",
          date:
            moment()
              .subtract(7, "day")
              .startOf("day")
              .format("YYYY-MM-DD HH:mm:ss") +
            "|" +
            moment().format("YYYY-MM-DD HH:mm:ss"),
        },
        {
          name: "Last 30 days",
          date:
            moment()
              .subtract(30, "day")
              .startOf("day")
              .format("YYYY-MM-DD HH:mm:ss") +
            "|" +
            moment().format("YYYY-MM-DD HH:mm:ss"),
        },
        {
          name: "Last 90 days",
          date:
            moment()
              .subtract(90, "day")
              .startOf("day")
              .format("YYYY-MM-DD HH:mm:ss") +
            "|" +
            moment().format("YYYY-MM-DD HH:mm:ss"),
        },
        {
          name: "Last month",
          date:
            moment()
              .subtract(1, "months")
              .startOf("month")
              .format("YYYY-MM-DD HH:mm:ss") +
            "|" +
            moment()
              .subtract(1, "months")
              .endOf("month")
              .format("YYYY-MM-DD HH:mm:ss"),
        },
        {
          name: "Last year",
          date:
            moment()
              .subtract(1, "year")
              .startOf("year")
              .format("YYYY-MM-DD HH:mm:ss") +
            "|" +
            moment()
              .subtract(1, "year")
              .endOf("year")
              .format("YYYY-MM-DD HH:mm:ss"),
        },
        {
          name: "Week to date",
          date:
            moment().startOf("week").format("YYYY-MM-DD HH:mm:ss") +
            "|" +
            moment().format("YYYY-MM-DD HH:mm:ss"),
        },
        {
          name: "Month to date",
          date:
            moment().startOf("month").format("YYYY-MM-DD HH:mm:ss") +
            "|" +
            moment().format("YYYY-MM-DD HH:mm:ss"),
        },
        {
          name: "Quarter to date",
          date:
            moment().startOf("quarter").format("YYYY-MM-DD HH:mm:ss") +
            "|" +
            moment().format("YYYY-MM-DD HH:mm:ss"),
        },
        {
          name: "Year to date",
          date:
            moment().startOf("year").format("YYYY-MM-DD HH:mm:ss") +
            "|" +
            moment().format("YYYY-MM-DD HH:mm:ss"),
        },
        {
          name:
            "4th Quarter (" + moment().subtract(1, "year").format("YYYY") + ")",
          date:
            moment()
              .subtract(1, "year")
              .quarter(4)
              .startOf("quarter")
              .format("YYYY-MM-DD HH:mm:ss") +
            "|" +
            moment()
              .subtract(1, "year")
              .quarter(4)
              .endOf("quarter")
              .format("YYYY-MM-DD HH:mm:ss"),
        },
        {
          name:
            "3rd Quarter (" + moment().subtract(1, "year").format("YYYY") + ")",
          date:
            moment()
              .subtract(1, "year")
              .quarter(3)
              .startOf("quarter")
              .format("YYYY-MM-DD HH:mm:ss") +
            "|" +
            moment()
              .subtract(1, "year")
              .quarter(3)
              .endOf("quarter")
              .format("YYYY-MM-DD HH:mm:ss"),
        },
        {
          name:
            "2nd Quarter (" + moment().subtract(1, "year").format("YYYY") + ")",
          date:
            moment()
              .subtract(1, "year")
              .quarter(2)
              .startOf("quarter")
              .format("YYYY-MM-DD HH:mm:ss") +
            "|" +
            moment()
              .subtract(1, "year")
              .quarter(2)
              .endOf("quarter")
              .format("YYYY-MM-DD HH:mm:ss"),
        },
        {
          name:
            "1st Quarter (" + moment().subtract(1, "year").format("YYYY") + ")",
          date:
            moment()
              .subtract(1, "year")
              .quarter(1)
              .startOf("quarter")
              .format("YYYY-MM-DD HH:mm:ss") +
            "|" +
            moment()
              .subtract(1, "year")
              .quarter(1)
              .endOf("quarter")
              .format("YYYY-MM-DD HH:mm:ss"),
        },
      ],
      selectedFilterOption: {
        date: this.newFilterOption,
      },
    };
  },
};
</script>