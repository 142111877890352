<template>
  <main role="main" class="bd-content">
    <div class="main-content">
      <section class="section section-nutrition-create section-firstAid-view">
        <div class="panel">
          <div class="panel-header">
            <div class="title-wrap">
              <div class="title-with-back">
                <router-link class="back-arrow" :to="{ name: 'FirstAid' }">
                  <back-arrow></back-arrow>
                </router-link>
                <h3 class="panel-title">First aid Details</h3>
                <div class="switch">
                  <input
                    id="language-toggle"
                    v-model="languageSwitch"
                    class="check-toggle check-toggle-round-flat"
                    type="checkbox"
                    @change="switchToggle($event)"
                  />
                  <label for="language-toggle"></label>
                  <span class="on">EN</span>
                  <span class="off">ZH</span>
                </div>
              </div>
            </div>
          </div>
          <div class="panel-body">
            <v-app>
              <div class="first-aid-form-wrapper">
                <v-chip class="status" :color="getColor(firstaid.status)" dark>
                  {{ firstaid.status }}
                </v-chip>

                <div class="action-wrap">
                  <router-link
                    :to="{ name: 'EditFirstAid', params: { id: id } }"
                    class="btn-purple"
                    >Edit</router-link
                  >
                  <button class="btn-text" @click="dialogDelete = true">
                    Delete
                    <div class="text-animation"></div>
                  </button>
                </div>
                <div class="title-wrap">
                  <h3 class="title" v-if="languageSwitch == false">
                    {{ firstaid.title_en }}
                  </h3>
                  <h3 class="title" v-else>
                    {{ firstaid.title_zh }}
                  </h3>
                </div>
                <div class="content-wrap">
                  <div class="first-aid-desc" v-if="languageSwitch == false">
                    {{ firstaid.description_en }}
                  </div>
                  <div class="first-aid-desc" v-else>
                    {{ firstaid.description_zh }}
                  </div>
                  <div class="first-aid-video" v-if="firstaid.video != null">
                    <iframe
                      :src="'https://www.youtube.com/embed/'+firstaid.video"
                      :title="firstaid.title"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                  <div class="first-aid-image" v-if="firstaid.image != null">
                    <img :src="firstaid.image" />
                  </div>
                  <div
                    class="first-aid-steps"
                    v-for="(step, key) in firstaid.steps"
                    :key="key"
                  >
                    <h6 class="step-title" v-if="languageSwitch == false">
                      {{ step.title_en }}
                    </h6>
                    <h6 class="step-title" v-else>
                      {{ step.title_zh }}
                    </h6>
                    <div
                      class="step-description"
                      v-if="languageSwitch == false"
                    >
                      {{ step.description_en }}
                    </div>
                    <div class="step-description" v-else>
                      {{ step.description_zh }}
                    </div>
                  </div>
                </div>
              </div>
              <v-dialog v-model="dialogDelete" max-width="460px">
                <v-card class="modal-actionDelete">
                  <div class="modal-head">
                    <v-card-title class="card-title">Delete</v-card-title>
                  </div>
                  <v-card-subtitle>
                    Are you sure want to delete?
                  </v-card-subtitle>
                  <v-card-actions>
                    <v-btn
                      class="btn btn-secondary"
                      text
                      @click="dialogDelete = false"
                      >Cancel</v-btn
                    >
                    <v-btn
                      class="btn btn-primary btn-sucess"
                      text
                      @click="deleteItemConfirm"
                      >Yes, delete</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-app>
          </div>
        </div>
      </section>
    </div>
  </main>
</template>
<script>
import BackArrow from "../icons/BackArrow.vue";
export default {
  name: "FirstAidView",
  components: {
    BackArrow,
  },
  data() {
    return {
      id: this.$route.params.id,
      loading: false,
      languageSwitch: false,
      firstaid: {
        status: null,
        title_en: "",
        title_zh: "",
        description_en: "",
        description_zh: "",
        video: null,
        image: null,
        steps: [
          {
            title_en: "",
            title_zh: "",
            description_en: "",
            description_zh: "",
          },
        ],
      },
      dialogDelete: false,
    };
  },
  created() {
    const token = localStorage.getItem("access_token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    this.getFirstAid();
  },
  methods: {
    switchToggle(e) {
      this.languageSwitch = e.target.checked;
    },
    getFirstAid() {
      axios
        .get(`/admin/first-aid/${this.id}`)
        .then(({ data }) => {
          this.firstaid.status = data.status;
          this.firstaid.title_en = data.all_translations[0].title;
          this.firstaid.title_zh = data.all_translations[1].title;
          this.firstaid.description_en = data.all_translations[0].description;
          this.firstaid.description_zh = data.all_translations[1].description;
          this.firstaid.video = data.video;
          this.firstaid.image = data.image_web;
          let all_steps = [];
          data.steps.map(function (step) {
            all_steps.push({
              title_en: step.all_translations[0].title,
              title_zh: step.all_translations[1].title,
              description_en: step.all_translations[0].description,
              description_zh: step.all_translations[1].description,
            });
          });
          this.firstaid.steps = all_steps;
        })
        .catch((error) => console.log(error));
    },
    deleteItemConfirm() {
      axios
        .delete(`/admin/first-aid/${this.id}`)
        .then(({ data }) => {
          this.dialogDelete = false;
          this.$router.push({ name: "FirstAid" });
          Toast.success();
        })
        .catch((errors) => Toast.error());
    },
    getColor(statusGet) {
      if (statusGet == "active") return "v-chip--active";
      else if (statusGet == "inactive") return "v-chip--inactive";
      else return "hidden";
    },
  },
};
</script>

<style>
</style>
