<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          d="M20.67,7.75A3.69,3.69,0,0,0,19.3,6.09L13,1.66A3.4,3.4,0,0,0,11.44,1l-.09,0h-.7c-.27.07-.54.13-.81.21A5.39,5.39,0,0,0,8.46,2L2.7,6.09A3.79,3.79,0,0,0,1,9.34c0,2.87,0,5.73,0,8.59a3.05,3.05,0,0,0,2.67,3,4,4,0,0,0,.6,0H17.82a3.05,3.05,0,0,0,2.85-1.66,6.39,6.39,0,0,0,.33-1V8.89A11.4,11.4,0,0,0,20.67,7.75ZM13,19H9V14a2,2,0,0,1,4,0Zm4.84,0H15V14a4,4,0,0,0-8,0v5H4.21a1.66,1.66,0,0,1-.69-.16A.93.93,0,0,1,3,17.94V9.37a1.89,1.89,0,0,1,.84-1.64l6.32-4.45a1.36,1.36,0,0,1,1.3-.2,2.11,2.11,0,0,1,.44.24C14,4.77,16,6.23,18.09,7.67A2,2,0,0,1,19,9.44c0,2.8,0,5.59,0,8.39A1.12,1.12,0,0,1,17.84,19Z"
          fill="#181818"
        />
        <rect width="22" height="22" fill="none" />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "DashboardIcon",
};
</script>

<style>
</style>
