<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <path
                      d="M11.83,1.79A.84.84,0,0,1,12.67,1,8.42,8.42,0,0,1,21.1,9.34a.85.85,0,0,1-1.69,0,6.72,6.72,0,0,0-6.74-6.71A.84.84,0,0,1,11.83,1.79Z"
                      fill="#181818"
                    />
                    <path
                      d="M12.67,6A3.37,3.37,0,0,1,16,9.34a.85.85,0,0,0,1.69,0,5,5,0,0,0-5.06-5,.84.84,0,1,0,0,1.67Z"
                      fill="#181818"
                    />
                    <path
                      d="M20.34,15l-2.06-1.58a2.63,2.63,0,0,0-3.61,0l-1.23,1A10.75,10.75,0,0,1,7.59,8.57l1-1.22a2.59,2.59,0,0,0,0-3.59L7,1.71a2.6,2.6,0,0,0-3.65,0l-1,.84c-6.51,6.89,10.29,23.61,17.2,17l.77-.88A2.61,2.61,0,0,0,20.34,15ZM19.1,17.52l-.76.88c-5,4.71-19.58-9-14.82-14.66l1-.84a.93.93,0,0,1,1.27,0l1.58,2a.92.92,0,0,1,0,1.3L6,7.91a.85.85,0,0,0-.12.84,12.63,12.63,0,0,0,7.44,7.39A.81.81,0,0,0,14.1,16l1.73-1.37a.94.94,0,0,1,1.3,0l2.05,1.57A.94.94,0,0,1,19.1,17.52Z"
                      fill="#181818"
                    />
                    <rect width="22" height="22" fill="none" />
                  </g>
                </g>
              </svg>
</template>

<script>
export default {
  name: "ContactIcon",
};
</script>

<style>
</style>
