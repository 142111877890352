<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          d="M7.85,15.5a.29.29,0,0,1,.3-.3h5.7a.29.29,0,0,1,.3.3V17a.29.29,0,0,1-.3.3H8.15a.29.29,0,0,1-.3-.3Zm4.2-7.35a.29.29,0,0,0-.3-.3h-1.5a.29.29,0,0,0-.3.3v1.5a.29.29,0,0,1-.3.3H8.15a.29.29,0,0,0-.3.3v1.5a.29.29,0,0,0,.3.3h1.5a.29.29,0,0,1,.3.3v1.5a.29.29,0,0,0,.3.3h1.5a.29.29,0,0,0,.3-.3v-1.5a.29.29,0,0,1,.3-.3h1.5a.29.29,0,0,0,.3-.3v-1.5a.29.29,0,0,0-.3-.3h-1.5a.29.29,0,0,1-.3-.3Z"
          fill="#181818"
        />
        <path
          d="M11,.5Q2.6,7.67,2.6,12.89a8.4,8.4,0,1,0,16.8,0Q19.4,7.66,11,.5Zm0,18.9a6.25,6.25,0,0,1-6.3-6.51c0-2.46,2.05-5.71,6.3-9.6,4.25,3.89,6.3,7.13,6.3,9.6A6.25,6.25,0,0,1,11,19.4Z"
          fill="#181818"
        />
        <rect width="22" height="22" fill="none" />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "FirstAidIcon",
};
</script>

<style>
</style>
