<template>
  <div class="template-places">
    <ul class="lists">
      <li class="list" v-for="(item, index) in places" :key="index">
        <div class="list-wrap">
          <div class="area-left">
            <h3 class="list-title">
                <span class="location">
                    <location-icon></location-icon>
                </span>
                <span>
              {{ item.name }}
                </span>
            </h3>
            <span class="date">Checked in {{ item.date }}</span>
          </div>
          <div class="area-right">
            <figure class="image-thumb">
              <img :src="item.image" :alt="item.name" />
            </figure>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>


<script>
import LocationIcon from "../icons/LocationIcon.vue";
export default {
  name: "CheckInPlace",
  components:{LocationIcon},
  props:['items'],
  data() {
    return {
      places: [
        {
          name: "Art Park West Kowloon Cultural District",
          date: "30 mins ago",
          image: "/images/kitty.jpeg",
        },
      ],
    };
  },
  mounted(){
      this.places = this.items
   console.log(JSON.stringify(this.items));
  }
};
</script>

<style lang="scss" scoped>
</style>
