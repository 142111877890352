<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M7.88704 7.76495C7.88753 8.69204 7.45054 9.5652 6.70773 10.121C6.19872 10.5021 5.57965 10.7078 4.94352 10.7071C3.31747 10.7067 1.99963 9.38962 2 7.76532C2 7.76471 2 7.76409 2 7.76348C2 4.45942 4.94352 1 4.94352 1C5.80155 2.11118 6.52755 3.31802 7.10705 4.59638C7.34852 5.13616 7.54324 5.69555 7.68901 6.26855C7.81539 6.75752 7.88189 7.25998 7.88704 7.76495Z"
      fill="#E77386"
    />
    <path
      d="M13.9996 10.3754C13.9996 6.21899 10.0418 1.27897 10.0418 1.27897C10.0418 1.27897 6.07925 5.93102 6.07925 10.3754C6.07827 12.5604 7.85058 14.3324 10.0378 14.3333C12.2251 14.3343 13.999 12.5639 14 10.379C14 10.3778 13.9996 10.3766 13.9996 10.3754Z"
      fill="#E77386"
    />
  </svg>
</template>

<script>
export default {
  name: "BloodIcon",
};
</script>

<style>
</style>
