<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <path
                      d="M6.77,7.21A4.23,4.23,0,0,1,11,3h4.23V7.21a4.23,4.23,0,0,1-8.46,0ZM11,1a6.23,6.23,0,1,0,6.23,6.23V2a1,1,0,0,0-1-1ZM7.27,14.49a6,6,0,0,0-5.93,5.45,1,1,0,0,0,2,.17,3.94,3.94,0,0,1,3.93-3.62h7.46a3.94,3.94,0,0,1,3.93,3.62,1,1,0,0,0,2-.17,6,6,0,0,0-5.93-5.45Zm3.3-6.18a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42,2.76,2.76,0,0,0,3.89,0,1,1,0,0,0-1.42-1.42A.74.74,0,0,1,10.57,8.31Z"
                      fill="#181818"
                      fill-rule="evenodd"
                    />
                    <rect width="22" height="22" fill="none" />
                  </g>
                </g>
              </svg>
</template>

<script>
export default {
  name: "UserIcon",
};
</script>

<style>
</style>
