<template>

    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M12.0003 23.43C18.3129 23.43 23.4303 18.3126 23.4303 12C23.4303 5.68739 18.3129 0.570007 12.0003 0.570007C5.6877 0.570007 0.570312 5.68739 0.570312 12C0.570312 18.3126 5.6877 23.43 12.0003 23.43Z"
        fill="#57DAA1"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.712 7.49919C18.0566 7.77491 18.1125 8.27779 17.8369 8.62248L11.8018 16.1684C11.5962 16.4256 11.2194 16.4636 10.9665 16.2528L6.61438 12.6256C6.27506 12.3428 6.22923 11.8385 6.512 11.4992C6.79478 11.1599 7.29908 11.114 7.63843 11.3967L10.8427 14.0666C11.0371 14.2286 11.3267 14.1993 11.4848 14.0017L16.5887 7.62391C16.8645 7.27932 17.3674 7.22348 17.712 7.49919Z"
        fill="white"
      />
    </svg>

</template>

<script>
export default {
  name: "DoseSuccess",
};
</script>

<style>
</style>
