<template>
 <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <path
                      d="M11,17a6,6,0,1,1,6-6A6,6,0,0,1,11,17ZM11,7a4,4,0,1,0,4,4A4,4,0,0,0,11,7Z"
                      fill="#181818"
                    />
                    <path
                      d="M11,4a1,1,0,0,1-1-1V1a1,1,0,0,1,2,0V3A1,1,0,0,1,11,4Z"
                      fill="#181818"
                    />
                    <path
                      d="M11,22a1,1,0,0,1-1-1V19a1,1,0,0,1,2,0v2A1,1,0,0,1,11,22Z"
                      fill="#181818"
                    />
                    <path
                      d="M11,4a1,1,0,0,1-1-1V1a1,1,0,0,1,2,0V3A1,1,0,0,1,11,4Z"
                      fill="#181818"
                    />
                    <path
                      d="M11,22a1,1,0,0,1-1-1V19a1,1,0,0,1,2,0v2A1,1,0,0,1,11,22Z"
                      fill="#181818"
                    />
                    <path
                      d="M21,12H19a1,1,0,0,1,0-2h2a1,1,0,0,1,0,2Z"
                      fill="#181818"
                    />
                    <path
                      d="M3,12H1a1,1,0,0,1,0-2H3a1,1,0,0,1,0,2Z"
                      fill="#181818"
                    />
                    <path
                      d="M5.34,6.34a1,1,0,0,1-.7-.29L3.22,4.64A1,1,0,0,1,4.64,3.22L6.05,4.64a1,1,0,0,1,0,1.41A1,1,0,0,1,5.34,6.34Z"
                      fill="#181818"
                    />
                    <path
                      d="M18.07,19.07a1,1,0,0,1-.71-.29L16,17.36A1,1,0,0,1,17.36,16l1.42,1.41a1,1,0,0,1,0,1.42A1,1,0,0,1,18.07,19.07Z"
                      fill="#181818"
                    />
                    <path
                      d="M16.66,6.34A1,1,0,0,1,16,6.05a1,1,0,0,1,0-1.41l1.41-1.42a1,1,0,1,1,1.42,1.42L17.36,6.05A1,1,0,0,1,16.66,6.34Z"
                      fill="#181818"
                    />
                    <path
                      d="M3.93,19.07a1,1,0,0,1-.71-.29,1,1,0,0,1,0-1.42L4.64,16a1,1,0,0,1,1.41,1.41L4.64,18.78A1,1,0,0,1,3.93,19.07Z"
                      fill="#181818"
                    />
                    <rect width="22" height="22" fill="none" />
                  </g>
                </g>
              </svg>
</template>

<script>
export default {
  name: "OfferMgmtIcon",
};
</script>

<style>
</style>
