// console.log("Admin SPA");
require('./bootstrap');

// import { createApp } from 'vue'
import Vue from 'vue';
// Vuetify
import vuetify from './plugins/vuetify'



//Import router
import VueRouter from 'vue-router'
Vue.use(VueRouter)

//Import vue moment
import VueMoment from 'vue-moment';
Vue.use(VueMoment);

// Import Sweetalert2
import Swal from 'sweetalert2'
window.Swal = Swal

// Import Toaster Class
import Toast from './Helpers/Toast'
window.Toast = Toast


//Importing Partial Components
import TopBar from "./components/partials/TopBar.vue";
import Sidebar from "./components/partials/Sidebar.vue";
// import App from "./App.vue"


import { routes } from "./router/index.js";
const router = new VueRouter({
    routes,
    mode: 'history',
    linkActiveClass: '',
    linkExactActiveClass: "router-link-active",
});

import { store } from './store/index'

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!store.getters.loggedIn) {
            next({
                name: 'login',
            })
        } else {
            next()
        }
    } else if (to.matched.some(record => record.meta.requiresVisitor)) {
        if (store.getters.loggedIn) {
            next({
                name: 'dashboard',
            })
        } else {
            next()
        }
    } else {
        next()
    }
})

//Authenticate Token
const token = localStorage.getItem("access_token");
if (token) {
    axios.defaults.headers.common['Authorization'] = "Bearer " + token;
}

// Add interceptors for validate authorization
axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        store.dispatch('destroyUnAuthorizedToken')
        return router.push({ name: "login" })
      }
      return Promise.reject(error)
    }
  );

// Moment date Format
import moment from "moment";
import axios from 'axios';
Vue.filter('formatDate', function (value) {
    if (value) {
        return moment(String(value)).utc().format('MMMM D, YYYY')
    }
})

const app = new Vue({
    el: "#app",
    router,
    vuetify,
    store: store,
    components: {
        TopBar,
        Sidebar,

    }
});



