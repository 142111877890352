<template>
<div>
      new section
</div>
</template>

<script>
export default {
name:"NewSection",
}
</script>

<style>

</style>
