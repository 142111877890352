<template>
  <div class="auth auth-change">
    <div class="login-wrapper">
      <form method="POST" @submit.prevent="resetPassword()">
        <!-- Email -->
        <div class="panel">
          <div class="panel-header">
            <div class="logo-wrap">
              <img
                class="logo"
                :src="asset_url + 'images/logo-white.png'"
                width="150"
                height="30"
                alt="logo "
              />
            </div>
          </div>
          <div class="panel-body">
            <!-- <div class="error-message"></div> -->
            <p class="email-notice">Change your password.</p>
            <!-- password -->
            <!-- <div class="form-group">
              <input
                class="form-control"
                placeholder="Email"
                type="email"
                name="email"
                v-model="form.email"
              />
              <div class="pre-icon icon-password"></div>

              <small class="text-danger" v-if="errors">
                {{ errors.message }}
              </small>
            </div> -->
            <!-- password -->
            <div class="form-group">
              <input
                class="form-control"
                placeholder="New password"
                type="password"
                name="password"
                v-model="form.password"
                required
              />
              <div class="pre-icon icon-password"></div>
              <!-- error message -->
              <div>
                <small class="text-danger" v-if="errors.password">
                  {{ errors.password[0] }}
                </small>
              </div>
            </div>

            <!-- password -->
            <div class="form-group">
              <input
                class="form-control"
                placeholder="Confirm new password"
                type="password"
                name="password"
                v-model="form.confirm_password"
                required
              />
              <div class="pre-icon icon-password"></div>
              <!-- error message -->

              <div>
                <small class="text-danger" v-if="errors.confirm_password">
                  {{ errors.confirm_password[0] }}
                </small>
              </div>
            </div>
          </div>
          <div class="panel-footer">
            <!-- Submit Button -->
            <button
              type="submit"
              class="btn btn-primary btn-login"
              :disabled="isDisable"
            >
              Change password
                  <span class="loading-dots" v-if="loading"></span>
            </button>
            <!-- <div class="forgot-password">
              <router-link :to="{ name: 'login' }"> Back to login </router-link>
            </div> -->
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "changepassword",
  props: ["asset_url"],
  data() {
    return {
      form: {
        token: null,
        email: null,
        password: "",
        confirm_password: "",
      },
      isDisable: true,
      errors: {
        password: "",
        confirm_password: "",
      },
          loading: false,
    };
  },
  watch: {
    form: {
      handler: function (v) {
        if (this.form.password == "" || this.form.confirm_password == "") {
          return (this.isDisable = true);
        } else {
          return (this.isDisable = false);
        }
      },
      deep: true,
    },
  },
  methods: {
    resetPassword() {
          this.loading = true;
      this.form.token = this.$route.params.token;
      axios
        .post("/admin/update-password", this.form)
        .then((res) => {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
          Toast.fire({
            icon: "success",
            title: "Password Changed",
          });
          this.$router.push({ name: "login" });
            this.loading = false;

        })
        .catch((error) => {
              this.loading = false;
          if (error.response) {
            this.errors.password = error.response.data.errors.password;
            this.errors.confirm_password =
              error.response.data.errors.confirm_password;
          }
          console.log(error.response);
        });
    },
  },
  // computed: {
  //   isDisabled() {
  //     if (this.form.password == "" || this.form.confirm_password == "") {
  //       return (this.isDisable = false);
  //     } else {
  //       return (this.isDisable = false);
  //     }
  //   },
  // },
};
</script>

<style scoped>
</style>
