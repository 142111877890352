<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="11" height="14" viewBox="0 0 11 14" fill="none">
<path fill-rule="evenodd" clip-rule="evenodd" d="M5.22055 13.9291L5.44456 13.6114L5.22055 13.9291ZM5.66856 13.9291L5.67012 13.9283L5.67401 13.9256L5.68567 13.9167L5.73156 13.8836C5.77045 13.8548 5.82723 13.8128 5.89879 13.7576C6.0419 13.648 6.24491 13.4866 6.48719 13.2793C7.1232 12.7357 7.71791 12.1455 8.26639 11.5137C9.55287 10.0258 10.8891 7.902 10.8891 5.47411C10.8891 4.02353 10.3159 2.63128 9.29542 1.60459C8.79103 1.09645 8.19113 0.693086 7.53024 0.417705C6.86935 0.142323 6.16052 0.000364916 5.44456 0C4.72862 0.000313974 4.01981 0.142211 3.35892 0.417525C2.69803 0.692839 2.09812 1.09613 1.5937 1.6042C0.572232 2.63324 -0.000685632 4.02457 6.15786e-07 5.4745C6.15786e-07 7.902 1.33625 10.0258 2.62272 11.5137C3.17121 12.1455 3.76592 12.7357 4.40193 13.2793C4.6446 13.4866 4.84721 13.648 4.99033 13.7576C5.06052 13.8117 5.13144 13.8649 5.20305 13.9171L5.2155 13.9256L5.219 13.9283L5.22055 13.9291C5.35472 14.0236 5.53439 14.0236 5.66856 13.9291ZM5.44456 13.6114L5.66856 13.9291L5.44456 13.6114ZM7.38904 5.44456C7.38904 5.96027 7.18418 6.45485 6.81952 6.81952C6.45485 7.18418 5.96027 7.38904 5.44456 7.38904C4.92885 7.38904 4.43426 7.18418 4.0696 6.81952C3.70494 6.45485 3.50007 5.96027 3.50007 5.44456C3.50007 4.92885 3.70494 4.43426 4.0696 4.0696C4.43426 3.70494 4.92885 3.50007 5.44456 3.50007C5.96027 3.50007 6.45485 3.70494 6.81952 4.0696C7.18418 4.43426 7.38904 4.92885 7.38904 5.44456Z" fill="#E77386"/>
</svg>
</template>

<script>
export default {
name:"LocationIcon"
}
</script>

<style>

</style>
