<template>
  <main role="main" class="bd-content">
    <div class="main-content">
      <section class="section section-nutrition-create section-firstAid-create">
        <div class="panel">
          <div class="panel-header">
            <div class="title-wrap">
              <div class="title-with-back">
                <router-link class="back-arrow" :to="{ name: 'FirstAid' }">
                  <back-arrow></back-arrow>
                </router-link>
                <h3 class="panel-title">Edit first aid</h3>
              </div>
            </div>
            <div class="switch">
              <input
                id="language-toggle"
                v-model="form.status"
                class="check-toggle check-toggle-round-flat"
                type="checkbox"
                @change="switchToggle($event)"
              />
              <label for="language-toggle"></label>
              <span class="on">Active</span>
              <span class="off">Inactive</span>
            </div>
          </div>
          <div class="panel-body">
            <form @submit.prevent="updateFirstAid">
              <v-app>
                <div class="first-aid-form-wrapper">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="vaccine">Title-EN *</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Enter title"
                          v-model="form.title_en"
                          @click="errors.title_en = null"
                        />
                        <small class="text-danger" v-if="errors.title_en"
                          >{{ errors.title_en[0] }}
                        </small>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="vaccine">Title-ZH *</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Enter title"
                          v-model="form.title_zh"
                          @click="errors.title_zh = null"
                        />
                        <small class="text-danger" v-if="errors.title_zh"
                          >{{ errors.title_zh[0] }}
                        </small>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div
                        :class="
                          form.image == null || form.image == ''
                            ? 'form-group'
                            : 'form-group disabled'
                        "
                      >
                        <label for="vaccine">Video Link</label>
                        <input
                          type="url"
                          class="form-control"
                          v-model="form.video"
                          placeholder="https://www.youtube.com/"
                          @click="errors.video = null"
                        />
                        <small class="text-danger" v-if="errors.video"
                          >{{ errors.video[0] }}
                        </small>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div
                        :class="
                          form.video == null || form.video == ''
                            ? 'form-group'
                            : 'form-group disabled'
                        "
                      >
                        <label for="vaccine">Or banner image</label>
                        <ImageDragDrap
                          removeIcon="true"
                          :imageSource="form.image"
                          class="image-drag-drop"
                          placeText="Drag & Drop here (PNG or JPEG)"
                          @imageDetails="checkImage"
                          @click="errors.image = null"
                          @input="errors.image = null"
                        ></ImageDragDrap>
                        <small class="text-danger" v-if="errors.image">
                          {{ errors.image[0] }}
                        </small>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="clinic">Description-EN *</label>
                        <v-textarea
                          name=""
                          value=""
                          @keyup="lengthPad('form.description_en')"
                          placeholder="Enter description "
                          v-model="form.description_en"
                          @click="errors.description_en = null"
                        ></v-textarea>
                        <small class="text-danger" v-if="errors.description_en"
                          >{{ errors.description_en[0] }}
                        </small>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="clinic">Description-ZH *</label>
                        <v-textarea
                          name=""
                          value=""
                          @keyup="lengthPad('form.description_zh')"
                          placeholder="Enter description "
                          v-model="form.description_zh"
                          @click="errors.description_zh = null"
                        ></v-textarea>
                        <small class="text-danger" v-if="errors.description_zh"
                          >{{ errors.description_zh[0] }}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="step-form-wrapper">
                  <div
                    class="step-form"
                    v-for="(step, i) in form.steps"
                    :key="i"
                  >
                    <div class="step-form-wrap">
                      <span
                        class="btn-text"
                        v-if="i != 0"
                        @click="deleteStep(i)"
                        >Remove</span
                      >
                      <div class="row">
                        <div class="col-md-12">
                          <h4 class="step-title">Step {{ i + 1 }}</h4>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="title">Title-EN *</label>
                            <input
                              type="text"
                              class="form-control"
                              v-model="step.title_en"
                              placeholder=""
                              @click="
                                removeValidation('steps.' + i + '.title_en')
                              "
                              @input="
                                removeValidation('steps.' + i + '.title_en')
                              "
                            />
                            <div class="error-container">
                              <div v-for="(error, key) in errors" :key="key">
                                <span
                                  v-for="(errorItem, innerKey) in error"
                                  :key="innerKey"
                                  style="margin-top: 2rem"
                                >
                                  <small
                                    class="text-danger"
                                    v-if="key == 'steps.' + i + '.title_en'"
                                  >
                                    {{ errorItem }}
                                  </small>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="title">Title-ZH *</label>
                            <input
                              type="text"
                              class="form-control"
                              v-model="step.title_zh"
                              placeholder=""
                              @click="
                                removeValidation('steps.' + i + '.title_zh')
                              "
                              @input="
                                removeValidation('steps.' + i + '.title_zh')
                              "
                            />
                            <div class="error-container">
                              <div v-for="(error, key) in errors" :key="key">
                                <span
                                  v-for="(errorItem, innerKey) in error"
                                  :key="innerKey"
                                  style="margin-top: 2rem"
                                >
                                  <small
                                    class="text-danger"
                                    v-if="key == 'steps.' + i + '.title_zh'"
                                  >
                                    {{ errorItem }}
                                  </small>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group last">
                            <label for="description">Description-EN *</label>
                            <v-textarea
                              placeholder="Enter description"
                              v-model="step.description_en"
                              @click="
                                removeValidation(
                                  'steps.' + i + '.description_en'
                                )
                              "
                              @input="
                                removeValidation(
                                  'steps.' + i + '.description_en'
                                )
                              "
                            ></v-textarea>
                            <div class="error-container">
                              <div v-for="(error, key) in errors" :key="key">
                                <span
                                  v-for="(errorItem, innerKey) in error"
                                  :key="innerKey"
                                  style="margin-top: 2rem"
                                >
                                  <small
                                    class="text-danger"
                                    v-if="
                                      key == 'steps.' + i + '.description_en'
                                    "
                                  >
                                    {{ errorItem }}
                                  </small>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group last">
                            <label for="description">Description-ZH *</label>
                            <v-textarea
                              placeholder="Enter description"
                              v-model="step.description_zh"
                              @click="
                                removeValidation(
                                  'steps.' + i + '.description_zh'
                                )
                              "
                              @input="
                                removeValidation(
                                  'steps.' + i + '.description_zh'
                                )
                              "
                            ></v-textarea>
                            <div class="error-container">
                              <div v-for="(error, key) in errors" :key="key">
                                <span
                                  v-for="(errorItem, innerKey) in error"
                                  :key="innerKey"
                                  style="margin-top: 2rem"
                                >
                                  <small
                                    class="text-danger"
                                    v-if="
                                      key == 'steps.' + i + '.description_zh'
                                    "
                                  >
                                    {{ errorItem }}
                                  </small>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="actions" v-if="i == form.steps.length - 1">
                      <button
                        type="submit"
                        class="btn btn-primary"
                        :disabled="loading"
                      >
                        Save <span class="loading-dots" v-if="loading"></span>
                      </button>
                      <button
                        @click.prevent="addStep"
                        class="btn-text btn-border"
                      >
                        <AddWithCircle></AddWithCircle> Add next step
                      </button>
                    </div>
                  </div>
                </div>
              </v-app>
            </form>
          </div>
          <div class="panel-footer">
            <div class="actions"></div>
          </div>
        </div>
      </section>
    </div>
  </main>
</template>
<script>
import BackArrow from "../icons/BackArrow.vue";
import AddWithCircle from "../icons/AddWithCircle.vue";
import ImageDragDrap from "../subComponents/imageDragDrop.vue";
export default {
  name: "FirstAidCreate",
  components: {
    BackArrow,
    AddWithCircle,
    ImageDragDrap,
  },
  data() {
    return {
      loading: false,
      id: this.$route.params.id,
      form: {
        status: null,
        title_en: "",
        title_zh: "",
        description_en: "",
        description_zh: "",
        video: null,
        image: null,
        steps: [
          {
            title_en: "",
            title_zh: "",
            description_en: "",
            description_zh: "",
          },
        ],
      },
      errors: {},
      max: "200",
      noteLength_description_en: "0",
      noteLength_description_zh: "0",
    };
  },
  created() {
    const token = localStorage.getItem("access_token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    this.getFirstAid();
  },
  methods: {
    switchToggle(e) {
      this.form.status = e.target.checked;
    },
    getFirstAid() {
      axios
        .get(`/admin/first-aid/${this.id}`)
        .then(({ data }) => {
          this.form.status = data.status;
          this.form.title_en = data.all_translations[0].title;
          this.form.title_zh = data.all_translations[1].title;
          this.form.description_en = data.all_translations[0].description;
          this.form.description_zh = data.all_translations[1].description;
          if(data.video != null) {
             this.form.video = 'https://youtu.be/'+data.video;
          } else {
             this.form.video = data.video;
          }
          // console.log(data.video);
          this.form.image = data.image_web;
          let all_steps = [];
          data.steps.map(function (step) {
            all_steps.push({
              title_en: step.all_translations[0].title,
              title_zh: step.all_translations[1].title,
              description_en: step.all_translations[0].description,
              description_zh: step.all_translations[1].description,
            });
          });
          this.form.steps = all_steps;
        })
        .catch((error) => console.log(error));
    },
    updateFirstAid() {
      this.loading = true;
      axios
        .put(`/admin/first-aid/${this.id}`, this.form)
        .then(({ data }) => {
          this.loading = false;
          Toast.success();
          this.$router.push({ name: "FirstAid" });
        })
        .catch((error) => {
          this.loading = false;
          this.errors = error.response.data.errors;
        });
    },
    lengthPad(num) {
      if (num == "form.description_en") {
        let lengthFirstAid_en = this.form.description_en.length;
        this.noteLength_description_en = ("" + lengthFirstAid_en).slice(-3);
      }
      if (num == "form.description_zh") {
        let lengthFirstAid_zh = this.form.description_zh.length;
        this.noteLength_description_zh = ("" + lengthFirstAid_zh).slice(-3);
      }
    },
    addStep() {
      this.form.steps.push({
        title_en: "",
        title_zh: "",
        description_en: "",
        description_zh: "",
      });
    },
    deleteStep(index) {
      this.form.steps.splice(index, 1);
    },
    removeValidation(key) {
      this.errors[key] = null;
    },
    checkImage(data) {
      // console.log('hello'+data);
      this.errors.image = null;
      this.form.image = data;
    },
  },
};
</script>

<style>
</style>
