<template>
  <div class="drop" @dragover.prevent @drop="onDrop">
    <label v-if="!image" class="btn">
      <div class="svg-icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="28"
          height="28"
          viewBox="0 0 28 28"
          fill="none"
        >
          <rect
            x="1"
            y="1"
            width="26"
            height="26"
            rx="5"
            stroke="#989898"
            stroke-width="2"
          />
          <path
            d="M6 22L11.5 16L15 20L22 13"
            stroke="#989898"
            stroke-width="2"
            stroke-linecap="round"
          />
          <circle
            cx="11.5"
            cy="8.5"
            r="2.5"
            stroke="#989898"
            stroke-width="2"
          />
        </svg>
      </div>
      <div class="text">SELECT OR DROP AN IMAGE</div>
      <input
        type="file"
        name="image"
        accept="image/png, image/gif, image/jpeg"
        @change="onChange"
      />
    </label>
    <div
      class="hidden display-inline align-center"
      v-else
      v-bind:class="{ image: true }"
    >
      <div class="img-wrap">
        <img :src="image" alt="" class="img" />
        <button v-if="remove_icon" class="btn-remove" @click="removeFile">
          REMOVE
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImageDragDrap",
  props: ["removeIcon","imageSource"],
  data() {
    return {
      image: this.imageSource,
      remove_icon: this.removeIcon,
    };
  },  
  watch: {
    imageSource: function (newVal, oldVal) {
      // watch it
      // console.log('Prop changed: ', newVal, ' | was: ', oldVal)
      this.image = newVal;
    },
  },
  methods: {
    onDrop(e) {
      e.stopPropagation();
      e.preventDefault();
      var files = e.dataTransfer.files;
      this.createFile(files[0]);
    },
    onChange(e) {
      var files = e.target.files;
      this.createFile(files[0]);
    },
    createFile(file) {
      if (!file.type.match("image.*")) {
        alert("Select an image");
        return;
      }
      var img = new Image();
      var reader = new FileReader();
      var vm = this;

      reader.onload = function (e) {
        vm.image = e.target.result;
      };
      reader.readAsDataURL(file);
      setTimeout(() => {
          this.$emit("imageDetails", vm.image);
      }, 100);
    },
    removeFile() {
      this.image = "";
       this.$emit("imageDetails", this.image);
    },
  },
};
</script>
<style lang="scss" scoped>
input[type="file"] {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
.helper {
  height: 100%;
  display: inline-block;
  vertical-align: middle;
  width: 0;
}

.hidden {
  display: none !important;
}
.hidden.image {
  display: inline-block !important;
}

.display-inline {
  display: inline-block;
  vertical-align: middle;
}

.drop {
  height: 100%;
  width: 100%;
  .btn {
    border: 1px solid var(--input-border);
    padding-top: 27px;
    padding-bottom: 27px;
    border-radius: 16px;
  }
  .svg-icon {
    margin-bottom: 12px;
  }
  .text {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #989898;
  }
  > .image {
    height: 110px;
    width: 100%;
    .img-wrap {
      position: relative;

      img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border: 1px solid var(--input-border);
        border-radius: 16px;
        overflow: hidden;
      }
    }
  }
}
</style>
