<template>
  <main role="main" class="bd-content">
    <h1>404 Page not found</h1>
  </main>
</template>
<script>
export default {};
</script>

<style>
</style>