<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          d="M11,16l1-2V8.86a4,4,0,1,0-2,0V14ZM9,5a2,2,0,1,1,2,2A2,2,0,0,1,9,5Z"
          fill="#181818"
        />
        <path
          d="M15.27,9.56l-.54,1.93C17.33,12.21,19,13.58,19,15c0,1.89-3.28,4-8,4s-8-2.11-8-4c0-1.42,1.67-2.79,4.27-3.51L6.73,9.56C3.2,10.54,1,12.62,1,15c0,3.36,4.39,6,10,6s10-2.64,10-6C21,12.62,18.8,10.54,15.27,9.56Z"
          fill="#181818"
        />
        <rect width="22" height="22" fill="none" />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "CheckInPlacesIcon",
};
</script>

<style>
</style>
