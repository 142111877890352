<template>
  <main role="main" class="bd-content">
    <h1>Terms and Conditions</h1>
  </main>
</template>

<script>
export default {};
</script>

<style>
</style>