<template>
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
<path d="M15 15.375V16H1V15.375C1 15.1679 1.16791 15 1.375 15H2V3.75C2 3.33578 2.33578 3 2.75 3H5.5V0.75C5.5 0.335781 5.83578 0 6.25 0H9.75C10.1642 0 10.5 0.335781 10.5 0.75V3H13.25C13.6642 3 14 3.33578 14 3.75V15H14.625C14.8321 15 15 15.1679 15 15.375ZM10.625 6H9.375C9.16791 6 9 6.16791 9 6.375V7.625C9 7.83209 9.16791 8 9.375 8H10.625C10.8321 8 11 7.83209 11 7.625V6.375C11 6.16791 10.8321 6 10.625 6ZM5.375 8H6.625C6.83209 8 7 7.83209 7 7.625V6.375C7 6.16791 6.83209 6 6.625 6H5.375C5.16791 6 5 6.16791 5 6.375V7.625C5 7.83209 5.16791 8 5.375 8ZM8.625 12H7.375C7.16791 12 7 12.1679 7 12.375V15H9V12.375C9 12.1679 8.83209 12 8.625 12ZM10.625 9H9.375C9.16791 9 9 9.16791 9 9.375V10.625C9 10.8321 9.16791 11 9.375 11H10.625C10.8321 11 11 10.8321 11 10.625V9.375C11 9.16791 10.8321 9 10.625 9ZM7 9.375C7 9.16791 6.83209 9 6.625 9H5.375C5.16791 9 5 9.16791 5 9.375V10.625C5 10.8321 5.16791 11 5.375 11H6.625C6.83209 11 7 10.8321 7 10.625V9.375ZM6.6875 3H7.5V3.8125C7.5 3.86223 7.51975 3.90992 7.55492 3.94508C7.59008 3.98025 7.63777 4 7.6875 4H8.3125C8.36223 4 8.40992 3.98025 8.44508 3.94508C8.48025 3.90992 8.5 3.86223 8.5 3.8125V3H9.3125C9.36223 3 9.40992 2.98025 9.44508 2.94508C9.48025 2.90992 9.5 2.86223 9.5 2.8125V2.1875C9.5 2.13777 9.48025 2.09008 9.44508 2.05492C9.40992 2.01975 9.36223 2 9.3125 2H8.5V1.1875C8.5 1.13777 8.48025 1.09008 8.44508 1.05492C8.40992 1.01975 8.36223 1 8.3125 1H7.6875C7.63777 1 7.59008 1.01975 7.55492 1.05492C7.51975 1.09008 7.5 1.13777 7.5 1.1875V2H6.6875C6.63777 2 6.59008 2.01975 6.55492 2.05492C6.51975 2.09008 6.5 2.13777 6.5 2.1875V2.8125C6.5 2.86223 6.51975 2.90992 6.55492 2.94508C6.59008 2.98025 6.63777 3 6.6875 3Z" fill="#E77386"/>
</svg>
</template>

<script>
export default {
  name: "HospitalIcon",
};
</script>

<style>
</style>
