<template>

    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="12"
      viewBox="0 0 8 12"
      fill="none"
    >
      <path
        d="M1 1L6 6L1 11"
        stroke="#181818"
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>

</template>

<script>
export default {
  name: "ArrowRight",
};
</script>

<style>
</style>
