<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <g clip-path="url(#clip0_356_1399)">
      <path
        d="M17.4994 3.33333H14.916C14.7226 2.39284 14.2109 1.54779 13.4671 0.940598C12.7233 0.333408 11.7929 0.0012121 10.8327 0L9.16602 0C8.20585 0.0012121 7.27545 0.333408 6.53164 0.940598C5.78783 1.54779 5.2761 2.39284 5.08268 3.33333H2.49935C2.27834 3.33333 2.06637 3.42113 1.91009 3.57741C1.75381 3.73369 1.66602 3.94565 1.66602 4.16667C1.66602 4.38768 1.75381 4.59964 1.91009 4.75592C2.06637 4.9122 2.27834 5 2.49935 5H3.33268V15.8333C3.33401 16.938 3.77342 17.997 4.55453 18.7781C5.33565 19.5593 6.39469 19.9987 7.49935 20H12.4994C13.604 19.9987 14.6631 19.5593 15.4442 18.7781C16.2253 17.997 16.6647 16.938 16.666 15.8333V5H17.4994C17.7204 5 17.9323 4.9122 18.0886 4.75592C18.2449 4.59964 18.3327 4.38768 18.3327 4.16667C18.3327 3.94565 18.2449 3.73369 18.0886 3.57741C17.9323 3.42113 17.7204 3.33333 17.4994 3.33333ZM9.16602 1.66667H10.8327C11.3496 1.6673 11.8536 1.82781 12.2757 2.1262C12.6978 2.42459 13.0172 2.84624 13.1902 3.33333H6.80852C6.9815 2.84624 7.30094 2.42459 7.72301 2.1262C8.14508 1.82781 8.64912 1.6673 9.16602 1.66667ZM14.9994 15.8333C14.9994 16.4964 14.736 17.1323 14.2671 17.6011C13.7983 18.0699 13.1624 18.3333 12.4994 18.3333H7.49935C6.83631 18.3333 6.20042 18.0699 5.73158 17.6011C5.26274 17.1323 4.99935 16.4964 4.99935 15.8333V5H14.9994V15.8333Z"
        fill="#E77386"
      />
      <path
        d="M8.33333 15C8.55435 15 8.76631 14.9122 8.92259 14.7559C9.07887 14.5997 9.16666 14.3877 9.16666 14.1667V9.16668C9.16666 8.94566 9.07887 8.7337 8.92259 8.57742C8.76631 8.42114 8.55435 8.33334 8.33333 8.33334C8.11232 8.33334 7.90036 8.42114 7.74408 8.57742C7.5878 8.7337 7.5 8.94566 7.5 9.16668V14.1667C7.5 14.3877 7.5878 14.5997 7.74408 14.7559C7.90036 14.9122 8.11232 15 8.33333 15Z"
        fill="#E77386"
      />
      <path
        d="M11.6673 15C11.8883 15 12.1003 14.9122 12.2566 14.7559C12.4129 14.5997 12.5007 14.3877 12.5007 14.1667V9.16668C12.5007 8.94566 12.4129 8.7337 12.2566 8.57742C12.1003 8.42114 11.8883 8.33334 11.6673 8.33334C11.4463 8.33334 11.2343 8.42114 11.0781 8.57742C10.9218 8.7337 10.834 8.94566 10.834 9.16668V14.1667C10.834 14.3877 10.9218 14.5997 11.0781 14.7559C11.2343 14.9122 11.4463 15 11.6673 15Z"
        fill="#E77386"
      />
    </g>
    <defs>
      <clipPath id="clip0_356_1399">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "Delete",
};
</script>

<style>
</style>
