<template>
  <div class="auth">
    <div class="login-wrapper">
      <form method="POST" @submit.prevent="forgotSubmit()">
        <!-- Email -->
        <div class="panel">
          <div class="panel-header">
            <div class="logo-wrap">
              <img
                class="logo"
                src="images/logo-white.png"
                width="150"
                height="30"
                alt="logo "
              />
            </div>
          </div>
          <div class="panel-body">
            <!-- <div class="error-message"></div> -->
            <p class="forget-title">
              Enter the email address associated with your account and we’ll
              send you a link to reset your password.
            </p>
            <div class="form-group">
              <input
                class="form-control"
                placeholder="Enter Email Address"
                type="email"
                name="email"
                v-model="form.email"
                @click="removeError"
              />
              <div class="pre-icon icon-email"></div>
              <!-- error message -->
              <div>
                <small class="text-danger" v-if="errors">{{ errors[0] }}</small>
              </div>
            </div>
          </div>
          <div class="panel-footer">
            <!-- Submit Button -->
            <button
              type="submit"
              class="btn btn-primary btn-login"
              :disabled="isDisable"
            >
              Continue
              <span class="loading-dots" v-if="loading"></span>
            </button>
            <!-- <div class="forgot-password">
              <router-link :to="{ name: 'login' }"> Back to login </router-link>
            </div> -->
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 1000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});
export default {
  name: "forgotPassword",
  data() {
    return {
      form: {
        email: "",
      },
      isDisable: true,
      errors: {},
      loading: false,
    };
  },
  watch: {
    form: {
      handler: function (v) {
        if (this.form.email == "") {
          return (this.isDisable = true);
        } else {
          return (this.isDisable = false);
        }
      },
      deep: true,
    },
  },
  methods: {
    forgotSubmit() {
      this.loading = true;
      this.isDisable = true;
      axios
        .post("/admin/forgot-password", this.form)
        .then((res) => {
          this.loading = false;

          Toast.fire({
            icon: "success",
            title: "Email Sent",
          });
          this.isDisable = false;
          this.errors = null;
        })
        .catch((error) => {
          // console.log(error.response.data);
          this.isDisable = false;
          this.loading = false;
          if (error.response.data.errors.email) {
            this.errors = error.response.data.errors.email;
          }
          if (error.response.data.errors.not_associated) {
            this.errors = error.response.data.errors.not_associated;
          }
        });
    },
    removeError() {
      this.errors = {};
      this.notAssociatedError = {};
    },
  },
};
</script>

<style scoped>
</style>
