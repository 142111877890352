<template>
  <div class="template-donations">
    <ul class="lists" v-if="items.length == 0">
      <li class="list">No records</li>
    </ul>
    <ul class="lists" v-else>
      <li class="list" v-for="(item, index) in donations" :key="index">
        <div class="list-wrap">
          <div class="area-left">
            <figure class="image-thumb">
              <img
                :src="
                  petDetail.image_web !== null
                    ? petDetail.image_web
                    : '/images/pet-deafult.png'
                "
                alt="pet icon"
              />
            </figure>
            <figure class="thumb-guardian">
              <img
                :src="
                  petDetail.user.image_web !== null
                    ? petDetail.user.image_web
                    : '/images/user-deafult.png'
                "
                alt=""
                class="guardian-img"
              />
            </figure>
          </div>
          <div class="area-right">
            <div class="text-wrap">
              <h3 class="list-title">
                <span>
                  {{ petDetail.name }}
                </span>
                <template v-if="item.cancelled_at">
                  <span class="status cancel"> Cancelled </span>
                </template>
                <template v-else>
                  <!-- {{ Date.now() | moment("D MMMM YYYY") }}
                  {{
                    new Date(item.expiration_at).getTime()
                      | moment("D MMMM YYYY")
                  }} -->

                  <template v-if="currentTime > item.expiration_at">
                    <span class="status expired"> Expired </span>
                  </template>
                  <template v-else>
                    <template v-if="item.status == 1">
                      <span
                        class="status"
                        :class="item.status == 1 ? 'active' : ''"
                      >
                        Active
                      </span>
                    </template>
                    <template v-else>
                      <span
                        class="status"
                        :class="item.status == 0 ? 'expired' : ''"
                      >
                        Expired
                      </span>
                    </template>
                  </template>
                </template>
              </h3>
              <div class="spec blood-type">
                <span class="icon">
                  <blood-icon></blood-icon>
                </span>
                <span class="blood">{{ item.blood_type }}</span>
              </div>
              <div class="spec blood-type">
                <span class="icon">
                  <hospital-icon></hospital-icon>
                </span>
                <span class="blood">{{ item.location }}</span>
              </div>
            </div>
            <div class="extend-area">
              <label>Extend:</label>
              <span
                >{{ item.extensions }}&nbsp;Time<template
                  v-if="item.extensions != 0 && item.extend != 1"
                  >s</template
                ></span
              >
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import BloodIcon from "../icons/BloodIcon.vue";
import HospitalIcon from "../icons/HospitalIcon.vue";
import moment from "moment";
export default {
  name: "BloodDonations",
  props: ["items", "petDetail"],
  components: {
    BloodIcon,
    HospitalIcon,
  },
  data() {
    return {
      currentTime: moment().format("YYYY-MM-DD HH:mm:ss"),
      donations: [
        {
          image: "/images/kitty.jpeg",
          name: "Groffy",
          blood_type: "(Positive) DEA-1.1",
          location: "Four Paws Animal Hospital",
          distance: "800m ",
          status: "active",
          extend: "0",
        },
      ],
      pet_detail: [],
    };
  },
  mounted() {
    this.donations = this.items;
    this.pet_detail = this.petDetail;
    //    console.log(JSON.stringify(this.items));
  },
};
</script>

<style lang="scss" scoped>
.list-title {
  span:not(.status) {
    margin-right: 14px;
    border-radius: 8px;
  }
  .status {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    padding: 5px 10px;
    color: #ffffff;
    display: inline-block;
  }
  .success,
  .sucessful {
    background: #57daa1;
  }
  .expired,
  .expire {
    background: #565656;
  }
  .cancel {
    background: #6a6a6a;
  }
  .registered,
  .active {
    background: #fd997a;
  }
}
.thumb-guardian {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  img {
    position: absolute;
    left: 0;
    top: 0;
    -o-object-fit: cover;
    object-fit: cover;
  }
}
</style>
