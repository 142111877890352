<template>

    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13.828"
      height="12.828"
      viewBox="0 0 13.828 12.828"
    >
      <g
        id="Group_9684"
        data-name="Group 9684"
        transform="translate(13.414 12.414) rotate(180)"
      >
        <path
          id="Path_1"
          data-name="Path 1"
          d="M7,1l5,5L7,11"
          fill="none"
          stroke="#6a6a6a"
          stroke-linecap="round"
          stroke-width="2"
        />
        <path
          id="Path_2"
          data-name="Path 2"
          d="M1,1,6,6,1,11"
          fill="none"
          stroke="#6a6a6a"
          stroke-linecap="round"
          stroke-width="2"
        />
      </g>
    </svg>

</template>

<script>
export default {
  name: "DoubleArrowLeft",
};
</script>

<style>
</style>
