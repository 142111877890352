<template>
  <main role="main" class="bd-content">
    <div class="main-content">
      <div class="section section-breed">
        <div class="panel">
          <div class="panel-body">
            <v-app class="mt-12 ml-12">
              <v-data-table
                class="elevation-1"
                :headers="headers"
                :items="breeds"
                :loading="loading"
                loading-text="Loading... Please wait"
                hide-default-footer
                :items-per-page="-1"
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <div class="toolbar-left">
                      <v-card-title>
                        <v-toolbar-title class="title-with-tab">
                          <h3 class="panel-title">Breeds</h3>
                          <div class="tab-wrap">
                            <!-- <v-btn
                                @click="getBreeds(1, 'dog')"
                                :class="form.pet_type == 0 ? 'active' : ''"
                                >Dog</v-btn
                              >
                              <v-btn
                                @click="getBreeds(1, 'cat')"
                                :class="form.pet_type == 1 ? 'active' : ''"
                                >Cat</v-btn
                              > -->
                            <template>
                              <v-tabs>
                                <v-tabs-slider color="#e77386"></v-tabs-slider>
                                <v-tab @click="getBreeds(1, 'dog')">
                                  Dog
                                </v-tab>
                                <v-tab @click="getBreeds(1, 'cat')">
                                  Cat
                                </v-tab>
                              </v-tabs>
                            </template>
                          </div>
                        </v-toolbar-title>
                        <div class="search-toolbar-area">
                          <!-- <v-text-field
                            v-model="search"
                            single-line
                            hide-details
                            placeholder="Search a name"
                          ></v-text-field> -->
                        </div>
                      </v-card-title>
                    </div>
                  </v-toolbar>
                </template>
                <template v-slot:item.sn="{ index }">
                  {{ index + 1 }}
                </template>
              </v-data-table>
              <Pagination
                :totalItems="pagination.totalItems + ' Breeds'"
                :totalPages="pagination.totalPages"
                :perPage="pagination.perPage"
                :currentPage="pagination.currentPage"
                @pagechanged="getBreeds"
              ></Pagination>
            </v-app>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import Pagination from "../../subComponents/pagination.vue";

export default {
  name: "breeds",
  components: {
    Pagination,
  },
  data() {
    return {
      pagination: {
        totalItems: 0,
        totalPages: 1,
        perPage: 0,
        currentPage: 1,
      },
      paginationOf: "breed",
      breeds: [],
      form: {
        pet_type: 0,
      },
      loading: false,
      headers: [
        {
          text: "S.N.",
          align: "center",
          sortable: false,
          value: "sn",
          width: "30px",
        },
        {
          text: "Name en",
          align: "center",
          sortable: true,
          value: "all_translations[0][name]",
        },
        {
          text: "Name zh",
          align: "center",
          sortable: true,
          value: "all_translations[1][name]",
        },
      ],
      search: null,
      awaitingSearch: false,
    };
  },
  created() {
    const token = localStorage.getItem("access_token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    this.getBreeds(this.pagination.currentPage, this.form);
  },
  watch: {
    search: function (val) {
      if (this.search == null || this.search == "") {
        this.awaitingSearch = false;
        this.getBreeds(this.pagination.currentPage, this.form);
        return false;
      } else {
        if (!this.awaitingSearch) {
          setTimeout(() => {
            if (this.search === null || this.search === "") {
              return;
            }
            this.loading = true;
            axios
              .post(
                `/admin/breed/search?page=${this.pagination.currentPage}`,
                this.form,
                { params: { search: this.search } }
              )
              .then(({ data }) => {
                this.paginationOf = "breed";
                this.pagination.totalItems = data.total;
                this.pagination.totalPages = data.last_page;
                this.pagination.perPage = data.per_page;
                this.pagination.currentPage = data.current_page;
                this.breeds = data.data;
                this.loading = false;
              })
              .catch((errors) => console.log(errors));
            this.awaitingSearch = false;
          }, 2000); // 2 sec delay
        }
        this.awaitingSearch = true;
      }
    },
  },
  methods: {
    searchBy(value) {
      this.search = value;
      console.log(this.search);
    },
    getBreeds(page, pet_type) {
      this.loading = true;
      this.pagination.currentPage = page;
      if (pet_type == "dog") {
        this.form.pet_type = 0;
      }
      if (pet_type == "cat") {
        this.form.pet_type = 1;
      }
      //  console.log("type"+pet_type);
      // console.log("type"+this.form);
      axios
        .post(`/admin/breed?page=${this.pagination.currentPage}`, this.form)
        .then(({ data }) => {
          //   console.log(data);
          this.paginationOf = "breed";
          this.pagination.totalItems = data.total;
          this.pagination.totalPages = data.last_page;
          this.pagination.perPage = data.per_page;
          this.pagination.currentPage = data.current_page;
          this.breeds = data.data;
          // console.log(this.breeds);
          this.loading = false;
        })
        .catch((errors) => console.log(errors));
    },
  },
};
</script>
