<template>
  <main role="main" class="bd-content">
    <div class="main-content">
      <div class="section section-nutrition section-firstAid">
        <div class="panel">
          <div class="panel-body">
            <v-app class="mt-12 ml-12">
              <v-data-table
                class="elevation-1"
                :headers="headers"
                :items="firstaids"
                :loading="loading"
                loading-text="Loading... Please wait"
                hide-default-footer
                :items-per-page="-1"
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <div class="toolbar-left">
                      <v-card-title>
                        <v-toolbar-title class="title-with-search">
                          <div class="title-wrap">
                            <h3 class="panel-title">First Aid Management</h3>
                          </div>
                        </v-toolbar-title>
                      </v-card-title>
                    </div>
                    <div class="toolbar-right">
                      <div class="nutrition-import-area">
                        <form @submit.prevent="importFirstAid">
                          <div class="form-group">
                            <div class="form-row">
                              <div class="form-title-wrap">
                                <h3 class="form-title">Import First Aid</h3>
                              </div>
                              <div class="field-wrap">
                                <div class="file-area" v-if="import_file_state">
                                  <label for="">File name: </label>
                                  <span
                                    class="file-name"
                                    v-if="import_file_name != null"
                                    >{{ import_file_name }}</span
                                  >
                                  <div
                                    v-if="error.message"
                                    class="invalid-feedback"
                                  >
                                    Invalid
                                  </div>
                                </div>
                                <div
                                  class="import-action"
                                  v-if="!import_file_state"
                                >
                                  <a
                                    class="btn btn-primary"
                                    @click="$refs.import_file.click()"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      xmlns:xlink="http://www.w3.org/1999/xlink"
                                      version="1.1"
                                      viewBox="0 0 512 512"
                                      enable-background="new 0 0 24 24"
                                      style=""
                                    >
                                      <g>
                                        <g>
                                          <path
                                            d="M480.6,341.4c-11.3,0-20.4,9.1-20.4,20.4v98.4H51.8v-98.4c0-11.3-9.1-20.4-20.4-20.4c-11.3,0-20.4,9.1-20.4,20.4v118.8    c0,11.3,9.1,20.4,20.4,20.4h449.2c11.3,0,20.4-9.1,20.4-20.4V361.8C501,350.5,491.9,341.4,480.6,341.4z"
                                            fill="#fff"
                                          />
                                          <path
                                            d="m241,365.6c11.5,11.6 25.6,5.2 29.9,0l117.3-126.2c7.7-8.3 7.2-21.2-1.1-28.9-8.3-7.7-21.2-7.2-28.8,1.1l-81.9,88.1v-265.2c0-11.3-9.1-20.4-20.4-20.4-11.3,0-20.4,9.1-20.4,20.4v265.3l-81.9-88.1c-7.7-8.3-20.6-8.7-28.9-1.1-8.3,7.7-8.7,20.6-1.1,28.9l117.3,126.1z"
                                            fill="#fff"
                                          />
                                        </g>
                                      </g>
                                    </svg>
                                    Import
                                  </a>

                                  <input
                                    type="file"
                                    class="form-control"
                                    id="input-file-import"
                                    name="file_import"
                                    ref="import_file"
                                    accept=".xlsx, .xls"
                                    @change="onFileChange"
                                  />
                                </div>

                                <div class="actions" v-else>
                                  <button
                                    type="submit"
                                    class="btn btn-primary"
                                    :disabled="loading"
                                  >
                                    Submit
                                    <span
                                      class="loading-dots"
                                      v-if="loading"
                                    ></span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                      <div class="action-download">
                        <a :href="csvFileLink" class="btn btn-primary" download>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            version="1.1"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="#fff"
                              d="M5,20H19V18H5M19,9H15V3H9V9H5L12,16L19,9Z"
                            />
                          </svg>
                          Download sample file
                        </a>
                      </div>
                      <router-link
                        :to="{ name: 'CreateFirstAid' }"
                        class="btn btn-primary btn-add"
                        >New Item</router-link
                      >
                    </div>
                    <!-- modal for Delete -->
                    <v-dialog v-model="dialogDelete" max-width="460px">
                      <v-card class="modal-actionDelete">
                        <div class="modal-head">
                          <v-card-title class="card-title">Delete</v-card-title>
                        </div>
                        <v-card-subtitle>
                          Are you sure want to delete?
                        </v-card-subtitle>
                        <v-card-actions>
                          <v-btn
                            class="btn btn-secondary"
                            text
                            @click="closeDelete"
                            >Cancel</v-btn
                          >
                          <v-btn
                            class="btn btn-primary btn-sucess"
                            text
                            @click="deleteItemConfirm"
                            >Yes, delete</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-toolbar>
                </template>

                <template v-slot:item.sn="{ index }">
                  {{ index + 1 }}
                </template>
                <template v-slot:item.all_translations[0].title="{ item }">
                  {{ item.all_translations[0].title }}
                </template>
                <template v-slot:item.all_translations[0].description="{ item }">
                  {{ item.all_translations[0].description }}
                </template>
                 <template v-slot:item.status="{ item }">
                  <v-chip class="status" :color="getColor(item.status)" dark>
                    {{ item.status }}
                  </v-chip>
                </template>
                <template v-slot:item.actions="{ item }">
                  <div class="action">
                    <router-link
                      :to="{ name: 'ViewFirstAid', params: { id: item.id } }"
                      class="btn btn-view-text"
                      >View</router-link
                    >
                    <router-link
                      :to="{ name: 'EditFirstAid', params: { id: item.id } }"
                      class="btn btn-view-text"
                      >Edit</router-link
                    >
                    <button
                      class="btn btn-delete-text"
                      @click="deleteItem(item)"
                    >
                      Delete
                      <div class="text-animation"></div>
                    </button>
                  </div>
                </template>
              </v-data-table>

              <Pagination
                :totalItems="pagination.totalItems + ' First Aids'"
                :totalPages="pagination.totalPages"
                :perPage="pagination.perPage"
                :currentPage="pagination.currentPage"
                @pagechanged="getFirstAids"
              ></Pagination>
            </v-app>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import Pagination from "../subComponents/pagination.vue";

export default {
  name: "FirstAids",
  props: ["csv_path"],
  components: {
    Pagination,
  },
  data() {
    return {
      csvFileLink: this.csv_path,
      error: {},
      import_file: "",
      import_file_name: "",
      import_file_state: false,
      pagination: {
        totalItems: 0,
        totalPages: 1,
        perPage: 0,
        currentPage: 1,
      },
      paginationOf: "firstaid",
      dialogDelete: false,
      loading: false,
      headers: [
        {
          text: "S.N.",
          align: "left",
          sortable: false,
          value: "sn",
        },
        {
          text: "Title",
          align: "left",
          sortable: true,
          value: "all_translations[0].title",
        },
        {
          text: "Description",
          align: "left",
          sortable: false,
          value: "all_translations[0].description",
        },
         { text: "Status", value: "status", sortable: true },
        { text: "Actions", value: "actions", sortable: false },
      ],
      firstaids: [],
      editedIndex: -1,
      editedItem: {
        id: "",
        title: "",
        description: "",
      },
      defaultItem: {
        id: "",
        title: "",
        description: "",
      },
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },
  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    const token = localStorage.getItem("access_token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    this.getFirstAids();
  },

  methods: {
    getFirstAids() {
      this.loading = true;
      axios
        .get(`/admin/first-aids`)
        .then(({ data }) => {
          this.paginationOf = "firstaid";
          this.pagination.totalItems = data.total;
          this.pagination.totalPages = data.last_page;
          this.pagination.perPage = data.per_page;
          this.pagination.currentPage = data.current_page;
          this.firstaids = data.data;
          this.loading = false;
        })
        .catch((error) => console.log(error));
    },
    deleteItem(item) {
      this.editedIndex = this.firstaids.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      axios
        .delete(`/admin/first-aid/${this.editedItem.id}`)
        .then(({ data }) => {
          this.closeDelete();
          this.firstaids.splice(this.editedIndex, 1);
          Toast.success();
        })
        .catch((errors) => Toast.error());
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    importFirstAid() {
      this.loading = true;
      let formData = new FormData();
      formData.append("import_file", this.import_file);
      axios
        .post("/admin/first-aid/import", formData, {
          headers: { "content-type": "multipart/form-data" },
        })
        .then((response) => {
          this.loading = false;
          this.import_file = "";
          this.import_file_name = "";
          this.import_file_state = false;
          if (response.status === 200) {
            Toast.success();
            this.getFirstAids();
          }
        })
        .catch((error) => {
          // code here when an upload is not valid
          this.loading = false;
          this.uploading = false;
          this.error = error.response.data;
          // console.log("check error: ", this.error);
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 1000,
            timerProgressBar: true,

            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
          Toast.fire({
            icon: "error",
            // title: "Invalid file!",
            title: "File format invalid!",
          });
          this.import_file_state = false;
          // Toast.error();
        });
    },
    onFileChange(e) {
      this.import_file = e.target.files[0];
      this.import_file_name = this.import_file.name;
      this.import_file_state = true;
    },
     getColor(statusGet) {
      if (statusGet == "active") return "v-chip--active";
      else if (statusGet == "inactive") return "v-chip--inactive";
      else return "hidden";
    },
  },
};
</script>
