<template>
  <div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="7.828"
      height="12.828"
      viewBox="0 0 7.828 12.828"
    >
      <path
        id="Vector_9"
        data-name="Vector 9"
        d="M1,1,6,6,1,11"
        transform="translate(7.414 12.414) rotate(180)"
        fill="none"
        stroke="#181818"
        stroke-linecap="round"
        stroke-width="2"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "ArrowLeft",
};
</script>

<style>
</style>
