<template>
  <main role="main" class="bd-content">
    <div class="main-content">
      <div class="section section-user">
        <div class="panel">
          <div class="panel-body">
            <v-app class="mt-12 ml-12">
              <v-data-table
                :headers="headers"
                :items="admins"
                :loading="loading"
                loading-text="Loading... Please wait"
                class="elevation-1"
                hide-default-footer
                :items-per-page="-1"
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <div class="toolbar-left">
                      <v-card-title>
                        <v-toolbar-title class="panel-with">
                          <h3 class="panel-title">
                            Admins management
                          </h3></v-toolbar-title
                        >
                        <div class="search-toolbar-area">
                          <v-text-field
                            name="search"
                            v-model="search"
                            single-line
                            hide-details
                            placeholder="Search a name"
                          ></v-text-field>
                        </div>
                      </v-card-title>
                    </div>
                    <div class="toolbar-right">
                      <v-dialog v-model="dialog" max-width="500px">
                        <template v-slot:activator="{ on, attrs }">
                          <button
                            class="btn btn-primary btn-add"
                            v-bind="attrs"
                            v-on="on"
                          >
                            Add Admin
                          </button>
                        </template>
                        <form @submit.prevent="storeAdmin">
                          <v-card class="modalAddAdmin">
                            <div class="modal-head">
                              <span class="text-h5">Add new admin</span>
                            </div>

                            <div class="modal-body">
                              <div class="form-wrap">
                                <label for="name">Name</label>
                                <input
                                  type="text"
                                  v-model="form.name"
                                  @input="errors.name = null"
                                  @click="errors.name = null"
                                />
                                <small class="text-danger" v-if="errors.name">
                                  {{ errors.name[0] }}
                                </small>
                              </div>

                              <div class="form-wrap last">
                                <label for="email">Email</label>
                                <input
                                  type="email"
                                  v-model="form.email"
                                  @input="errors.email = null"
                                  @click="errors.email = null"
                                />
                                <small class="text-danger" v-if="errors.email">
                                  {{ errors.email[0] }}
                                </small>
                              </div>
                            </div>
                            <div class="modal-footer">
                              <div class="actions">
                                <a
                                  class="btn btn-secondary"
                                  text
                                  @click="dialog = false"
                                >
                                  Cancel
                                </a>
                                <button
                                  class="btn btn-primary"
                                  text
                                  type="submit"
                                  :disabled="loadingAddAdmin"
                                >
                                  Save
                                          <span
                                      class="loading-dots"
                                      v-if="loadingAddAdmin"
                                    ></span>
                                </button>
                              </div>
                            </div>
                          </v-card>
                        </form>
                      </v-dialog>
                    </div>
                  </v-toolbar>
                </template>
                <template v-slot:item.sn="{ index }">
                  {{ index + 1 }}
                </template>
                <template v-slot:item.image_web="{ item }">
                  <div class="thumb-wrap">
                    <img
                      :src="
                        item.image_web !== null
                          ? item.image_web
                          : '/images/user-deafult.png'
                      "
                      class="border-img"
                    />
                  </div>
                </template>
                <template v-slot:item.name="{ item }">
                  {{ item.name }}
                </template>

                <template v-slot:item.email="{ item }">
                  <a :href="'mailto:' + item.email" class="link-email">
                    {{ item.email }}</a
                  >
                </template>
                <template v-slot:item.email_verified_at="{ item }">
                  {{ item.email_verified_at | moment("D MMMM, YYYY") }}
                </template>
                <template v-slot:item.status="{ item }">
                  <v-chip class="status" :color="getColor(item.status)" dark>
                    {{ item.status }}
                  </v-chip>
                </template>
                <template v-slot:item.actions="{ item }">
                  <div class="action"></div>
                </template>
              </v-data-table>
              <Pagination
                :totalItems="pagination.totalItems + ' Admins'"
                :totalPages="pagination.totalPages"
                :perPage="pagination.perPage"
                :currentPage="pagination.currentPage"
                @pagechanged="getAdmins"
              ></Pagination>
            </v-app>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Pagination from "../subComponents/pagination.vue";
export default {
  name: "admins",
  components: {
    Pagination,
  },
  data() {
    return {
      pagination: {
        totalItems: 0,
        totalPages: 1,
        perPage: 0,
        currentPage: 1,
      },
      paginationOf: "admins",
      admins: [],
      loading: true,
      loadingAddAdmin: false,
      dialog: false,
      headers: [
        {
          text: "S.N.",
          align: "start",
          sortable: false,
          value: "sn",
          width: "30px",
        },
        {
          text: "Image",
          value: "image_web",
          sortable: false,
          align: "start",
        },
        {
          text: "Name",
          value: "name",
          sortable: true,
          align: "start",
        },
        { text: "Email", value: "email", sortable: false },
        {
          text: "Registration Date",
          value: "email_verified_at",
          sortable: false,
          align: "start",
        },
        { text: "Status", value: "status", sortable: true },
        { text: "Actions", value: "actions", sortable: false, align: "start" },
      ],
      form: {
        name: "",
        email: "",
      },
      errors: {},
      search: null,
      awaitingSearch: false,
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Admin" : "Edit Admin";
    },
  },
  created() {
    const token = localStorage.getItem("access_token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
  },
  mounted() {
    this.getAdmins(this.pagination.currentPage);
  },
  watch: {
    search: function (val) {
      if (this.search == null || this.search == "") {
        this.awaitingSearch = false;
        this.getAdmins(this.pagination.currentPage);
        return false;
      } else {
        if (!this.awaitingSearch) {
          setTimeout(() => {
            if (this.search === null || this.search === "") {
              return;
            }
            this.loading = true;
            axios
              .get(`/admin/admin/search?page=${this.pagination.currentPage}`, {
                params: { search: this.search },
              })
              .then(({ data }) => {
                this.paginationOf = "search";
                this.pagination.currentPage = data.current_page;
                this.pagination.totalPages = data.last_page;
                this.admins = data.data;
                this.pagination.totalItems = data.total;
                this.loading = false;
                // console.log(data);
              })
              .catch((errors) => console.log(errors));
            this.awaitingSearch = false;
          }, 2000); // 2 sec delay
        }
        this.awaitingSearch = true;
      }
    },
  },
  methods: {
    getAdmins(page) {
      this.loading = true;
      this.pagination.currentPage = page;
      if (this.paginationOf == "search") {
        axios
          .get(`/admin/admin/search?page=${this.currentPage}`, {
            params: { search: this.search },
          })
          .then(({ data }) => {
            this.paginationOf = "search";
            this.pagination.currentPage = data.current_page;
            this.pagination.totalPages = data.last_page;
            this.admins = data.data;
            this.pagination.totalItems = data.total;
            this.loading = false;
            // console.log(data);
          })
          .catch((errors) => console.log(errors));
      } else {
        axios
          .get(`/admin/admins?page=${this.pagination.currentPage}`)
          .then(({ data }) => {
            this.paginationOf = "admins";
            this.pagination.totalItems = data.total;
            this.pagination.totalPages = data.last_page;
            this.pagination.perPage = data.per_page;
            this.pagination.currentPage = data.current_page;
            this.admins = data.data;
            this.loading = false;
            //  console.log(this.admins);
          })
          .catch((errors) => console.log(errors));
      }
    },
    storeAdmin() {
      this.loading = true;
      this.loadingAddAdmin = true;
      axios
        .post(`/admin/store-admin`, this.form)
        .then(({ data }) => {
          this.loading = false;
          this.loadingAddAdmin = false;
          this.dialog = false;
          this.getAdmins(1);
          Toast.success();
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.loading = false;
          this.loadingAddAdmin = false;
        });
    },
    getColor(statusGet) {
      //   console.log(statusGet);
      if (statusGet == "active") return "v-chip--active";
      else if (statusGet == "inactive") return "v-chip--inactive";
      else return "hidden";
    },
  },
};
</script>

<style>
</style>

